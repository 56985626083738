import { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useAuth } from 'src/utils/useAuth'
import { gTag } from 'src/utils/gTag'
import {
  withLoginIfNotLoggedIn,
  withOnboardingRedirectIfLoggedIn,
  withAccountRedirectIfLoggedIn,
  withRedirectToOnBoardingIfOnBoardingNotComplete,
  withRedirectToDashboardIfOnBoardingComplete,
} from 'src/utils/withProtect'
import { UserStore } from 'src/store/UserStore'

import { PageIndex } from 'src/pages/PageIndex'
import { PageLogin } from 'src/pages/PageLogin'
import { PageOnBoarding } from 'src/pages/PageOnBoarding'
import { PageDashboard } from 'src/pages/PageDashboard'
import { PagePlans } from 'src/pages/PagePlans'
import { PageNotifications } from 'src/pages/PageNotifications'
import { PageIntegrations } from 'src/pages/PageIntegrations'
import { PageAccount } from 'src/pages/PageAccount'
import { PagePrivacy } from 'src/pages/PagePrivacy'
import { PageTerms } from 'src/pages/PageTerms'
import { PageUninstall } from 'src/pages/PageUninstall'
import { PageContact } from 'src/pages/PageContact'
import { PageInterview } from 'src/pages/PageInterview'
import { Page404 } from 'src/pages/Page404'
import { Page500 } from 'src/pages/Page500'
import { Page502 } from 'src/pages/Page502'
import { Page503 } from 'src/pages/Page503'
import { PageDiary } from 'src/pages/PageDiary'
import { PageFaq } from 'src/pages/PageFaq'
import { PageHowItWorks } from 'src/pages/PageHowItWorks'
import { PagePartnership } from 'src/pages/PagePartnership'
import { PageExtension } from 'src/pages/PageExtension'

const PageIndexProtected = withAccountRedirectIfLoggedIn(PageIndex)

const PageLoginProtected = withOnboardingRedirectIfLoggedIn(PageLogin)

const PageOnBoardingProtected = withRedirectToDashboardIfOnBoardingComplete(
  withLoginIfNotLoggedIn(PageOnBoarding)
)
const PageDashboardProtected = withRedirectToOnBoardingIfOnBoardingNotComplete(
  withLoginIfNotLoggedIn(PageDashboard)
)
const PagePlansProtected = withRedirectToOnBoardingIfOnBoardingNotComplete(
  withLoginIfNotLoggedIn(PagePlans)
)
const PageNotificationsProtected = withRedirectToOnBoardingIfOnBoardingNotComplete(
  withLoginIfNotLoggedIn(PageNotifications)
)
const PageIntegrationsProtected = withRedirectToOnBoardingIfOnBoardingNotComplete(
  withLoginIfNotLoggedIn(PageIntegrations)
)
const PageAccountProtected = withRedirectToOnBoardingIfOnBoardingNotComplete(
  withLoginIfNotLoggedIn(PageAccount)
)
const PageUninstallProtected = withLoginIfNotLoggedIn(PageUninstall)
const PageDiaryProtected = withLoginIfNotLoggedIn(PageDiary)

const Main = observer(() => {
  const userId = UserStore.user?.id

  useEffect(() => {
    if (userId) {
      gTag(`set`, { user_id: userId })
      gTag(`config`, `UA-196308133-3`, { user_id: userId })
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageIndexProtected />} />
        <Route path="/onboarding" element={<PageOnBoardingProtected />} />
        <Route path="/login" element={<PageLoginProtected />} />
        <Route path="/dashboard" element={<PageDashboardProtected />} />
        <Route path="/plans" element={<PagePlansProtected />} />
        <Route path="/notifications" element={<PageNotificationsProtected />} />
        <Route path="/integrations" element={<PageIntegrationsProtected />} />
        <Route path="/diary" element={<PageDiaryProtected />} />
        <Route path="/account" element={<PageAccountProtected />} />
        <Route path="/privacy_policy" element={<PagePrivacy />} />
        <Route path="/terms_of_use" element={<PageTerms />} />
        <Route path="/uninstall_ext" element={<PageUninstallProtected />} />
        <Route path="/contact" element={<PageContact />} />
        <Route path="/faq" element={<PageFaq />} />
        <Route path="/how-it-works" element={<PageHowItWorks />} />
        <Route path="/partnership" element={<PagePartnership />} />

        <Route path="/404" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        <Route path="/502" element={<Page502 />} />
        <Route path="/503" element={<Page503 />} />
        <Route path="/extension" element={<PageExtension />} />
        <Route path="/interview-route" element={<PageInterview />} />

        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  )
})

export function App() {
  const [dataLoaded] = useAuth()
  return dataLoaded ? <Main /> : null
}
