import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledContentTitle = styled.h1`
  margin: 48px 0 0;
  font-family: var(--fonts-second);
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
  color: var(--color-black);
`

export const ContentTitle = ({ children }: { children: ReactNode }) => {
  return <StyledContentTitle>{children}</StyledContentTitle>
}
