import styled from 'styled-components'

import { media } from 'src/media'
import PuffLoader from 'react-spinners/PuffLoader'

const RootContainer = styled.div`
  width: 324px;
  height: 324px;
  position: relative;

  ${media.tablet`
    width: 150px;
    height: 150px
  `}
`

export const PaymentLoader = () => {
  const size = window.innerWidth < 768 ? 150 : 324
  return (
    <RootContainer>
      <PuffLoader size={size} />
    </RootContainer>
  )
}
