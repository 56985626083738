import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import PuffLoader from 'react-spinners/PuffLoader'

import { PlansStore, usePlansStore } from 'src/store/PlansStore'
import { UserStore } from 'src/store/UserStore'
import { useTransactionsStore, TransactionsStore } from 'src/store/TransactionsStore'

import { LayoutBase } from 'src/components/LayoutBase'
import { LoaderTop, Spacer } from 'src/ui/atoms'
import { media } from 'src/media'
import { usePlansActions } from 'src/pages/PageOnBoarding/hooks/usePlansActions'

import { PlansList } from './components/PlansList'
import { PlansBenefits } from './components/PlansBenefits'
import { PlansControls } from './components/PlansControls'
import { BillingHistory } from './components/BillingHistory'
import { usePlansCheck } from './components/usePlansCheck'

const Wrapper = styled.div`
  margin-left: 115px;
  margin-top: 36px;
  margin-bottom: 300px;
  max-width: 740px;
  width: 100%;

  ${media.laptop`
    margin: 146px auto 232px;
  `}

  ${media.tablet`
    margin: 132px auto 196px;
  `}

  ${media.mobile`
    margin-bottom: 160px;
  `}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 34px;
  font-family: var(--fonts);
  line-height: 38px;
  text-transform: none;

  ${media.tablet`
    font-size: 24px;
    line-height: 32px;
  `}

  ${media.tablet`
    margin-bottom: -10px;
  `}
`

const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 24px;
  font-family: var(--fonts);
  line-height: 32px;
  text-transform: none;

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
  `}

  ${media.mobile`
    margin-top: -50px;
  `}
`

const LoaderWrap = styled.div`
  margin-top: 100px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    margin-top: 30px;
  `}
`

const LoaderText = styled.p`
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
  margin: 50px 0 0;
`

const LimitText = styled.p`
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
  color: #e32626;
  margin: 24px 0 0;
`

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Paddle: any
  }
}

export const PagePlans: React.FC<RouteProps> = observer(() => {
  const { plans } = usePlansStore({ filterTrial: true })
  const { handleUserPlanUpdate, isPaymentProcessing } = usePlansCheck()
  const { transactions } = useTransactionsStore()
  const [selectedPlanToPurchase, selectedPlanToPurchaseSet] = useState(``)
  const { plansToRender, currentPlan, handleBuyPlan } = usePlansActions(
    UserStore.isOnActiveSubscription
  )

  const handleCancel = async () => {
    await PlansStore.cancel()
  }

  const handleBuyClick = () => {
    handleBuyPlan(() => {
      handleUserPlanUpdate()
    })
  }

  useEffect(() => {
    // предвыбираем первый план после их загрузки
    if (plans.length > 0) {
      selectedPlanToPurchaseSet(plans[0].external_id)
    }
  }, [plans])

  useEffect(() => {
    TransactionsStore.fetch()
  }, [isPaymentProcessing])
  return (
    <LayoutBase enableNav showSticky={false}>
      <Helmet>
        <script src="https://cdn.paddle.com/paddle/paddle.js" />
        <title>Plan & Billing</title>
      </Helmet>
      <LoaderTop />
      <Wrapper>
        <Title>Plan & Billing</Title>
        {UserStore.user.practice_limit_exceeded && (
          <LimitText>You have reached the limit. Choose a plan to continue:</LimitText>
        )}
        {isPaymentProcessing ? (
          <LoaderWrap>
            <PuffLoader size={150} />
            <LoaderText>Activating subscription. Wait a moment...</LoaderText>
          </LoaderWrap>
        ) : (
          <>
            <Spacer s="24" />
            {plans.length > 0 && (
              <PlansList
                selectedPlanId={selectedPlanToPurchase}
                onPlanClick={(id) => selectedPlanToPurchaseSet(id)}
                plans={plansToRender}
                handleCancel={handleCancel}
                currentPlanName={UserStore.selectUserActiveSubscription?.plan_title}
                nextBillDate={UserStore.selectUserActiveSubscription?.next_bill_date}
                trialToDate={UserStore.isOnActiveTrial && UserStore.user.trial_to}
              />
            )}
            <Spacer s="38" />
            <PlansBenefits />
            <Spacer s="48" />
            {plans.length > 0 && (
              <PlansControls handleBuy={handleBuyClick} currentPlan={currentPlan} />
            )}
          </>
        )}

        {transactions.length !== 0 &&
          UserStore.user.analytic_group !== `Testers` &&
          UserStore.user.analytic_group !== `Business` && (
            <>
              <Spacer s="72" />
              <Subtitle>Billing history</Subtitle>
              <BillingHistory transactions={transactions} />
            </>
          )}
      </Wrapper>
    </LayoutBase>
  )
})
