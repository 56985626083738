import { useState } from 'react'
import { breathUltima, facebook, twitter, medium, breathhhExtension } from 'src/utils/config'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from 'src/media'
import { Spacer, SocialDot } from 'src/ui/atoms'
import { FacebookBlack, Twitter, Medium } from 'src/ui/icons'
import { UserStore } from 'src/store/UserStore'
import { AppPopup } from 'src/components/AppPopup'

const Root = styled.footer`
  padding-top: 134px;
  padding-bottom: 34px;
  max-width: 1120px;
  width: 1120px;

  & a {
    color: inherit;
    text-decoration: none;
  }

  ${media.laptop`
    width: 100%;
  `}

  ${media.tablet`
    padding-top: 96px;
  `}

  ${media.mobile`
    padding-top: 68px;
    padding-bottom: 24px;
  `}
`

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 115px;

  ${media.laptopX`
    gap: 48px 24px;
  `}

  ${media.tablet`
    gap: 31px;
    max-width: 200px;
    justify-content: center;
    margin: 0 auto;
  `}
`

const InnerDown = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${media.tablet`
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  `}

  ${media.mobile`
    margin-top: 3px;
  `}
`

const Space = styled.div<{ height: string }>`
  height: ${(props) => props.height};

  ${media.mobile`
    height: 9px;
  `}
`

const SpaceStatic = styled(Space)`
  ${media.mobile`
    height: 34px;
  `}
`

const Title = styled.div`
  width: 182px;
  height: 13px;
  background: no-repeat url(/assets/logo-breathhh.svg);
  background-size: contain;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 170px;

  ${media.laptop`
    width: 208px;
  `}

  ${media.tablet`
    width: auto;
    max-width: 208px;
    min-width: 140px;
    align-items: center;
  `}
`

const SocialBlock = styled.div`
  display: flex;
  margin-top: -12px;
  margin-left: -12px;

  & > * {
    margin-top: 12px;
    margin-left: 12px;
  }

  ${media.mobile`
    margin-bottom: 3px;
  `}
`

const InfoBlock = styled.div`
  display: flex;
  gap: 34px;
`

const InfoText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: var(--color-ground-600);

  ${media.tablet`
    font-size: 16px;
    line-height: 25px;
  `}
`

const InfoTextSmall = styled(InfoText)`
  ${media.tablet`
    font-size: 14px;
    line-height: 22px;
  `}
`

const InfoLink = styled(InfoText)`
  &:hover {
    color: var(--color-ground-800);
  }
`

const InfoLinkSmall = styled(InfoText)`
  ${media.tablet`
    font-size: 14px;
    line-height: 22px;
  `}
  &:hover {
    color: var(--color-ground-800);
  }

  ${media.mobile`
    font-size: 14px;
    line-height: 22px;
  `}
`

const Line = styled.div`
  width: 100%;
  border: 1px solid var(--color-ground-100);
  margin-top: 48px;

  ${media.tablet`
    display: none;
  `}
`

const LineSmall = styled.div`
  display: none;
  width: 100%;
  border: 1px solid var(--color-ground-100);
  margin: 24px 0;

  ${media.tablet`
    display: block;
  `}
`

const WrapIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BlockTitle = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: var(--color-ground-600);

  ${media.tablet`
    font-size: 16px;
    line-height: 25px;
  `}
`

const BlockLink = styled(BlockTitle)`
  color: var(--color-black);

  &:hover {
    color: var(--color-ground-800);
  }
`

const Badge = styled.a`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`

export const Footer: React.FC = () => {
  const [appPopup, setAppPopup] = useState(false)
  const year = new Date().getFullYear()

  const clickClose = () => {
    setAppPopup(false)
  }

  const sendAction = (action: string) => {
    if (UserStore.user) {
      UserStore.sendAction({ kind: action })
    }
  }
  const devices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i

  return (
    <>
      <Root>
        <Inner>
          <Block>
            <Title />
            <Spacer s="28" />
            <SocialBlock>
              <SocialDot href={facebook}>
                <WrapIcon onClick={() => sendAction(`facebook_icon_click`)}>
                  <FacebookBlack />
                </WrapIcon>
              </SocialDot>
              <SocialDot href={twitter}>
                <WrapIcon onClick={() => sendAction(`twitter_icon_click`)}>
                  <Twitter />
                </WrapIcon>
              </SocialDot>
              <SocialDot href={medium}>
                <WrapIcon onClick={() => sendAction(`medium_icon_click`)}>
                  <Medium />
                </WrapIcon>
              </SocialDot>
            </SocialBlock>
          </Block>
          <Inner>
            <Block>
              <BlockTitle>PRODUCT</BlockTitle>
              <Space height="18px" />
              <Badge
                href={breathhhExtension}
                target="_blank"
                onClick={() => sendAction(`footer_breathhh_extension_click`)}
              >
                <BlockLink>Browser Extension</BlockLink>
              </Badge>
              <Space height="12px" />
              {devices.test(navigator.userAgent) ? (
                <Badge
                  href="https://apps.apple.com/us/app/breathhh/id6444698538?platform=iphone"
                  target="_blank"
                  onClick={() => sendAction(`footer_mobile_application_click`)}
                >
                  <BlockLink>Mobile Application</BlockLink>
                </Badge>
              ) : (
                <Badge
                  onClick={() => {
                    setAppPopup(true)
                    sendAction(`footer_mobile_application_click`)
                  }}
                >
                  <BlockLink>Mobile Application</BlockLink>
                </Badge>
              )}
              <Space height="12px" />
              <Badge
                href={breathUltima}
                target="_blank"
                onClick={() => sendAction(`footer_breathhh_ultima_click`)}
              >
                <BlockLink>Ultima Subscription</BlockLink>
              </Badge>
            </Block>
            <Block>
              <BlockTitle>COMPANY</BlockTitle>
              <Space height="18px" />
              <Link to="/contact" onClick={() => sendAction(`footer_contact_click`)}>
                <BlockLink>Contact</BlockLink>
              </Link>
              <Space height="12px" />
              <Link to="/partnership" onClick={() => sendAction(`footer_for_partnership_click`)}>
                <BlockLink>Partnership</BlockLink>
              </Link>
              <Space height="12px" />
              <Badge
                href="https://app.box.com/s/cfn4xrp4x6a2ay6gbrltdbjmwkikkyqw"
                target="_blank"
                onClick={() => sendAction(`footer_media_kit_click`)}
              >
                <BlockLink>Media Kit</BlockLink>
              </Badge>
            </Block>
            <Block>
              <BlockTitle>HELP</BlockTitle>
              <Space height="18px" />
              <Link to="/faq" onClick={() => sendAction(`footer_faq_click`)}>
                <BlockLink>FAQs</BlockLink>
              </Link>
              <Space height="12px" />
              <Badge
                href="https://bit.ly/3z6oPT4"
                target="_blank"
                onClick={() => sendAction(`footer_how_it_works_click`)}
              >
                <BlockLink>How it works</BlockLink>
              </Badge>
              <Space height="12px" />
              <Badge
                href="https://t.me/+Y2Z2l-qdhagyZGVl"
                target="_blank"
                onClick={() => sendAction(`footer_community_click`)}
              >
                <BlockLink>Community</BlockLink>
              </Badge>
            </Block>
          </Inner>
        </Inner>
        <Line />
        <SpaceStatic height="34px" />
        <InnerDown>
          <InfoBlock>
            <InfoTextSmall>© {year}, Breathhh</InfoTextSmall>
            <Badge
              href="mailto:feedback@breathhh.app"
              onClick={() => {
                sendAction(`footer_send_feedback_click`)
              }}
            >
              <InfoLinkSmall>Send Feedback</InfoLinkSmall>
            </Badge>
          </InfoBlock>
          <LineSmall />
          <InfoBlock>
            <Link to="/terms_of_use" onClick={() => sendAction(`footer_terms_of_user_click`)}>
              <InfoLink>Terms of Use</InfoLink>
            </Link>
            <Link to="/privacy_policy" onClick={() => sendAction(`footer_privacy_policy_click`)}>
              <InfoLink>Privacy Policy</InfoLink>
            </Link>
          </InfoBlock>
        </InnerDown>
      </Root>
      {appPopup && <AppPopup isFooter clickClose={clickClose} />}
    </>
  )
}
