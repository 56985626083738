import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'

import { ReactComponent as IconBreathe } from './assets/icon-breathe.svg'
import { ReactComponent as IconTail } from './assets/icon-tail.svg'
import { ReactComponent as IconArrow } from './assets/icon-arrow-up.svg'

import css from './Morda.module.scss'

type TProps = {
  startPractice: () => void
}

export const Morda = ({ startPractice }: TProps) => {
  const [isHovered, setHovered] = useState(false)

  return (
    <div
      className={css.inner}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* @ts-ignore */}
      <AnimatePresence exitBeforeEnter>
        {isHovered && (
          <motion.div
            key="banner"
            className={css.bannerWrap}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
          >
            <div
              className={css.banner}
              onClick={() => {
                setHovered(false)
                startPractice()
              }}
            >
              <div className={css.bannerTitle}>Time to breathe</div>
              <div className={css.bannerSubtitle}>
                Start practice
                <IconArrow />
              </div>
              <div className={css.bannerTail}>
                <IconTail />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={css.contentWrap}
        onClick={() => {
          startPractice()
        }}
      >
        <div className={css.content}>
          <IconBreathe />
        </div>
      </div>
    </div>
  )
}
