import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { chromeExtUrl } from 'src/utils/config'
import { Button } from 'src/ui/atoms'
import { OnboardingState, TOnboardingState, UserStore } from 'src/store/UserStore'
import { ONBOADING_DATA } from '../../PageOnBoading.contants'
import { PlansStepContent } from '../PlansStepContent'
import { usePlansActions } from '../../hooks/usePlansActions'

const ButtonWrap = styled.div`
  margin: 48px 0 0;
`

export interface ActionContentBodyProps {
  state: TOnboardingState
  isExtNotInstalled: boolean
  setNextStep: (step: TOnboardingState) => void
  onError: () => void
}

export const ActionContentBody = observer(
  ({ state, isExtNotInstalled, setNextStep, onError }: ActionContentBodyProps) => {
    const { action, nextStep, buttonText } = ONBOADING_DATA[state]
    const { plansToRender, currentPlan, handleBuyPlan } = usePlansActions(
      UserStore.isOnActiveSubscription
    )
    const [paymentCompleted, setPaymentCompleted] = useState(false)

    const goToNextStep = () => {
      if (nextStep) {
        setNextStep(nextStep)
      }
    }

    const goToLastStep = () => {
      setNextStep(OnboardingState.PAYMENT_COMPLETED)
    }

    let isPending = false
    useEffect(() => {
      if (paymentCompleted && !isPending) {
        isPending = true
        goToNextStep()

        const runReq = async () => {
          try {
            await UserStore.awaitSubscriptionConfirmation(() => {
              isPending = false
              goToLastStep()
            })
          } catch (err) {
            console.error(`an error during awaiting subscription confirmation`, err)
            onError()

            // return to prev step
            setNextStep(OnboardingState.PRACTICES)
          }
        }

        runReq()
      }
    }, [paymentCompleted, setNextStep, goToNextStep])

    if (
      ([OnboardingState.INITIAL, OnboardingState.INSTALLED] as TOnboardingState[]).includes(state)
    ) {
      const btnText = isExtNotInstalled
        ? `Web Store`
        : window.innerWidth < 1245 && state === OnboardingState.INSTALLED
        ? `Continue`
        : buttonText

      const onClick = useCallback(async () => {
        if (action) {
          UserStore.completeOnboarding(action)
        }
        goToNextStep()
      }, [])

      return (
        <ButtonWrap>
          <Button
            href={isExtNotInstalled ? chromeExtUrl : undefined}
            iconRight="ArrowRight"
            noBlank
            onClick={onClick}
          >
            {btnText}
          </Button>
        </ButtonWrap>
      )
    }

    if (state === OnboardingState.PRACTICES) {
      const handleBuyClick = () => {
        handleBuyPlan(() => {
          setPaymentCompleted(true)
        })
      }

      return (
        <PlansStepContent
          plans={plansToRender}
          currentPlan={currentPlan}
          onSkip={goToLastStep}
          onBuyClick={handleBuyClick}
        />
      )
    }

    return null
  }
)
