import { useCallback, useEffect, useState } from 'react'

import { TPlan, usePlansStore } from 'src/store/PlansStore'
import { UserStore } from 'src/store/UserStore'
import { PADDLE_SANDBOX, PADDLE_VENDOR_ID } from 'src/utils/envs'

type status = `idle` | `pending` | `success` | `failure`

export const usePlansActions = (isSubscriptionActive?: boolean) => {
  const { plans } = usePlansStore({ filterTrial: false })
  const [currentPlan, setCurrentPlan] = useState<null | TPlan>(null)
  const [status, setStatus] = useState<status>(`idle`)

  useEffect(() => {
    if (plans && !currentPlan && !isSubscriptionActive) {
      const defaultPlan = plans.reduce<null | TPlan>((current, plan) => {
        let result = current
        if (plan.recurring_type === `yearly`) {
          result = plan
        }

        if (current?.recurring_type !== `yearly` && plan.recurring_type === `monthly`) {
          result = current
        }

        return result
      }, null)
      setCurrentPlan(defaultPlan)
    }
  }, [plans])

  const handlePlanChoose = useCallback(
    (plan: TPlan) => () => {
      setCurrentPlan(plan)
    },
    [setCurrentPlan]
  )

  const logCheckoutEvent = (data) => {
    const whiteListedEvents = [`Checkout.Error`, `Checkout.Failed`, `Checkout.Loaded`]

    if (whiteListedEvents.includes(data.event)) {
      UserStore.sendAction({
        kind: `checkout_event`,
        payload: { event: data.event, eventData: data.eventData },
      })
    }
  }

  const handleBuyPlan = useCallback(
    (callback: () => void) => {
      const externalId = currentPlan.external_id
      if (!externalId) {
        console.warn(`🍅 no product "external_id"`)
        return
      }

      if (PADDLE_SANDBOX) {
        window.Paddle.Environment.set(`sandbox`)
      }

      if (PADDLE_VENDOR_ID) {
        // https://developer.paddle.com/reference/ZG9jOjI1MzU0MDIx-checkout-events
        const setup = {
          vendor: PADDLE_VENDOR_ID,
          eventCallback: (data) => {
            logCheckoutEvent(data)
            return null
          },
          // eventCallback: (data) => {
          //   console.log(data, `event callback data`)
          // },
        }

        window.Paddle.Setup(setup)

        const onSuccess = () => {
          callback()
          setStatus(`success`)
          UserStore.completeOnboarding(`payment_pending`)
        }

        const config = {
          email: UserStore.user?.email,
          product: externalId,
          passthrough: JSON.stringify({ user_id: UserStore.user?.id }),
          successCallback: onSuccess,
        }

        setStatus(`pending`)
        window.Paddle.Checkout.open(config)
      }
    },
    [currentPlan]
  )

  const plansToRender = plans?.map((plan) => {
    return {
      ...plan,
      checked: plan.external_id === currentPlan?.external_id,
      onChange: handlePlanChoose(plan),
    }
  })

  return { plans, plansToRender, currentPlan, handleBuyPlan, handlePlanChoose, status }
}
