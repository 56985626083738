import styled from 'styled-components'
import { useState, useRef } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
// @ts-ignore
import LoadingBar from 'react-top-loading-bar'
import { Helmet } from 'react-helmet-async'
import { UserStore } from 'src/store/UserStore'
import { LayoutBase } from 'src/components/LayoutBase'
import { LoaderTop } from 'src/ui/atoms'
import { media } from 'src/media'

const Wrapper = styled.div`
  margin-left: 115px;
  margin-top: 34px;
  margin-bottom: 192px;

  ${media.laptop`
    margin: 146px auto 232px;
  `}

  ${media.tablet`
    margin: 132px auto 196px;
  `}

  ${media.mobile`
    margin-bottom: 160px;
  `}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 34px;
  font-family: var(--fonts);
  line-height: 38px;
  text-transform: none;
  letter-spacing: normal;
  margin: 4px 0 0;

  ${media.tablet`
    font-size: 24px;
    line-height: 32px;
  `}
`

const InfoWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;

  ${media.tablet`
    flex-direction: column;
    margin-top: 8px;
  `}
`

const InputWrapper = styled.div`
  max-width: 360px;

  &:first-child {
    margin-right: 20px;
  }

  ${media.laptop`
    max-width: 324px;
    &:first-child {
      margin-right: 24px;
    }
  `}

  ${media.tablet`
    margin-right: 0;
  `}
`

const Label = styled.label`
  ${media.tablet`
    display: inline-block;
      margin-top: 24px;
  `}
`
const Input = styled.input`
  width: 360px;
  height: 52px;
  margin-top: 5px;
  box-sizing: border-box;
  border: 1px solid var(--color-ground-200);
  border-radius: 3px;
  padding: 0 12px 0 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  ${media.laptop`
    width: 324px;
  `}

  ${media.tablet`
    max-width: 672px;
    width: calc(100vw - 70px);
  `}

  ${media.mobile`
    width: calc(100vw - 40px);
  `}

  &:focus {
    outline: none;
  }
`

const InputGrey = styled.div`
  width: 360px;
  height: 52px;
  margin-top: 5px;
  box-sizing: border-box;
  border: 1px solid var(--color-ground-200);
  border-radius: 3px;
  background: var(--color-ground-100);
  padding: 0 12px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-ground-800);
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  ${media.laptop`
    width: 324px;
  `}

  ${media.tablet`
    max-width: 672px;
    width: calc(100vw - 70px);
  `}

  ${media.mobile`
    width: calc(100vw - 40px);
  `}
`

const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-white);
  background-color: var(--color-black);
  padding: 12px 44.5px;
  border-radius: 50px;
  margin-top: 34px;
  outline: none;
  border: none;

  ${media.tablet`
    max-width: 672px;
    width: calc(100vw - 70px);
  `}

  ${media.mobile`
    width: calc(100vw - 40px);
  `}

  &:hover {
    cursor: pointer;
    background-color: var(--color-ground-900);
  }

  &:active {
    background-color: var(--color-black);
  }

  &:focus {
    background-color: var(--color-ground-800);
  }
`

const ControlsWrap = styled.div`
  display: flex;
  margin-top: 93px;
  align-items: center;
  vertical-align: middle;
  color: var(--color-ground-600);

  ${media.tablet`
    margin-top: 60px;
    flex-direction: column;
    align-items: flex-start;
  `}
`

const ButtonGrey = styled(Button)`
  color: var(--color-ground-600);
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  margin: 0 8px;
  border: none;
  outline: none;

  &:first-child {
    margin-left: 0;
  }

  ${media.tablet`
    margin-bottom: 12px;
    width: fit-content;
    margin-right: 0;
    margin-left: 0;
  `}

  &:hover {
    cursor: pointer;
    color: var(--color-ground-1000);
    background-color: transparent;
    &:first-child {
      color: var(--color-black);
    }
    &:last-child {
      color: var(--color-signal);
    }
  }

  &:active:hover {
    &:first-child {
      color: var(--color-sunrise);
    }
  }

  &:focus {
    background-color: transparent;
  }
`

const Point = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-ground-600);

  ${media.tablet`
    display: none;
  `}
`

const Icon = styled.img`
  width: 18px;
  height: 18px;
`
export const PageAccount: React.FC<RouteProps> = observer(() => {
  const navigate = useNavigate()
  const [value, setValue] = useState(UserStore.user.name)
  const ref = useRef(null)

  const handleReset = () => {
    UserStore.sendAction({ kind: `account_logout_click` })
    UserStore.logout()
    navigate(`/login`, { replace: true })
  }

  const handleDelete = async () => {
    UserStore.sendAction({ kind: `account_delete_account_click` })
    // eslint-disable-next-line no-alert
    const isOk = window.confirm(
      `Do you really want to delete youre account? It will not be possible to undo this action`
    )
    if (isOk) {
      await UserStore.deleteMyAccount()
      navigate(`/`, { replace: true })
    }
  }

  const isFacebookConnected = UserStore.user?.facebook_connected
  const isGoogleConnected = UserStore.user?.google_connected

  const handleChangeName = () => {
    UserStore.updateName(value)
    UserStore.user.name = value
    UserStore.sendAction({ kind: `account_save_click` })
  }

  const copyData = () => {
    const data = `id: ${
      UserStore.user.id
    }, time: ${new Date().getHours()}:${new Date().getMinutes()}, timezone: ${
      new Date().getTimezoneOffset() / 60
    }, info: ${navigator.userAgent}`
    navigator.clipboard.writeText(data)
    UserStore.sendAction({ kind: `acount_copy_tech_info_click` })
  }
  return (
    <LayoutBase enableNav showSticky={false}>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <LoaderTop />
      <LoadingBar color="var(--app-text-color)" ref={ref} />
      <Wrapper>
        <Title>Account</Title>
        <InfoWrapper>
          <InputWrapper>
            <Label htmlFor="name">Name</Label>
            <Input
              type="text"
              id="apple"
              name="name"
              value={value}
              maxLength={64}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <Label htmlFor="email">Email</Label>
            <InputGrey id="email">
              {UserStore.user?.email}
              {isFacebookConnected ? (
                <Icon width="18" height="18" src="/assets/facebook-grey.svg" alt="Facebook Logo" />
              ) : isGoogleConnected ? (
                <Icon width="18" height="18" src="/assets/google-grey.svg" alt="Google Logo" />
              ) : (
                <Icon width="18" height="18" src="/assets/apple-grey.svg" alt="Apple Logo" />
              )}
            </InputGrey>
          </InputWrapper>
          <Button type="button" onClick={handleChangeName}>
            Save
          </Button>
        </InfoWrapper>

        <ControlsWrap>
          <ButtonGrey onClick={copyData}>Copy tech information</ButtonGrey>
          <Point>•</Point>
          <ButtonGrey onClick={handleReset}>Logout</ButtonGrey>
          <Point>•</Point>
          <ButtonGrey onClick={handleDelete}>Delete account</ButtonGrey>
        </ControlsWrap>
      </Wrapper>
    </LayoutBase>
  )
})
