import styled from 'styled-components'
import { RouteProps } from 'react-router-dom'
import { LayoutCenter } from 'src/components/LayoutCenter'
import { Helmet } from 'react-helmet-async'
import { media } from 'src/media'

const Root = styled.div`
  width: 100%;
  margin-bottom: 320px;

  ${media.laptop`
    margin-bottom: 256px;
  `}

  ${media.tablet`
    margin-bottom: 160px;
  `}

  ${media.mobile`
    margin-bottom: 132px;
  `}
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Content = styled.div`
  max-width: 740px;
  margin-top: 91px;
  margin-bottom: 88px;

  ${media.tablet`
    margin-top: 47px;
    margin-bottom: 72px;
  `}

  ${media.mobile`
    margin-bottom: 56px;
  `}
`

const Title = styled.h1`
  ${media.laptop`
    text-align: center;
  `}

  ${media.tablet`
    padding-bottom: 40px;
  `}
`

const QWrapper = styled.div`
  margin-top: 32px;
  border-top: 1px solid var(--color-ground-200);

  &:first-child {
    margin-top: 0;
    border-top: transparent;
  }
`

const FirstSubtitle = styled.h4``

const Subtitle = styled.h4`
  margin-top: 24px;

  ${media.laptop`
    margin-top: 20px;
  `}
`

const Info = styled.p`
  margin-top: 32px;

  ${media.laptop`
    text-align: center;
  `}

  ${media.tablet`
    margin-top: 0;
  `}
`

const Text = styled.p`
  margin-top: 24px;

  ${media.laptop`
    margin-top: 20px;
  `}
`

const TextBlack = styled.a`
  font-size: 24px;
  line-height: 34px;
  font-weight: 400;
  border-radius: 6px;
  background-color: var(--color-black);
  color: var(--color-white);
  transition: 0.3s;

  ${media.tablet`
    font-size: 22px;
    line-height: 32px;
  `}

  &:hover {
    transition: 0.3s;
    cursor: pointer;
    & svg path {
      animation: rotation 0.3s linear forwards;
    }
  }

  @keyframes rotation {
    0% {
      fill: var(--color-ground-700);
    }
    100% {
      fill: var(--color-white);
    }
  }
`

const Link = styled.a`
  color: inherit;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: var(--color-ground-600);
  }
`
const Data = styled.p`
  margin-top: 32px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: var(--color-ground-700);

  ${media.laptop`
    font-size: 16px;
    text-align: center;
  `}

  ${media.tablet`
    font-size: 16px;
    line-height: 24px;
    margin-top: 48px;
  `}

  ${media.tablet`
    margin-top: 40px;
  `}
`

const Question = styled.img`
  width: 265px;
  height: 265px;
  margin-top: 91px;

  ${media.laptop`
    display: none;
  `}
`

const Arrow = styled.svg`
  width: 24px;
  height: 24px;
  vertical-align: middle;
  transition: 0.3s;
`

export const PageFaq: React.FC<RouteProps> = () => {
  return (
    <LayoutCenter>
      <Helmet>
        <title>FAQs Breathhh</title>
        <meta name="title" content="Frequently asked questions" />
        <meta name="description" content="Regularly updated for users needs" />
        <meta
          name="keywords"
          content="Breathhh, FAQ, FAQs, Question, Questions, Frequently, Ask, Asked, Suggestion, Help"
        />
        <meta name="twitter:title" content="Breathhh FAQ" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Frequently asked questions" />
        <meta property="og:description" content="Regularly updated for users needs" />
      </Helmet>
      <Root>
        <Wrapper>
          <Content>
            <Title>Frequently asked questions</Title>
            <Info>
              Feel free to&thinsp;
              <TextBlack href="mailto:contact@breathhh.app">
                &nbsp;ask anything&nbsp;
                <Arrow
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.19356 6H18V14.8065H16V9.41421L6.70712 18.7071L5.29291 17.2929L14.5858 8H9.19356V6Z"
                    fill="#8C8C8C"
                  />
                </Arrow>
              </TextBlack>
              &thinsp;about product or&thinsp;
              <TextBlack href="mailto:feedback@breathhh.app">
                &nbsp;make&nbsp;suggestions&nbsp;
                <Arrow
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.19356 6H18V14.8065H16V9.41421L6.70712 18.7071L5.29291 17.2929L14.5858 8H9.19356V6Z"
                    fill="#8C8C8C"
                  />
                </Arrow>
              </TextBlack>
            </Info>
            <Data>Last Update: Apr 12, 2022</Data>
          </Content>
          <Question width="265" height="265" src="/assets/question-circle.svg" alt="question" />
        </Wrapper>
        <FirstSubtitle>How do I use Breathhh?</FirstSubtitle>
        <Text>
          You need to{` `}
          <Link href="https://breathhh.app/login" target="_blank">
            register an account
          </Link>
          {` `}
          and{` `}
          <Link href="https://breathhh.app/extension" target="_blank">
            download an extension
          </Link>
          {` `}for your browser. After you will be forwarded to a welcome tour. For more
          information, see the{` `}
          <Link href="https://bit.ly/3z6oPT4" target="_blank">
            "How Breaathhh Works" page
          </Link>
          .
        </Text>
        <QWrapper>
          <Subtitle>Is it free? </Subtitle>
          <Text>
            With a free account, you get the basic features of the service. You can use it for as
            long as you need. If you need additional functionality, you can purchase{` `}
            <Link href="https://breathhh.app/plans" target="_blank">
              Breathhh Ultima
            </Link>
            {` `}
            subscription.
          </Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>How to get and use practices?</Subtitle>
          <Text>
            The essence of the service is that thanks to artificial intelligence, you get offers to
            practice automatically at the most relevant moment. However, you can turn on the
            necessary practice yourself from the browser extension window.
          </Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>How do I configure the features I want?</Subtitle>
          <Text>
            In the extension window, you can see settings that allow you to enable and disable the
            automatic appearance of various practices, activate the light/night theme, turn on the
            background noise, and activate artificial intelligence personalization. Among other
            things, you can set limitation for different categories of websites.
          </Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>How can I figure out what works best for me?</Subtitle>
          <Text>
            You can learn about the value of each feature on the{` `}
            <Link href="https://bit.ly/3z6oPT4" target="_blank">
              "How Breaathhh Works" page
            </Link>
            . All of the basic features of the product are available to you at the beginning of use.
          </Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>What does Personalized Neural Engine?</Subtitle>
          <Text>
            The algorithm for selecting practices will be trained on your personal preferences and
            experiences. This will greatly increase the relevance of practice suggestions.
          </Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>How do I cancel my subscription?</Subtitle>
          <Text>
            You need to log in to the website and go to "Billing / Subscription". On this page you
            will find the "Unsubscribe" button. The subscription will be canceled and your account
            will be switched to a basic.
          </Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>Do you plan on adding more features in the future?</Subtitle>
          <Text>Sure! Breathhh is going to grow its feature set for years to come.</Text>
        </QWrapper>
        <QWrapper>
          <Subtitle>I didn't find the answer to the question</Subtitle>
          <Text>
            You can email us at{` `}
            <Link href="mailto:contact@breathhh.app">contact@breathhh.app</Link>. We'll be sure to
            respond!
          </Text>
        </QWrapper>
      </Root>
    </LayoutCenter>
  )
}
