import { TOnboardingState } from 'src/store/UserStore'
import { BubbledContent } from '../BubbledContent'
import { ContentSubtitle } from '../ContentSubtitle'
import { ContentTitle } from '../ContentTitle'
import { ONBOADING_DATA } from '../../PageOnBoading.contants'
import { ActionContentBody } from '../ActionContentBody'

export interface LeftContentSlotProps {
  onboardingState: TOnboardingState
  startAnimate: boolean
  activeTab: number
  isExtNotInstalled: boolean
  setNextStep: (step: TOnboardingState) => void
  onError: () => void
}

export const LeftContentSlot = ({
  startAnimate,
  onboardingState,
  isExtNotInstalled,
  setNextStep,
  onError,
}: LeftContentSlotProps) => {
  const { title, subtitle } = ONBOADING_DATA[onboardingState]

  return (
    <BubbledContent startAnimate={startAnimate} key={onboardingState}>
      <ContentTitle>{title}</ContentTitle>
      {Boolean(subtitle) && <ContentSubtitle>{subtitle}</ContentSubtitle>}
      <ActionContentBody
        state={onboardingState}
        isExtNotInstalled={isExtNotInstalled}
        setNextStep={setNextStep}
        onError={onError}
      />
    </BubbledContent>
  )
}
