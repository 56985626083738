import styled from 'styled-components'

import { media } from 'src/media'

export interface PlanRadioBtnProps {
  planName: string
  title: string
  annualPrice: string | number
  isFreeTrialAlllowed?: boolean
  checked?: boolean
  badgeInfo?: string
  groupName?: string
  className?: string
  onChange: () => void
}

const CustomRadio = styled.span`
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: var(--color-ground-100);
  border: 2px solid var(--color-black);
  transition: background-color 0.25s ease-out;
  box-sizing: border-box;

  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-ground-100);
    transform: translate(-50%, -50%);
    transition: opacity 0.25s ease-out;
  }
`

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
`

const ComponentRoot = styled.label<{ checked: boolean; hasBadge: boolean }>`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-ground-100);
  border-radius: 4px;
  border: ${({ checked }) => (checked ? `2px solid var(--color-black)` : `2px solid transparent`)};
  cursor: pointer;

  ${HiddenInput}:checked ~ ${CustomRadio} {
    background-color: var(--color-black);

    &::after {
      opacity: 1;
    }
  }

  ${media.tablet`
    flex-wrap: ${({ hasBadge }) => (hasBadge ? `wrap` : `nowrap`)};;
  `}
`

const BaseText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-black);
`

const LeftWrap = styled.div<{ hasBadge: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;

  ${media.tablet`
    justify-content: space-between;
    margin-bottom: ${({ hasBadge }) => (hasBadge ? `8px` : `0`)};
  `}
`

const InfoWrap = styled.div`
  width: 100%;
  text-align: right;
`

const InputArea = styled.div`
  display: flex;
`

const BottomInfo = styled.div`
  font-size: 12px;
  line-height: 1.5;
`

const FreeTrialText = styled.span`
  color: #31a775;

  &:after {
    content: ' • ';
    color: var(--color-ground-700);
  }
`

const AnnualPrice = styled.span`
  color: var(--color-ground-700);
`

const Badge = styled.div`
  margin-left: 12px;
  padding: 4px 10px;
  background-color: var(--color-sunrise);
  border-radius: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: var(--color-black);
`

export const UserPlanRadio = ({
  planName,
  title,
  checked,
  annualPrice,
  badgeInfo,
  groupName,
  isFreeTrialAlllowed,
  onChange,
}: PlanRadioBtnProps) => {
  return (
    <ComponentRoot checked={checked} hasBadge={Boolean(badgeInfo)}>
      <LeftWrap hasBadge={Boolean(badgeInfo)}>
        <InputArea>
          <HiddenInput type="radio" onChange={onChange} name={groupName} checked={checked} />
          <CustomRadio />
          <BaseText>{planName}</BaseText>
        </InputArea>
        {Boolean(badgeInfo) && <Badge>{badgeInfo}</Badge>}
      </LeftWrap>
      <InfoWrap>
        <BaseText>{title}</BaseText>
        <BottomInfo>
          {isFreeTrialAlllowed && <FreeTrialText>Free trial</FreeTrialText>}
          <AnnualPrice>${annualPrice} anual</AnnualPrice>
        </BottomInfo>
      </InfoWrap>
    </ComponentRoot>
  )
}
