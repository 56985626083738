import styled from 'styled-components'
import * as icons from 'src/ui/icons'
import { useEffect, useState } from 'react'
import { UserStore } from 'src/store/UserStore'
// import { PlansStore } from 'src/store/PlansStore'
import { media } from 'src/media'
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'

import { Container } from './Container'
import { Menu } from './Menu'

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;

  ${media.laptop`
    flex-direction: column;
  `}
`

const Popup = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`

const IframeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 930px;
  max-height: 547px;
  width: 100%;
  height: 100%;
  z-index: 15;

  ${media.laptop`
    max-width: calc(100vw - 100px);
    max-height: 515px;
  `}

  ${media.tablet`
    max-width: calc(100vw - 100px);
    max-height: 415px;
    width: 100%;
  `}
`

const Iframe = styled.iframe`
  width: 930px;
  height: 315px;
  height: 100%;
  z-index: 15;
  opacity: 1;

  ${media.laptop`
    max-width: calc(100vw - 100px);
    max-height: 515px;
  `}

  ${media.tablet`
    max-width: calc(100vw - 100px);
    max-height: 415px;
  `}
`

const CloseButton = styled.svg`
  position: absolute;
  right: -36px;
  width: 28px;
  height: 28px;

  &:hover {
    cursor: pointer;
    path {
      fill: var(--color-white);
    }
  }
`

const CloseButtonCross = styled(CloseButton)`
  top: 12px;
  right: 12px;
  &:hover {
    path {
      fill: var(--color-black);
    }
  }
`

const PopupCountry = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--color-white);
  width: 341px;
  height: 263px;
  right: 24px;
  top: calc(100vh - 287px);
  box-shadow: 0px 0px 48px rgba(46, 67, 108, 0.16);
  border-radius: 3px;
  z-index: 5;
  opacity: 0;
  animation: showcountrypopupbottom 1s ease-in 3s forwards;

  ${media.tablet`
    left: calc(50% - 170px);
    animation: showcountrypopupbottom 1s ease-in 3s forwards;
  `}

  ${media.mobile`
    max-width: 280px;
    left: calc(50% - 140px);
  `}

  @keyframes showcountrypopupcorner {
    from {
      transform: translate(100%, 100%);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }

  @keyframes showcountrypopupbottom {
    from {
      transform: translateY(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

const WrapAvatars = styled.div`
  margin: 28px auto 0;
  width: fit-content;
  height: 40px;
`

const WrapFlag = styled.div`
  margin: 31px auto 0;
  width: fit-content;
  height: 34px;
`

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &:not(:first-child) {
    margin-left: -10px;
  }
`

const Flag = styled.img`
  width: 51px;
  height: 34px;
`

const PopupTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-top: 16px;
`

const PopupText = styled.p`
  max-width: 293px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin: 4px auto 0;
  color: var(--color-ground-700);
`

const PopupButton = styled.a`
  width: 293px;
  height: 57px;
  border-radius: 50px;
  color: var(--color-white);
  margin: 24px auto 24px;
  border: none;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  gap: 8px;

  ${media.mobile`
    max-width: 232px;
  `}
`

const RusButton = styled(PopupButton)`
  background: #40a7e3;

  &:hover {
    cursor: pointer;
    background: #45b8fc;
  }
`

// const UaButton = styled(PopupButton)`
//   background: var(--color-black);

//   &:hover {
//     cursor: pointer;
//     background: var(--color-sunrise);
//   }
// `

type TProps = {
  hideLogin?: boolean
  enableNav?: boolean
  showSticky?: boolean
  showPopup?: boolean
  browserName?: string | boolean
  watcherRef?: unknown
  handleShowPopup?: () => void
  isIOS?: boolean
}

export const LayoutBase: React.FC<React.PropsWithChildren<TProps>> = ({
  children,
  hideLogin,
  enableNav,
  showSticky,
  browserName,
  showPopup,
  watcherRef,
  handleShowPopup,
  isIOS,
}) => {
  const [showCountryPopup, setShowCountryPopup] = useState(
    localStorage.getItem(`show_country_popup`) === `false`
      ? localStorage.getItem(`show_country_popup`)
      : `true`
  )
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleClick = () => {
    setShowCountryPopup(`false`)
    localStorage.setItem(`show_country_popup`, `false`)
    UserStore.sendAction({ kind: `join_community_click` })
  }
  // const handleActivateTrial = async () => {
  //   handleClick()
  //   await PlansStore.enableTrial()
  // }
  return (
    <Root>
      {showPopup && (
        <Popup onClick={handleShowPopup}>
          <IframeWrapper>
            <Iframe
              // @ts-ignore
              ref={watcherRef}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/IDsu0CEArDY?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <CloseButton onClick={handleShowPopup}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.3498 14L6.1748 7.82496L7.82472 6.17504L13.9998 12.3501L20.1748 6.17504L21.8247 7.82496L15.6497 14L21.8247 20.175L20.1748 21.825L13.9998 15.6499L7.82472 21.825L6.1748 20.175L12.3498 14Z"
                  fill="#E6E6E6"
                />
              </svg>
            </CloseButton>
          </IframeWrapper>
        </Popup>
      )}
      <div>
        <Header
          hideLogin={hideLogin}
          enableNav={enableNav}
          showSticky={showSticky}
          browserName={browserName}
          hideInstallStatic
          isIOS={isIOS}
        />
        <Container>
          <Wrapper>
            {enableNav && <Menu />}
            {enableNav &&
              showCountryPopup !== `false` &&
              (UserStore.user.country === `US` ||
                UserStore.user.country === `CA` ||
                UserStore.user.country === `UA`) &&
              (UserStore.user.country === `US` || UserStore.user.country === `CA` ? (
                <PopupCountry>
                  <CloseButtonCross onClick={handleClick}>
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.3498 14L6.1748 7.82496L7.82472 6.17504L13.9998 12.3501L20.1748 6.17504L21.8247 7.82496L15.6497 14L21.8247 20.175L20.1748 21.825L13.9998 15.6499L7.82472 21.825L6.1748 20.175L12.3498 14Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                  </CloseButtonCross>
                  <WrapAvatars>
                    <Avatar src="/assets/avatar1.png" alt="avatar" />
                    <Avatar src="/assets/avatar2.png" alt="avatar" />
                    <Avatar src="/assets/avatar3.png" alt="avatar" />
                    <Avatar src="/assets/avatar4.png" alt="avatar" />
                  </WrapAvatars>
                  <PopupTitle>Join our community</PopupTitle>
                  <PopupText>
                    Here, you can share your ideas and experiences with us. <br />
                    Together, we can make our product better.
                  </PopupText>
                  <RusButton
                    onClick={handleClick}
                    href="https://t.me/+ezFtc4Nz4N0xOTQ1"
                    target="_blank"
                  >
                    <icons.Telegram /> Telegram chat
                  </RusButton>
                </PopupCountry>
              ) : (
                <PopupCountry>
                  <CloseButtonCross onClick={handleClick}>
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.3498 14L6.1748 7.82496L7.82472 6.17504L13.9998 12.3501L20.1748 6.17504L21.8247 7.82496L15.6497 14L21.8247 20.175L20.1748 21.825L13.9998 15.6499L7.82472 21.825L6.1748 20.175L12.3498 14Z"
                        fill="#8C8C8C"
                      />
                    </svg>
                  </CloseButtonCross>
                  <WrapFlag>
                    <Flag src="/assets/uaflag.png" alt="flag" />
                  </WrapFlag>
                  <PopupTitle>Are you from Ukraine?</PopupTitle>
                  <PopupText>
                    We want to give you free access to a paid plan so you can take care of your
                    mental health
                  </PopupText>
                  {/* <UaButton onClick={handleActivateTrial} href="/plans">
                    Activate Ultima for Free
                  </UaButton> */}
                </PopupCountry>
              ))}
            {children}
          </Wrapper>
        </Container>
      </div>
      <Container>
        <Footer />
      </Container>
    </Root>
  )
}
