import styled from 'styled-components'
import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { observer } from 'mobx-react-lite'
import dayjs from 'dayjs'
import { IMoodRate } from '../../store/UserStore/types'

Chart.register(...registerables)

const title = (tooltipItems) => {
  const mood =
    tooltipItems[0].parsed.y === 1
      ? `😰  Terrible`
      : tooltipItems[0].parsed.y === 2
      ? `😒  Unamused`
      : tooltipItems[0].parsed.y === 3
      ? `😐  Neutral`
      : tooltipItems[0].parsed.y === 4
      ? `🙂  Good`
      : tooltipItems[0].parsed.y === 5 && `😁  Great`

  return mood
}

interface IProps {
  days: number
  dataTest: IMoodRate[]
}

const Wrapper = styled.div`
  width: 670px;
  height: 310px;
`

export const LineChart: React.FC<IProps> = observer((props) => {
  const { days, dataTest } = props

  const ftmDay = (day) => dayjs(day).format(`dddd,  DD MMM`)

  const label = (tooltipItem) => {
    return ftmDay(dataTest[tooltipItem.parsed.x].date)
  }

  const footer = (tooltipItems) => {
    let comment = ``
    tooltipItems.forEach((tooltipItem) => {
      comment = dataTest[tooltipItem.parsed.x].note
    })
    return comment
  }

  const options = {
    layout: {
      padding: 20,
    },
    spanGaps: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: false,
        beginAtZero: true,
        suggestedMin: 0,
        max: 6,
      },
      x: {
        display: false,
        min: 1,
        max: days,
        step: 1,
      },
    },
    plugins: {
      legend: false,
      tooltip: {
        displayColors: false,
        backgroundColor: `rgba(0, 0, 0, 1)`,
        padding: 12,
        titleSpacing: 3,
        callbacks: {
          title,
          label,
          labelTextColor() {
            return `#8C8C8C`
          },
          footer,
        },
      },
    },
  }
  const daysForLabels = []
  for (let i = 1; i <= days; i += 1) {
    daysForLabels.push(i)
  }
  const dataForLine = []
  for (let i = 1; i <= days; i += 1) {
    dataForLine.push(3)
  }
  const data = {
    labels: daysForLabels.map((item) => item),
    datasets: [
      {
        label: `Mood`,
        data: dataTest.map((item) => {
          return item.rate
        }),
        backgroundColor: `black`,
        borderColor: `black`,
        borderWidth: 2,
        pointRadius: 2,
        fill: {
          above: `rgba(4, 205, 119, 0.2)`,
          below: `rgba(255, 203, 26, 0.2)`,
          target: { value: 3 },
        },
      },
      {
        label: `Mood2`,
        data: dataForLine.map((item) => {
          return item
        }),
        backgroundColor: `var(--color-ground-300)`,
        borderColor: `var(--color-ground-300)`,
        borderWidth: 0.5,
        pointRadius: 0,
      },
    ],
  }
  return (
    <Wrapper>
      {/* @ts-ignore */}
      <Line data={data} options={options} />
    </Wrapper>
  )
})
