import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { UserStore } from 'src/store/UserStore'
import { media } from 'src/media'
import { Button } from 'src/ui/atoms'

import { PlansRadioGroup } from '../PlansRadioGroup'
import { PlanRadioGroup } from '../PlansRadioGroup/PlansRadioGroup'

const Root = styled.div`
  margin-top: 16px;

  ${media.tablet`
    margin-top: 12px;
  `}
`

const BtnListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  ${media.tablet`
    margin-top: 18px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `}
`

const ChooseLaterButton = styled.button`
  padding: 16px 24px;
  border: none;
  background: transparent;
  font-size: 16px;
  line-height: 1.5;
  color: var(--color-ground-700);
  outline: none;
  cursor: pointer;
  transition: color 0.25s ease-out;

  &:hover {
    color: var(--color-ground-400);
  }

  ${media.tablet`
    margin-top: 5px;
  `}
`

export interface PlansStepContentProps {
  plans: PlanRadioGroup[]
  currentPlan: PlanRadioGroup
  onBuyClick: () => void
  onSkip: () => void
}

export const PlansStepContent = observer(
  ({ plans, currentPlan, onSkip, onBuyClick }: PlansStepContentProps) => {
    const sendOnceRef = useRef(false)
    useEffect(() => {
      if (!sendOnceRef.current) {
        UserStore.sendAction({ kind: `paywall_page_opened` })
      }

      return () => {
        sendOnceRef.current = true
      }
    })

    const hasFreeAcess = UserStore.hasFreeAcessFeature()
    const isTrialPossible = currentPlan?.trial_days > 0

    const handleBuy = () => {
      UserStore.sendAction({ kind: `buy_plan_click` })
      onBuyClick()
    }

    const handleChooseLater = () => {
      onSkip()
      UserStore.completeOnboarding(`paywall`)
      UserStore.sendAction({ kind: `choose_later_click` })
    }

    const buyButtonText = isTrialPossible
      ? `Start ${currentPlan.trial_days}-day Free Trial`
      : `Continue`

    const handleOnChange = () => {
      UserStore.sendAction({ kind: `change_plan_click` })
    }

    return (
      <Root>
        <PlansRadioGroup plans={plans} onChange={handleOnChange} />
        <BtnListWrap>
          <Button iconRight="ArrowRight" onClick={handleBuy}>
            {buyButtonText}
          </Button>
          {hasFreeAcess && (
            <ChooseLaterButton type="button" onClick={handleChooseLater}>
              Choose Later
            </ChooseLaterButton>
          )}
        </BtnListWrap>
      </Root>
    )
  }
)
