import Rollbar from 'rollbar'

const isProd = process.env.NODE_ENV === `production`

const rollBarConfig = {
  accessToken: `960c63c90161452fa830caa464230012`,
  environment: isProd ? `production` : `development`,
}

export const rollBar = new Rollbar(rollBarConfig)
