import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useLongPress } from 'react-use'

import { UserStore } from 'src/store/UserStore'

import { media } from 'src/media'
import { Button } from 'src/ui/atoms'

const Root = styled.header.attrs(({ className }) => ({ classname: className }))<{
  transparent?: boolean
  positionAbsolute?: boolean
}>`
  top: 0;
  display: flex;
  height: 89px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${({ transparent }) => (transparent ? `` : `background-color: var(--color-white);`)}
  overflow: hidden;
  padding-bottom: 1px;
  z-index: 5;
  ${({ positionAbsolute }) => positionAbsolute && `position: absolute !important;`}

  ${media.mobile`
    height: 73px;
  `}

  &.top {
    position: relative;
  }

  &.hide {
    height: 89px;
    animation: headerFadeOut ease-in 0.5s forwards;
  }

  &.show {
    height: 77px;
    position: relative;
    animation: headerFadeIn ease-in 0.5s forwards;
    overflow: hidden;
  }

  @keyframes headerFadeIn {
    from {
      position: relative;
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
      position: sticky;
    }
  }

  @keyframes headerFadeOut {
    from {
      opacity: 1;
      transform: translateY(0);
      position: sticky;
    }
    to {
      position: relative;
      opacity: 0;
      transform: translateY(-100%);
    }
  }
`

const Border = styled.div`
  height: 77px;
  position: absolute;
  width: 100%;
  border-bottom: solid 1px var(--color-ground-100);
  margin-right: -34px;
`

const WrapperTitle = styled.div<{ h: string; w: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin-left: 26px;

  ${media.tablet`
    width: 61px;
    height: 61px;
    margin-left: 12px;
  `}
`

const Title = styled.div<{ h: string; w: string; bg: string }>`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  background: ${(props) => props.bg};
  z-index: 10;

  ${media.tablet`
    width: 45px;
    height: 45px;
    background-image: url(/assets/icon-breathhh.svg);
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 10;
  margin-right: 34px;

  ${media.tablet`
    margin-right: 20px;
    gap: 4px;
  `}
`

const WrapperAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`
const SmallButton = styled.div`
  display: none;

  ${media.tablet`
    display: block;
  `}
`

const BigButton = styled.div`
  display: block;

  ${media.tablet`
    display: none;
  `}
`

interface IProps {
  hideLogin?: boolean
  hideInstall?: boolean
  hideInstallStatic?: boolean
  enableNav?: boolean
  showSticky?: boolean
  positionAbsolute?: boolean
  transparent?: boolean
  browserName?: string | boolean
  isIOS?: boolean
}

export const Header: React.FC<IProps> = observer((props) => {
  const {
    showSticky,
    hideLogin,
    hideInstall,
    browserName,
    hideInstallStatic,
    transparent,
    positionAbsolute,
    isIOS,
  } = props
  const [scroll, setScroll] = useState(false)
  const [up, setUp] = useState(false)
  const watcherRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)

  // секретная функция для отладки состояния профиля на проде
  // копирует пользователя в буффер
  const onLongPress = async () => {
    if (UserStore.user && navigator.clipboard) {
      const userJson = toJS(UserStore.user)
      const userString = JSON.stringify(userJson, null, 2)
      await navigator.clipboard.writeText(userString)

      console.log(`>> debug info START`)
      console.log(userJson)
      console.log(`>> debug info END`)
    }
  }

  const longPressEvent = useLongPress(onLongPress, { isPreventDefault: true, delay: 2_000 })

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: `700px`,
      threshold: 0,
    }

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setScroll(false)
        } else {
          setUp(true)
          setScroll(true)
        }
      })
    }

    const options2 = {
      root: null,
      rootMargin: `200px`,
      threshold: 0,
    }

    const callback2 = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setUp(false)
        }
      })
    }

    const scrollObserver = new IntersectionObserver(callback, options)
    const scrollObserver2 = new IntersectionObserver(callback2, options2)

    scrollObserver.observe(watcherRef.current)
    scrollObserver2.observe(watcherRef.current)

    return () => {
      scrollObserver.disconnect()
      scrollObserver2.disconnect()
    }
  }, [headerRef])
  const sticky = scroll ? `show` : !scroll && up && `hide`
  return (
    <>
      <div ref={watcherRef} />
      <Root
        ref={headerRef}
        className={!up || showSticky !== undefined ? `top` : sticky}
        transparent={transparent}
        positionAbsolute={positionAbsolute}
      >
        {scroll && <Border />}
        <ButtonContainer onClick={() => UserStore.sendAction({ kind: `breathhh_logo_click` })}>
          <Link to="/">
            <WrapperTitle
              w={!up || showSticky !== undefined ? `220px` : `61px`}
              h={!up || showSticky !== undefined ? `29px` : `61px`}
            >
              <Title
                bg={
                  !up || showSticky !== undefined
                    ? `no-repeat url(/assets/logo-breathhh.svg)`
                    : `no-repeat url(/assets/icon-breathhh.svg)`
                }
                w={!up || showSticky !== undefined ? `204px` : `45px`}
                h={!up || showSticky !== undefined ? `13px` : `45px`}
              />
            </WrapperTitle>
          </Link>
        </ButtonContainer>
        <ButtonContainer>
          {!UserStore.user ? (
            isIOS ? (
              <Link to="/login">
                {!hideLogin && (
                  <Button type="button" appearance="ghost">
                    Sign in
                  </Button>
                )}
              </Link>
            ) : (
              <Link to="/login">
                {!hideLogin && (
                  <Button type="button" appearance="ghost">
                    {window.innerWidth < 768 && sticky ? `Get Started` : `Sign in`}
                  </Button>
                )}
              </Link>
            )
          ) : (
            <Link
              to="/dashboard"
              onClick={() => UserStore.sendAction({ kind: `account_avatar_click` })}
            >
              <WrapperAvatar
                // секретный функционал для дебага
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...longPressEvent}
              >
                <Avatar
                  width="45"
                  height="45"
                  src={UserStore.user.picture ? UserStore.user.picture : `/assets/avatar.svg`}
                  alt="user's avatar"
                />
              </WrapperAvatar>
            </Link>
          )}
          {UserStore.onboardingIsCompleted ||
            (!UserStore.user && (
              <div>
                {((hideInstallStatic && sticky === `show`) ||
                  (!hideInstall && !hideInstallStatic)) &&
                  (isIOS ? (
                    <Button
                      id="200"
                      appearance="ghost"
                      noBlank
                      href="https://apps.apple.com/us/app/breathhh/id6444698538"
                    >
                      Get Started
                    </Button>
                  ) : (
                    <BigButton>
                      <Button
                        id="105"
                        noBlank
                        href="https://chrome.google.com/webstore/detail/breathhh/niipedbmjiopjpmjcpigiflabghcckeo"
                      >
                        {!browserName ? `Get from Web Store` : `Add to ${browserName} — it’s free`}
                      </Button>
                    </BigButton>
                  ))}
                <SmallButton>
                  {((hideInstallStatic && sticky === `show`) ||
                    (!hideInstall && !hideInstallStatic)) &&
                    window.innerWidth >= 768 &&
                    (isIOS ? (
                      <Button
                        id="200"
                        appearance="ghost"
                        noBlank
                        href="https://apps.apple.com/us/app/breathhh/id6444698538"
                      >
                        Get Started
                      </Button>
                    ) : (
                      <Button
                        id="104"
                        appearance="ghost"
                        href="https://chrome.google.com/webstore/detail/breathhh/niipedbmjiopjpmjcpigiflabghcckeo"
                      >
                        Install
                      </Button>
                    ))}
                </SmallButton>
              </div>
            ))}
        </ButtonContainer>
      </Root>
    </>
  )
})
