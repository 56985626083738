import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from '@rollbar/react'

import { rollBar } from 'src/utils/rollBar'

import 'normalize.css'
import './ui/tokens.css'
import './ui/fonts.css'
import './index.css'

import { App } from './App'

const isProd = process.env.NODE_ENV === `production`

const root = createRoot(document.getElementById(`root`))

root.render(
  <React.StrictMode>
    {isProd ? (
      // @ts-ignore проблема с FC на 18 реакте, должно решиться обновление роллбара
      <Provider instance={rollBar}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    ) : (
      <HelmetProvider>
        <App />
      </HelmetProvider>
    )}
  </React.StrictMode>
)
