import { ReactNode, useEffect } from 'react'
import { motion, useAnimationControls } from 'framer-motion'

export interface BubbledContentProps {
  children?: ReactNode
  startAnimate: boolean
}

export const BubbledContent = ({ children, startAnimate }: BubbledContentProps) => {
  const controls = useAnimationControls()

  useEffect(() => {
    controls.start({ opacity: [0, 1], y: [`20%`, `0%`] })
  }, [startAnimate])

  return (
    <motion.div
      initial={{ opacity: 0, y: `20%` }}
      animate={controls}
      transition={{
        duration: 0.3,
      }}
    >
      {children}
    </motion.div>
  )
}
