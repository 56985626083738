import axios from 'axios'
import { makeAutoObservable, runInAction } from 'mobx'
import dayjs from 'dayjs'
import cookie from 'js-cookie'

import { appDomain, sendMessageToExt } from 'src/utils/config'
import { fetcher } from 'src/utils/fetcher'
import { ACTION_LOGOUT } from 'src/utils/actions'

import {
  OnboardingState,
  IUser,
  IDeleteFeedback,
  IMoodRates,
  IEngagements,
  IAction,
  TOnboardingState,
} from './types'

const today = () => dayjs().format(`YYYY-MM-DD`)

export class UserStore {
  token = ``

  user: null | IUser = null

  moodRates: IMoodRates

  moodIsLoaded = false

  engagements: IEngagements

  engagementsIsLoaded = false

  loadingFeedback = false

  cid = ``

  constructor() {
    makeAutoObservable(this)
  }

  setOnboaringState(state: TOnboardingState) {
    runInAction(() => {
      this.user = {
        ...this.user,
        onboarding_state: state,
      }
    })
  }

  setToken(token: string) {
    this.token = token
  }

  setCId() {
    let match = document.cookie.match(`(?:^|;)\\s*_ga=([^;]*)`)
    const raw = match ? decodeURIComponent(match[1]) : null
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/)
    }
    const gacid = match ? match[1] : null
    if (gacid) {
      this.cid = gacid
      return
    }
    console.log(`no cid`)
  }

  logout() {
    cookie.remove(`token`, { path: `/`, domain: `.${appDomain}` })
    this.user = null
    sendMessageToExt(ACTION_LOGOUT)
  }

  async fetchUser() {
    const { data } = await fetcher.get<IUser>(`/profile?date=${today()}`, {
      headers: {
        AUTHORIZATION: this.token,
        GOOGLE_CID: this.cid,
      },
    })

    runInAction(() => {
      this.user = data
    })
  }

  async awaitSubscriptionConfirmation(callback: () => void) {
    const controller = new AbortController()

    try {
      const interval = setInterval(async () => {
        const { data } = await fetcher.get<IUser>(`/profile?date=${today()}`, {
          signal: controller.signal,
          headers: {
            AUTHORIZATION: this.token,
            GOOGLE_CID: this.cid,
          },
        })

        if (
          data &&
          (data.onboarding_state === OnboardingState.PAYMENT_COMPLETED ||
            data.subscription_state === `subscription_active`)
        ) {
          clearInterval(interval)
          callback()
        }
      }, 3000)
    } catch (err) {
      console.error(`[UserStore]: an error during fetching subscription confirmation: ${err}`)

      if (axios.isCancel(err)) {
        controller?.abort()
      }
    }
  }

  async setAndFetchUser(token: string) {
    this.setToken(token)
    this.setCId()
    await this.fetchUser()
  }

  async deleteMyAccount() {
    await fetcher.delete(`/profile`, {
      headers: {
        AUTHORIZATION: this.token,
        GOOGLE_CID: this.cid,
      },
    })
    this.logout()
  }

  async patchCategories(id: number, limit: number) {
    await fetcher.patch(
      `/users/categories/${id}`,
      { limit },
      {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      }
    )
  }

  async sendDeleteFact() {
    await fetcher.delete(`/users/extension`, {
      headers: {
        AUTHORIZATION: this.token,
        GOOGLE_CID: this.cid,
      },
    })
  }

  async sendDeleteFeedback(data: IDeleteFeedback) {
    this.loadingFeedback = true
    await fetcher.post(`/users/feedbacks`, data, {
      headers: {
        AUTHORIZATION: this.token,
        GOOGLE_CID: this.cid,
      },
    })

    runInAction(() => {
      this.loadingFeedback = false
    })
  }

  async fetchMoodRates(from, to) {
    try {
      this.moodIsLoaded = false
      const { data } = await fetcher.get<IMoodRates>(`/moods?from=${from}&to=${to}`, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })

      runInAction(() => {
        this.moodRates = data
        this.moodIsLoaded = true
      })
    } catch (error) {
      console.log(`>> fetchMoodRates`, error)
    }
  }

  async downloadMoodReport(from, to) {
    try {
      const { data } = await fetcher.get<string>(`/moods/download?from=${from}&to=${to}`, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })

      return data
    } catch (error) {
      console.log(`>> fetchMoodRates`, error)
      throw error
    }
  }

  async fetchEngagements(from, to) {
    try {
      this.engagementsIsLoaded = false
      const { data } = await fetcher.get<IEngagements>(`/engagements?from=${from}&to=${to}`, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })

      runInAction(() => {
        this.engagements = data
        this.engagementsIsLoaded = true
      })
    } catch (error) {
      console.log(`>> fetchEngagements`, error)
    }
  }

  async downloadEngagementsReport(from, to) {
    try {
      const { data } = await fetcher.get<string>(`/engagements/download?from=${from}&to=${to}`, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })
      return data
    } catch (error) {
      console.log(`>> fetchEngagements`, error)
      throw error
    }
  }

  async completeOnboarding(step: string): Promise<
    | {
        text: string
        emoji: string
      }
    | undefined
  > {
    let result
    try {
      const { data } = await fetcher.post<{
        notice_text: null | string
        notice_emoji: string | null
      }>(
        `/users/onboardings`,
        { step },
        {
          headers: {
            AUTHORIZATION: this.token,
            GOOGLE_CID: this.cid,
          },
        }
      )

      if (data && data.notice_emoji && data.notice_text) {
        result = {
          text: data.notice_text,
          emoji: data.notice_emoji,
        }
      }
    } catch (error) {
      console.log(`>> onboarding`, error)
    }

    return result
  }

  async serviceEmail(value) {
    const data = { service_emails: !value }
    try {
      this.loadingFeedback = true
      await fetcher.put(`/profile`, data, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })
    } catch (error) {
      console.log(`>> service_emails`, error)
    }
    runInAction(() => {
      this.loadingFeedback = false
    })
  }

  async founderEmail(value) {
    const data = { founder_emails: !value }
    try {
      this.loadingFeedback = true
      await fetcher.put(`/profile`, data, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })
    } catch (error) {
      console.log(`>> founder_emails`, error)
    }
    runInAction(() => {
      this.loadingFeedback = false
    })
  }

  async newsEmail(value) {
    const data = { news_emails: !value }
    try {
      this.loadingFeedback = true
      await fetcher.put(`/profile`, data, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })
    } catch (error) {
      console.log(`>> new_emails`, error)
    }
    runInAction(() => {
      this.loadingFeedback = false
    })
  }

  async tipsEmail(value) {
    const data = { tips_emails: !value }
    try {
      this.loadingFeedback = true
      await fetcher.put(`/profile`, data, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })
    } catch (error) {
      console.log(`>> tips_emails`, error)
    }
    runInAction(() => {
      this.loadingFeedback = false
    })
  }

  async updateName(value) {
    const data = { name: value }
    try {
      this.loadingFeedback = true
      await fetcher.put(`/profile`, data, {
        headers: {
          AUTHORIZATION: this.token,
          GOOGLE_CID: this.cid,
        },
      })
    } catch (error) {
      console.log(`>> update_name`, error)
    }
    runInAction(() => {
      this.loadingFeedback = false
    })
  }

  async sendAction(data: IAction) {
    await fetcher.post(`/analytics_events`, data, {
      headers: {
        AUTHORIZATION: this.token,
        GOOGLE_CID: this.cid,
      },
    })
  }

  hasFreeAcessFeature() {
    if (this.user?.free_access_feature) {
      return true
    }

    return false
  }

  get avatar() {
    return this.user?.picture || undefined
  }

  get engList() {
    return this.user?.engagement || []
  }

  get currentOnboarding() {
    return this.user?.onboarding_state
  }

  get onboardingIsCompleted() {
    return this.user?.onboarding_state === OnboardingState.COMPLETED
  }

  get isNotActiveSubscription() {
    return this.user?.subscription_state === `subscription_not_active`
  }

  get isOnActiveSubscription() {
    return this.user?.subscription_state === `subscription_active`
  }

  get isOnActiveTrial() {
    return this.user?.subscription_state === `subscription_trial`
  }

  get selectUserActiveSubscription() {
    return this.user?.active_subscription
  }

  get isTrialAbility() {
    return this.user?.trial_ability
  }

  get isShowTrialVariant() {
    return !this.isOnActiveSubscription && this.isTrialAbility
  }
}
