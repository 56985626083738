export interface IMood {
  id: number
  user_id: string
  date: string
  rate: number
}

export interface IEngagement {
  category_id: number
  category_title: string
  engagement: null | number
  color: string
  limit: number
}

export const OnboardingState = {
  INITIAL: `onboarding_initial`,
  INSTALLED: `onboarding_extension_installed_step`,
  PRACTICES: `onboarding_practices_completed`,
  TECHNOLOGY: `onboarding_technology_completed`,
  PAYMENT_PENDING: `onboarding_payment_pending`,
  PAYMENT_COMPLETED: `onboarding_paywall_completed`,
  COMPLETED: `onboarding_completed`,
} as const

export type TOnboardingState = typeof OnboardingState[keyof typeof OnboardingState]

interface IActiveSubscription {
  external_subscription_id: string
  external_plan_id: string
  external_checkout_id: null
  next_bill_date: string
  plan_title: string
  plan_price: number
  state?: string
}

export interface IUser {
  analytic_group?: string
  id: string
  picture: string | null
  email: string
  name: string
  breath: boolean
  mood_picker: boolean
  gdpr: boolean
  mood: IMood | null
  warming: null | boolean
  ml_algorithm: boolean
  tabs_count: number
  onboarding_state: TOnboardingState
  engagement: IEngagement[]
  facebook_connected: boolean
  google_connected: boolean
  subscription_state: `subscription_not_active` | `subscription_trial` | `subscription_active`
  trial_to: string
  trial_from: string
  night_mode: `disabled` // FIXME: почему строкой? `boolean` не подойдет?
  focus_control: boolean
  active_subscription: null | IActiveSubscription
  trial_ability: boolean
  country: string
  service_emails: boolean
  news_emails: boolean
  tips_emails: boolean
  founder_emails: boolean
  average_browser_activity_seconds: number
  has_moods: boolean
  free_access_feature?: boolean
  practice_limit_exceeded?: boolean
}

export interface IDeleteFeedback {
  reasons: Array<string>
}

export interface IReferrer {
  referrer: string
  entry_url: string
}

export interface IMoodRate {
  date: string
  rate: 1 | 2 | 3 | 4 | 5
  note: string
}

export interface IMoodRates {
  moods: IMoodRate[]
  average_mood: 1 | 2 | 3 | 4 | 5
}

export interface IEngagements {
  engagements: {
    category_id: number
    category_title: string
    engagement: number
    color: string
    limit: number | null
  }
  average_browser_time: number
}

export interface IAction {
  kind: string
  payload?: object
}
