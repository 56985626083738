import styled from 'styled-components'
import { RouteProps } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { LayoutBase } from 'src/components/LayoutBase'
import { media } from 'src/media'

const Content = styled.div`
  margin-top: 57px;
  ${media.tablet`
    margin-top: 21px;
  `}
  ${media.mobile`
    max-width: 320px;
  `}
`

const Title = styled.h4`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-ground-300);
`

const Caption = styled.p`
  color: var(--color-ground-700);
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  font-weight: 500; ;
`

const Subtitle = styled.p`
  margin-top: 64px;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`

const Text = styled.p`
  margin-top: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
`

const TextList = styled(Text)`
  padding-left: 12px;
`

const Pad = styled.div`
  padding-top: 256px;
  ${media.laptop`
    padding-top: 160px;
  `}
  ${media.tablet`
    padding-top: 132px;
  `}
`

const Link = styled.a`
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: inherit;
  font-feature-settings: 'liga' off;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    color: var(--color-ground-700);
  }
`

export const PagePrivacy: React.FC<RouteProps> = () => {
  return (
    <LayoutBase>
      <Helmet>
        <title>Breathhh Privacy Policy</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <Content>
        <Title>Breathhh Privacy Policy</Title>
        <Caption>Last update: April 02, 2024</Caption>
        <Subtitle>Introduction</Subtitle>
        <Text>
          This privacy policy (“Privacy Policy”) explains how Breathhh (or “we”) collects and
          processes information from members and users of the website, browser extension, mobile
          application and other online services we provide. Breathhh's products include, but are not
          limited to, the Breathhh extension for web browsers (the “Extension”), the Breathhh
          application for iPhone and Apple Watch (the “Application”) and the website located at
          www.breathhh.app (the “Website”).
        </Text>
        <Text>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </Text>
        <Text>
          If we ever need additional personal information for a specific reason, we will tell you
          and ask for your permission. Also, if Breathhh has links to other websites, please note
          that we do not own, operate or control them, and they have their own privacy policies that
          you should familiarize yourself with.
        </Text>
        <Subtitle>Disclaimer & Key Points</Subtitle>
        <Text>
          Breathhh is developed by a team of independent enthusiast developers, not as a traditional
          business entity but as a project driven by an ideological mission to enhance well-being in
          today's uncertain world. As a startup, Breathhh is inherently dynamic, evolving over time
          to better serve our mission and the needs of our users and strategy.
        </Text>
        <Text>
          In pursuit of improving user experience, we process user data to enhance interfaces,
          algorithms, marketing communication and assets, and machine learning functionalities.
          Although we prioritize the security of the data and dedicate significant resources to
          protect against unauthorized access, we cannot guarantee complete security. The digital
          environment is inherently vulnerable, and no system can ensure total protection from all
          unauthorized breaches or access.
        </Text>
        <Text>
          By using Breathhh, users acknowledge and accept the risks associated with digital data
          processing and storage. Despite our best efforts and the implementation of robust security
          measures, absolute security is an unrealistic expectation in the digital realm. Our team
          is transparent and diligent in our efforts to protect user data, but it is also crucial
          for users to understand and accept the limitations and risks associated with the use of
          online services.
        </Text>
        <Text>
          Users who engage with Breathhh's services consent to the processing of their data as
          described herein and acknowledge our disclaimer of liability for any unintended breaches
          or unauthorized access to their personal information. We encourage users to stay informed
          about their data privacy rights and to reach out to us with any concerns or questions
          regarding the security of their data and our privacy practices.
        </Text>
        <Subtitle>What information do we collect?</Subtitle>
        <Text>
          In the course of providing the Breathhh services, we automatically collect information
          that you directly share with us through the use of our website, browser extension, mobile
          application, or other services. This information may include personally identifiable
          information (e.g., your name or email address upon registration), as well as data
          concerning your device, IP address, browser characteristics, operating system, language
          preferences, referring URL addresses, device name, country, location, information
          regarding your use of our Services, and other technical information. Additionally, we
          process and collect data related to websurfing activity when browser extension installed
          and authrozied (processing is limited to the root domain without access to the full URL
          for category label). This collection is primarily necessary for ensuring the security and
          operation of our Services, as well as for internal analytics and reporting purposes. The
          browser extension in Google Web Store, mobile application in App Store, and authentication
          methods (including Facebook, Gmail, and iCloud) transparently delineate the requisite data
          access for service use, thus fostering transparency and enabling users to make informed
          decisions regarding their privacy. Payment information collection is facilitated through
          the use of Paddle Inc. as our acquiring partner. This information is collected at the
          point of payment gateway activation and is thoroughly detailed in the agreement presented
          at that time. This ensures that users are fully informed about the type of payment
          information collected and its usage.
        </Text>
        <Subtitle>How do we access your data</Subtitle>
        <Text>
          Access to your data is primarily facilitated through the authorization or registration
          processes you engage in while using our services, including our website, mobile
          application, browser extensions, and other related services. When you register or log in
          through third-party platforms such as Facebook, Gmail, or iCloud, we access certain
          profile information with your permission. Installing and using our browser extension
          allows us to access data related to your browsing activity. By using our mobile
          application, you grant us access to certain information on your device, necessary for
          service provision and user experience enhancement. Visiting our website and interacting
          with our services involves collecting data like your IP address and browser type, which
          helps personalize and improve your experience. Additionally, we utilize Google Analytics
          to analyze website traffic and user interactions, further enabling us to enhance our
          services and understand user needs. We may use cookies and similar tracking technologies
          (like web beacons and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is set out in our
          Cookie Notice. Access to your data across these platforms and services is contingent upon
          your consent, underlining our commitment to transparency and privacy respect.
        </Text>
        <Subtitle>How do we store and protect your data</Subtitle>
        <Text>
          The data we collect is stored across various sources based on its type and nature,
          including but not limited to remote servers managed by Digital Ocean, services provided by
          Google, email platforms such as Mailchimp, and other infrastructure services that are
          integral to the provision and enhancement of our product's services and user experience.
          We retain personal data only for as long as necessary to fulfill the purposes for which it
          was collected, including for the purposes of satisfying any legal, accounting, product
          development or reporting requirements.
        </Text>
        <Text>
          We have implemented appropriate and reasonable technical and organisational security
          measures designed to protect the security of any personal information we process. However,
          despite our safeguards and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed to be 100% secure,
          so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised
          third parties will not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Although we will do our best to protect your personal
          information, transmission of personal information to and from our Services is at your own
          risk. You should only access the Services within a secure environment.
        </Text>
        <Subtitle>How do we use your data</Subtitle>
        <TextList>
          <li>
            To provide and maintain our Service, including to monitor the usage of our Service.
          </li>
          <li>
            To manage your registration as a user of the Service. The Personal Data You provide can
            give You access to different functionalities of the Service that are available to You as
            a registered user.
          </li>
          <li>
            The development, compliance and undertaking of the purchase contract for the products,
            items or services You have purchased or of any other contract with Us through the
            Service.
          </li>
          <li>
            To contact You by email, telephone calls, SMS, or other equivalent forms of electronic
            communication, such as a mobile application's push notifications regarding updates or
            informative communications related to the functionalities, products or contracted
            services, including the security updates, when necessary or reasonable for their
            implementation.
          </li>
          <li>
            To provide You with news, special offers and general information about other goods,
            services and events which we offer that are similar to those that you have already
            purchased or enquired about unless You have opted not to receive such information.
          </li>
          <li>To attend and manage Your requests to Us.</li>
          <li>
            We can use your information to improve service technology (include machine learning & AI
            needs).
          </li>
          <li>
            We may use Your information to evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding,
            in which Personal Data held by Us about our Service users is among the assets
            transferred.
          </li>
          <li>
            We may use Your information for other purposes, such as data analysis, identifying usage
            trends, determining the effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your experience.
          </li>
          <li>
            We may share Your personal information with Service Providers to monitor and analyze the
            use of our Service, to contact You.
          </li>
          <li>
            We may share or transfer Your personal information in connection with, or during
            negotiations of, any merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </li>
          <li>
            We may share Your information with Our affiliates, in which case we will require those
            affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
            other subsidiaries, joint venture partners or other companies that We control or that
            are under common control with Us.
          </li>
          <li>
            We may share Your information with Our business partners to offer You certain products,
            services or promotions.
          </li>
          <li>
            When you share personal information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be publicly distributed
            outside.
          </li>
          <li>
            We may disclose Your personal information for any other purpose with your consent.
          </li>
        </TextList>
        <Subtitle>Changes to this policy</Subtitle>
        <Text>
          We will continue to update our policies and practices as needed. We will notify you of any
          changes to our Privacy Policy by posting any changes here. If we do, you'll see that the
          date at the top of this Privacy Policy has changed.
        </Text>
        <Subtitle>How can you contact us about this notice</Subtitle>
        <Text>
          If you have questions or comments about this notice, you may email us at
          {` `}
          <Link href="mailto:contact@breathhh.app">contact@breathhh.app</Link>.
        </Text>
        <Pad />
      </Content>
    </LayoutBase>
  )
}
