import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { media } from 'src/media'

import type { ITransaction } from 'src/store/TransactionsStore'

const Root = styled.div`
  display: grid;
  margin-top: 14px;

  ${media.mobile`
    margin-top: 0;
  `}
`

const Row = styled.div<{ isHeader?: boolean; isHideMobile?: boolean; isHideDesktop?: boolean }>`
  display: ${({ isHideDesktop }) => (isHideDesktop ? `none` : `grid`)};
  grid-template-columns: 1fr 1fr 1fr;
  color: var(${({ isHeader }) => (isHeader ? `--color-ground-600` : `--color-black`)});
  border-bottom: 1px solid var(--color-ground-200);
  padding: ${({ isHeader }) => (isHeader ? `8px` : `20px`)} 0;

  &:last-child {
    border-bottom: 0;
  }

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
    display:  ${({ isHideMobile }) => (isHideMobile ? `none` : `grid`)};
    padding: 24px 0;
  `}
`

const MobileWrapper = styled.div`
  display: flex;

  ${media.mobile`
    flex-direction: column;
  `}
`

const Text = styled.div<{ isMobile?: boolean; textAlign?: boolean }>`
  ${media.mobile`
    color: var(${({ isMobile }) => (isMobile ? `--color-ground-600` : `--color-black`)});
    text-align: ${({ textAlign }) => (textAlign ? `end` : `start`)};
  `}
`

export const BillingHistory = observer(({ transactions }: { transactions: ITransaction[] }) => {
  return (
    <Root>
      <Row isHeader isHideMobile>
        <div>Date</div>
        <div>Order</div>
        <div>Amount</div>
      </Row>

      {transactions.map((item, key) => (
        <React.Fragment key={key}>
          <Row isHideDesktop>
            <MobileWrapper>
              <Text>{dayjs(item.date).format(`MMMM D, YYYY`)}</Text>
              <Text isMobile>{item.plan_name}</Text>
            </MobileWrapper>
            <Text textAlign>{item.amount}</Text>
          </Row>
          <Row isHideMobile>
            <Text>{dayjs(item.date).format(`MMMM D, YYYY`)}</Text>
            <Text>{item.plan_name}</Text>
            <Text>{item.amount}</Text>
          </Row>
        </React.Fragment>
      ))}
    </Root>
  )
})
