import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import { UserStore } from 'src/store/UserStore'

import { Button } from 'src/ui/atoms'
import { media } from 'src/media'

import { PlanRadioGroup } from 'src/pages/PageOnBoarding/components/PlansRadioGroup/PlansRadioGroup'
import { ReactComponent as LogoPaddle } from './assets/logo-paddle.svg'
import { ReactComponent as LogoCustomer } from './assets/logo-customer.svg'

const Block = styled.div`
  display: flex;
  margin-left: -16px;

  & > * {
    margin-left: 16px;
  }

  ${media.tablet`
    display: grid;
  `}

  ${media.mobile`
    margin-left: 0;
  `}
`

const WrapperLogo = styled.div`
  display: flex;
  margin-left: 0;
  margin-top: -16px;

  & > * {
    margin-top: 16px;
    margin-left: 16px;
  }

  ${media.tablet`
    justify-content: center;
    margin-top: 13px;

  `}
`

const WrapperButtons = styled.div`
  display: flex;
  margin-top: -16px;

  & > * {
    margin-top: 16px;
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }

    ${media.mobile`
      margin-left: 0;
    `}
  }

  ${media.tablet`
    justify-content: center;
  `}

  ${media.mobile`
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    margin-top: -30px;
  `}
`

const WrapText = styled.div`
  ${media.mobile`
    width: 232px;
  `}
`
type TProps = {
  handleBuy: () => void
  currentPlan?: PlanRadioGroup
}

export const PlansControls = observer(({ handleBuy, currentPlan }: TProps) => {
  const clickButton = () => {
    handleBuy()
    UserStore.sendAction({
      kind: `upgrade_to_ultima_click`,
    })
  }

  return (
    <Block>
      {!UserStore.selectUserActiveSubscription?.plan_title && !UserStore.isOnActiveTrial && (
        <>
          <WrapperButtons>
            <Button onClick={clickButton}>
              {currentPlan?.trial_days > 0 ? (
                <WrapText>Start {currentPlan.trial_days}-day Free Trial</WrapText>
              ) : (
                <WrapText>Upgrade to Ultima</WrapText>
              )}
            </Button>
          </WrapperButtons>
          <WrapperLogo>
            <LogoCustomer />
            <LogoPaddle />
          </WrapperLogo>
        </>
      )}
    </Block>
  )
})
