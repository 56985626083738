import styled from 'styled-components'
import { RouteProps } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { media } from 'src/media'

import { LayoutCenter } from 'src/components/LayoutCenter'

const Root = styled.div`
  width: 100%;
  margin-top: 90px;
  margin-bottom: 240px;

  ${media.laptop`
    max-width: 672px;
    margin-top: 106px;
  `}

  ${media.tablet`
    margin-top: 47px;
    margin-bottom: 232px;
  `}
`

const Title = styled.h1`
  font-family: var(--fonts-second);
  font-size: 62px;
  line-height: 64px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 8px;

  ${media.laptop`
    text-align: center;
  `}

  ${media.tablet`
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 0;
  `}
`

const TextWrapper = styled.div`
  margin-top: 32px;

  ${media.laptop`
    max-width: 672px;
    text-align: center;
  `}

  ${media.tablet`
    margin-top: 48px;
  `}
`

const Subtitle = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;

  ${media.laptop`
    text-align: center;
  `}

  ${media.tablet`
    font-size: 20px;
    line-height: 30px;
  `}
`

const Link = styled.a`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: inherit;
  font-feature-settings: 'liga' off;
  transition: 0.3s;

  ${media.laptop`
    text-align: center;
  `}

  ${media.tablet`
    font-size: 20px;
    line-height: 30px;
  `}

  &:hover {
    cursor: pointer;
    color: var(--color-ground-700);
  }
`

const Br = styled.br`
  display: none;

  ${media.laptop`
    display: block;
  `}
`

export const PageContact: React.FC<RouteProps> = () => {
  return (
    <LayoutCenter>
      <Helmet>
        <title>Contact Breathhh</title>
        <meta name="title" content="Get in touch" />
        <meta
          name="description"
          content="You can get technical help or write any comments and suggestions"
        />
        <meta name="keywords" content="Breathhh, Contact, Email, Feedback" />
        <meta name="twitter:title" content="Breathhh contacts" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Get in touch" />
        <meta
          property="og:description"
          content="You can get technical help or write any comments and suggestions"
        />
      </Helmet>
      <Root>
        <Title>get in touch</Title>
        <TextWrapper>
          <Subtitle>For sales enquires and technical support:</Subtitle>
          <Link href="mailto:contact@breathhh.app">contact@breathhh.app</Link>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>Any comments, ideas and suggestions:</Subtitle>
          <Link href="mailto:feedback@breathhh.app">feedback@breathhh.app</Link>
        </TextWrapper>
        <TextWrapper>
          <Subtitle>
            Also you can find us in{` `}
            <Br />
            <Link href="https://twitter.com/BreathhhApp" target="_blank" rel="noreferrer">
              Twitter
            </Link>
            ,{` `}
            <Link href=" https://www.facebook.com/breathhh.page" target="_blank" rel="noreferrer">
              Facebook
            </Link>
            {` `}
            or{` `}
            <Link href="https://www.instagram.com/breathhh.app/" target="_blank" rel="noreferrer">
              Instagram
            </Link>
          </Subtitle>
        </TextWrapper>
      </Root>
    </LayoutCenter>
  )
}
