import type { IReferrer } from 'src/store/UserStore'

const REF_NAME = `ref_url`

export class Referrer {
  static safeRef(value: IReferrer) {
    if (Referrer.getRef()) {
      return
    }

    localStorage.setItem(REF_NAME, JSON.stringify(value))
  }

  static getRef(): IReferrer | null {
    return JSON.parse(localStorage.getItem(REF_NAME))
  }

  static remove() {
    localStorage.removeItem(REF_NAME)
  }
}
