// import ReactPixel from 'react-facebook-pixel'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Footer } from 'src/components/Footer'
import { Header } from 'src/components/Header'
import { Container } from './Container'

const Root = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Main = styled.main`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const LayoutCenter: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  // const options = {
  //   autoConfig: true,
  //   debug: false,
  // }
  // // @ts-ignore
  // ReactPixel.init(`218867823794422`, options)

  // ReactPixel.pageView()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Root>
      <div>
        <Header />
        <Container>
          <Main>{children}</Main>
        </Container>
      </div>
      <Container>
        <Footer />
      </Container>
    </Root>
  )
}
