import styles from './SnackbarElement.module.scss'

export interface SnackbarElementProps {
  text: string
  emoji?: string
}

export const SnackbarElement = ({ text, emoji }: SnackbarElementProps) => {
  return (
    <div className={styles.root}>
      {Boolean(emoji) && <span className={styles.icon}>{emoji}</span>}
      <span className={styles.text}>{text}</span>
    </div>
  )
}
