import styled from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import { RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { UserStore } from 'src/store/UserStore'
// @ts-ignore
import LoadingBar from 'react-top-loading-bar'
import { Helmet } from 'react-helmet-async'

import { LayoutBase } from 'src/components/LayoutBase'
import { FormToggle, LoaderTop } from 'src/ui/atoms'

import { media } from 'src/media'

const Wrapper = styled.div`
  margin-left: 115px;
  margin-top: 34px;
  margin-bottom: 320px;

  ${media.laptop`
    margin: 146px auto 232px;
  `}

  ${media.tablet`
    margin: 132px auto 196px;
  `}

  ${media.mobile`
    margin-bottom: 160px;
  `}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 34px;
  font-family: var(--fonts);
  line-height: 38px;
  text-transform: none;
  letter-spacing: normal;
  margin: 4px 0 6px;

  ${media.tablet`
    font-size: 24px;
    line-height: 32px;
  `}
`

const NotificationWrapper = styled.div`
  display: flex;
  width: 550px;
  max-width: 550px;
  padding: 28px 0 34px;
  border-bottom: 1px solid var(--color-ground-200);
  justify-content: space-between;

  ${media.laptop`
    max-width: 672px;
    width: 672px;
  `}

  ${media.tablet`
    width: calc(100vw - 70px);
  `}

  ${media.mobile`
    max-width: calc(100vw - 40px);
  `}

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`

const InfoWrapper = styled.div`
  max-width: 455px;

  ${media.laptop`
    max-width: 556px;
  `}
`

const NoteTitle = styled.h3`
  font-family: var(--fonts);
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
  letter-spacing: normal;
`

const NoteText = styled.p`
  font-family: var(--fonts);
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: none;
  letter-spacing: normal;
  color: var(--color-ground-700);
  margin-top: 8px;

  ${media.tablet`
    font-size: 14px;
    line-height: 22px;
  `}
`

const ToggleWrapper = styled.div`
  margin-right: -12px;

  ${media.laptop`
    margin-left: 26px;
  `}

  ${media.mobile`
    margin-left: 14px;
  `}
`
export const PageNotifications: React.FC<RouteProps> = observer(() => {
  const [service, SetService] = useState(UserStore.user.service_emails)
  const [news, SetNews] = useState(UserStore.user.news_emails)
  const [tips, SetTips] = useState(UserStore.user.tips_emails)
  const [founder, SetFounder] = useState(UserStore.user.founder_emails)
  const ref = useRef(null)

  const handleChangeService = async () => {
    UserStore.serviceEmail(service)
    SetService(!service)
    localStorage.setItem(`service_emails`, `${!service}`)
  }

  const handleChangeNews = async () => {
    UserStore.newsEmail(news)
    SetNews(!news)
    localStorage.setItem(`news_emails`, `${!news}`)
  }

  const handleChangeTips = async () => {
    UserStore.tipsEmail(tips)
    SetTips(!tips)
    localStorage.setItem(`tips_emails`, `${!tips}`)
  }

  const handleChangeFounder = async () => {
    UserStore.founderEmail(founder)
    SetFounder(!founder)
    localStorage.setItem(`founder_emails`, `${!founder}`)
  }

  const convertBool = (string) => {
    return string === `true` && true
  }

  useEffect(() => {
    if (UserStore.loadingFeedback) {
      ref.current.continuousStart()
    } else {
      ref.current.complete()
    }
  }, [UserStore.loadingFeedback])
  return (
    <LayoutBase enableNav showSticky={false}>
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <LoaderTop />
      <LoadingBar color="var(--app-text-color)" ref={ref} />
      <Wrapper>
        <Title>Email Notifications</Title>
        <NotificationWrapper>
          <InfoWrapper>
            <NoteTitle>Service</NoteTitle>
            <NoteText>
              Receive notifications about actions and changes related to your account or browser
              extension
            </NoteText>
          </InfoWrapper>
          <ToggleWrapper
            onClick={() => UserStore.sendAction({ kind: `notifications_service_click` })}
          >
            <FormToggle
              onChange={handleChangeService}
              checked={
                !localStorage.getItem(`service_emails`)
                  ? UserStore.user.service_emails
                  : convertBool(localStorage.getItem(`service_emails`))
              }
            />
          </ToggleWrapper>
        </NotificationWrapper>
        <NotificationWrapper>
          <InfoWrapper>
            <NoteTitle>News & Updates</NoteTitle>
            <NoteText>
              Occasional emails with announcements, events, contests, and surveys from the Breathhh
              team
            </NoteText>
          </InfoWrapper>
          <ToggleWrapper
            onClick={() => UserStore.sendAction({ kind: `notifications_news_and_updates_click` })}
          >
            <FormToggle
              onChange={handleChangeNews}
              checked={
                !localStorage.getItem(`news_emails`)
                  ? UserStore.user.news_emails
                  : convertBool(localStorage.getItem(`news_emails`))
              }
            />
          </ToggleWrapper>
        </NotificationWrapper>
        <NotificationWrapper>
          <InfoWrapper>
            <NoteTitle>Tips & Insights</NoteTitle>
            <NoteText>
              Get personalized notifications and hacks to help you feel better and stay mindful
            </NoteText>
          </InfoWrapper>
          <ToggleWrapper
            onClick={() => UserStore.sendAction({ kind: `notifications_tips_and_insights_click` })}
          >
            <FormToggle
              onChange={handleChangeTips}
              checked={
                !localStorage.getItem(`tips_emails`)
                  ? UserStore.user.tips_emails
                  : convertBool(localStorage.getItem(`tips_emails`))
              }
            />
          </ToggleWrapper>
        </NotificationWrapper>
        <NotificationWrapper>
          <InfoWrapper>
            <NoteTitle>Founders Newsletter </NoteTitle>
            <NoteText>
              Dive deeper with stories from the creators of the service. We share our insights and
              steps from our startup, as well as the latest news in mental health, tech, and
              wellness.
            </NoteText>
          </InfoWrapper>
          <ToggleWrapper
            onClick={() =>
              UserStore.sendAction({ kind: `notifications_founders_newsletter_click` })
            }
          >
            <FormToggle
              onChange={handleChangeFounder}
              checked={
                !localStorage.getItem(`founder_emails`)
                  ? UserStore.user.founder_emails
                  : convertBool(localStorage.getItem(`founder_emails`))
              }
            />
          </ToggleWrapper>
        </NotificationWrapper>
      </Wrapper>
    </LayoutBase>
  )
})
