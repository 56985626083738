import styled from 'styled-components'
import { UserStore } from 'src/store/UserStore'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: var(--color-black);
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WrapperQR = styled.div`
  position: fixed;
  top: calc(50% - 233px);
  left: calc(50% - 207px);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 414px;
  height: 466px;
  background-color: var(--color-white);
  border-radius: 3px;
  opacity: 1;
  z-index: 11;
`

const TitleQR = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-top: 34px;
  margin-bottom: 16px;
`

const TextQR = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 10px;
  color: var(--color-ground-600);
  &:hover {
    cursor: pointer;
    color: var(--color-black);
  }
`

const InfoQR = styled.p`
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 43px;
  color: var(--color-ground-600);
`

const CloseButton = styled.svg`
  position: absolute;
  right: -36px;
  width: 28px;
  height: 28px;

  &:hover {
    cursor: pointer;
    path {
      fill: var(--color-white);
    }
  }
`

const CloseButtonCross = styled(CloseButton)`
  top: 12px;
  right: 12px;
  &:hover {
    path {
      fill: var(--color-black);
    }
  }
`

const QRcode = styled.img`
  width: 320px;
  height: 320px;
`

type TProps = {
  isFooter?: boolean
  clickClose?: () => void
}

export const AppPopup: React.FC<React.PropsWithChildren<TProps>> = ({ isFooter, clickClose }) => {
  const connectedService = UserStore.user?.facebook_connected ? `Facebook` : `Google`

  return (
    <>
      <Overlay onClick={clickClose}>Test</Overlay>
      <WrapperQR>
        <TitleQR>Scan with your phone</TitleQR>
        <QRcode width="320" height="320" src="/assets/qr-code.gif" alt="QR code" />
        <TextQR
          href="https://apps.apple.com/us/app/breathhh/id6444698538?platform=iphone"
          target="_blank"
        >
          Show in App Store
        </TextQR>
        {!isFooter && (
          <InfoQR>
            You are logged in with {connectedService}, log in to the mobile application with the
            same service
          </InfoQR>
        )}
        <CloseButtonCross onClick={clickClose}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3498 14L6.1748 7.82496L7.82472 6.17504L13.9998 12.3501L20.1748 6.17504L21.8247 7.82496L15.6497 14L21.8247 20.175L20.1748 21.825L13.9998 15.6499L7.82472 21.825L6.1748 20.175L12.3498 14Z"
              fill="#8C8C8C"
            />
          </svg>
        </CloseButtonCross>
      </WrapperQR>
    </>
  )
}
