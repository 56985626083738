import styled from 'styled-components'
import { Radar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'
import { observer } from 'mobx-react-lite'
import { IEngagement } from '../../store/UserStore/types'

Chart.register(...registerables)

interface IProps {
  days: number
  dataTest: IEngagement[]
}

const Wrapper = styled.div`
  margin-top: 10px;
  width: 483px;
  height: 395px;
`

export const RadarChart: React.FC<IProps> = observer((props) => {
  const { dataTest } = props

  const label = () => {
    return `Engagement`
  }

  const footer = (tooltipItems) => {
    let comment = ``
    tooltipItems.forEach((tooltipItem) => {
      comment = tooltipItem.parsed.r
    })
    return `${comment}`
  }

  const options = {
    layout: {
      padding: 20,
    },
    spanGaps: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        ticks: {
          display: false, // Hides the labels in the middel (numbers)
          font: {
            size: 22,
          },
        },
        beginAtZero: true,
        suggestedMin: -1,
        suggestedMax: 10,
        step: 1,
      },
    },
    plugins: {
      legend: false,
      tooltip: {
        displayColors: false,
        backgroundColor: `black`,
        padding: 12,
        titleSpacing: 3,
        titleMarginBottom: 14,
        titleFont: {
          size: 12,
          fontFamily: `var(--fonts)`,
        },
        bodyFont: {
          size: 10,
          fontFamily: `var(--fonts)`,
        },
        footerFont: {
          size: 24,
          fontFamily: `var(--fonts)`,
        },
        callbacks: {
          label,
          labelTextColor() {
            return `#8C8C8C`
          },
          footer,
        },
      },
    },
  }

  const data = {
    labels: dataTest.map((item) => {
      return item.category_title
    }),
    datasets: [
      {
        label: `engagement`,
        data: dataTest.map((item) => {
          return item.engagement
        }),
        borderColor: `black`,
        backgroundColor: `rgba(96, 130, 218, 0.2)`,
        borderWidth: 2,
        pointRadius: 2,
      },
    ],
  }
  return (
    <Wrapper>
      {/* @ts-ignore */}
      <Radar data={data} options={options} />
    </Wrapper>
  )
})
