import { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'

import { Button, Spacer } from 'src/ui/atoms'

import css from './Breath.module.scss'

export const Breath = ({ nextStep, time = 24_000 }) => {
  let steps = [`Inhale`, `Hold`, `Breathe out`, `Hold`]

  const timeoutTick = useRef<ReturnType<typeof setTimeout>>()
  const timeoutBeforeStart = useRef<ReturnType<typeof setInterval>>()

  const [word, setWord] = useState(steps[0])
  const [secBeforeStart, setSecBeforeStart] = useState(3)

  const practiceTime = time
  const timeBeforeStartPractice = 8_000
  const timeBeforeTimer = 2_000

  const [isShowText, setShowText] = useState(false)
  const [isShowCountdown, setShowCountdown] = useState(false)
  const [isAnimate, setAnimate] = useState(false)
  const [runProgress, setRunProgress] = useState(false)

  const tick = () => {
    const [first, ...nextSteps] = steps
    steps = [...nextSteps, first]
    setWord(first)
    timeoutTick.current = setTimeout(() => {
      tick()
    }, 4_000)
  }

  const startTimer = () => {
    if (!timeoutBeforeStart.current) {
      timeoutBeforeStart.current = setInterval(() => {
        setSecBeforeStart((second) => {
          return second - 1
        })
      }, 2_000)
    }
  }

  let tickTimeout: ReturnType<typeof setTimeout>
  const tickTimer = () => {
    tickTimeout = setTimeout(() => tick(), timeBeforeStartPractice)
  }

  const atTheEndOfPractice = () => {
    setRunProgress(false)
    setShowText(false)
    setAnimate(false)
    setSecBeforeStart(3)
    clearInterval(timeoutBeforeStart.current)
  }

  let practiceFinishTimeout: ReturnType<typeof setTimeout>
  const finishPractice = () => {
    practiceFinishTimeout = setTimeout(() => {
      atTheEndOfPractice()
    }, practiceTime)
  }

  const startBreath = () => {
    tickTimer()
    setTimeout(() => {
      if (timeoutTick.current) {
        clearTimeout(timeoutTick.current)
      }
    })
    finishPractice()
  }

  let runTimeout: ReturnType<typeof setTimeout>
  const startPractice = () => {
    runTimeout = setTimeout(() => {
      setShowText(true)
      setRunProgress(true)
      setShowCountdown(false)
    }, timeBeforeStartPractice)
  }

  useEffect(() => {
    startBreath()
    setAnimate(true)
    startPractice()
    setTimeout(() => setShowCountdown(true), timeBeforeTimer)
    setTimeout(() => startTimer(), timeBeforeTimer)
    setTimeout(() => {
      if (timeoutBeforeStart.current) {
        clearTimeout(timeoutBeforeStart.current)
      }
    })
  }, [])

  window.addEventListener(`visibilitychange`, () => {
    atTheEndOfPractice()
    setShowCountdown(false)
    clearTimeout(tickTimeout)
    clearTimeout(runTimeout)
    clearTimeout(practiceFinishTimeout)
    clearTimeout(timeoutTick.current)
  })

  const wait = (
    <div className={clsx(css.waitBlock, isAnimate && css.hideBlock)}>
      <p className={css.title}>Success! </p>
      <Spacer s="24" />
      <div className={clsx(css.breathContainer)}>
        <motion.div className={css.breathRound} />
      </div>
      <div className={css.buttonWrap}>
        <Button href={undefined} iconRight="ArrowRight" noBlank onClick={nextStep}>
          Continue
        </Button>
      </div>
    </div>
  )

  const animate = (
    <div className={clsx(css.activeBlock, isAnimate && css.showBlock)}>
      <motion.div
        transition={{
          duration: 1,
        }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        <div className={clsx(css.breathContainerActive)}>
          {!isShowText ? (
            <motion.div className={css.breathRoundActiveWait} />
          ) : (
            <motion.div className={css.breathRoundActive} />
          )}
          {runProgress && (
            <svg className={css.progressContainer}>
              <circle className={css.progress} cx="226" cy="226" r="220" />
            </svg>
          )}
        </div>
      </motion.div>
      <Spacer s="22" />
      <div className={css.progressText}>
        <p className={clsx(css.animateActive, !isShowText && css.displayNone)}>{word}</p>
        <p className={clsx(css.animate, !isShowCountdown && css.displayNone)}>{secBeforeStart} </p>
      </div>
    </div>
  )

  return (
    <div>
      <div className={css.container}>
        {/* @ts-ignore */}
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={!isAnimate ? 0 : 1}
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: isAnimate ? 0 : 1 }}
          >
            {!isAnimate ? wait : animate}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}
