import { useEffect, useState, useRef } from 'react'

import { chromeExtId } from 'src/utils/config'

export enum E_EXT_STATE {
  UNKNOWN,
  INSTALLED,
  NOT_INSTALLED,
}

export const useExtension = () => {
  const [value, valueSet] = useState<E_EXT_STATE>(E_EXT_STATE.UNKNOWN)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const clearTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
  }

  useEffect(() => {
    try {
      chrome.runtime.sendMessage(chromeExtId, { message: `version` }, (reply) => {
        if (reply) {
          valueSet(E_EXT_STATE.INSTALLED)
          // console.log(`>> extension check: INSTALLED`)
        } else {
          valueSet(E_EXT_STATE.NOT_INSTALLED)
          // console.log(`>> extension check: NOT_INSTALLED`)
        }

        clearTimer()
      })

      // Если экстеншен старой версии, то он не ответит на сообщение с веба, и будет долго висеть, пока не отвалиться
      // по таймауту
      timer.current = setTimeout(() => {
        if (value === E_EXT_STATE.UNKNOWN) {
          valueSet(E_EXT_STATE.NOT_INSTALLED)
        }
      }, 2_000)
    } catch (error) {
      clearTimer()
      valueSet(E_EXT_STATE.NOT_INSTALLED)
      console.log(`🍅>> extension check:`, error)
    }

    return () => {
      clearTimer()
    }
  }, [])

  return [value] as const
}
