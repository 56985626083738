import styled from 'styled-components'
import { RouteProps } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { LayoutBase } from 'src/components/LayoutBase'
import { media } from 'src/media'

const Content = styled.div`
  margin-top: 57px;

  ${media.tablet`
    margin-top: 21px;
  `}

  ${media.mobile`
    max-width: 320px;
  `}
`

const Title = styled.h4`
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--color-ground-300);
`

const Caption = styled.p`
  color: var(--color-ground-700);
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  font-weight: 500; ;
`

const Subtitle = styled.p`
  margin-top: 64px;
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
`

const Text = styled.p`
  margin-top: 16px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
`

const Pad = styled.div`
  padding-top: 256px;

  ${media.laptop`
    padding-top: 160px;
  `}

  ${media.tablet`
    padding-top: 132px;
  `}
`

const Link = styled.a`
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: inherit;
  font-feature-settings: 'liga' off;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: var(--color-ground-700);
  }
`

export const PageTerms: React.FC<RouteProps> = () => {
  return (
    <LayoutBase>
      <Helmet>
        <title>Breathhh Terms of Use</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <Content>
        <Title>Breathhh Terms of Use</Title>
        <Caption>Last update: April 02, 2024</Caption>
        <Subtitle>Introduction</Subtitle>
        <Text>
          These Terms of Use (the "Agreement") are a contract between you and Breathhh and govern
          your access to and use of the Breathhh browser extension (the Breathhh website located at
          www.breathhh.app) and all other Breathhh-branded websites (each, a "Website"), and other
          software applications and online services provided by Breathhh (collectively, the
          "Service").
        </Text>
        <Text>
          The words of which the initial letter is capitalized have meanings defined under the
          following conditions. The following definitions shall have the same meaning regardless of
          whether they appear in singular or in plural.
        </Text>
        <Subtitle>Description of the Service</Subtitle>
        <Text>
          Breathhh ("the Service") is a multifaceted platform that integrates a range of tools and
          technical solutions, including but not limited to a browser extension, a website, mobile
          applications, and additional digital resources. These components are ingeniously designed
          to support Users in enhancing their personal well-being and mindfulness. The Service
          offers an array of mental and physical practices tailored to improve Users' life quality.
          Among the core functionalities are mental wellness practices, physical exercises guidance
          such as breathing techniques, mood journaling, computer use warm-up routines, selective
          website blocking to promote focus, ambient soundscapes for relaxation, and web surfing
          activity analysis for insights into digital habits.
        </Text>
        <Text>
          It is important for Users to recognize that not all Service aspects are accessible via
          each component or in every geographic location. For instance, specific functionalities
          might be exclusively available through the browser extension or to Users in predetermined
          countries.
        </Text>
        <Text>
          Breathhh is dedicated to the continuous enhancement and evolution of the Service.
          Consequently, the Service reserves the right to modify, suspend, or discontinue any part
          of the Service at any time, which includes the availability of any feature, database, or
          content. Breathhh may also impose limits on specific features and services or restrict
          User access to parts or the entirety of the Service without notice or liability.
        </Text>
        <Text>
          By engaging with the Service, Users consent to adhere to these Terms of Use, affirming
          their understanding and acceptance of the described components, features, and potential
          limitations. Users are advised to periodically review these terms to ensure compliance and
          agreement with any updates or changes.
        </Text>
        <Subtitle>Health Considerations and Acknowledgment</Subtitle>
        <Text>
          By engaging with Breathhh ("the Service"), Users acknowledge and agree that the Service is
          not a scientifically or medically validated method for enhancing well-being or health. The
          mental and physical practices provided by the Service are selected based on market
          analysis, demand, technological advancements, and general scientific knowledge, and should
          not be considered as medical advice or treatment. The Service is designed to support and
          complement Users' general wellness efforts and is not intended to replace professional
          medical consultation, diagnosis, or treatment.
        </Text>
        <Text>
          Users are strongly advised to consult with healthcare professionals before starting any
          new wellness program, especially if they have pre-existing health conditions or concerns.
          The Service is not equipped to diagnose, treat, cure, or prevent any health conditions and
          makes no guarantees regarding the improvement of Users' well-being as a result of using
          the Service.
        </Text>
        <Text>
          Breathhh expressly disclaims any liability for adverse consequences that may arise from
          the use of the Service. Users assume full responsibility for their use of the Service and
          acknowledge that Breathhh is not liable for any direct, indirect, incidental,
          consequential, or punitive damages, including but not limited to, personal injury, pain
          and suffering, emotional distress, or similar damages.
        </Text>
        <Text>
          By using the Service, Users confirm their understanding that the practices and content
          offered by Breathhh are intended for general wellness purposes only and agree not to use
          the Service as a substitute for medical advice from a qualified healthcare provider. The
          Service's role is to enhance Users' overall wellness journey, not to provide medical
          solutions.
        </Text>
        <Text>
          This acknowledgment serves to inform Users of the nature of the Service and to clarify the
          scope of Breathhh's responsibilities. Users' decision to engage with the Service signifies
          their acceptance of these terms and their understanding of the limitations of the Service
          as a wellness tool.
        </Text>
        <Subtitle>License</Subtitle>
        <Text>
          Personal use of the Breathhh service is permitted. Commercial use of developed
          functionality within the service is discussed upon request (otherwise prohibited). It is
          important to note that the submission of a personal/private use license is not a transfer
          of ownership, and under this license, you are not allowed to modify or copy the materials,
          use the materials for any commercial purpose or for public display, or attempt to modify
          any software or functionality of the service.
        </Text>
        <Text>
          You may not remove any copyright or other intellectual property rights from the materials,
          transfer the materials to another person, or "mirror" the materials on a third party
          server. This license shall automatically terminate if you violate any of these terms and
          conditions and may be terminated at any time. Upon terminating your viewing of these
          materials or upon the termination of this license, you must destroy any downloaded
          materials in your possession whether in electronic or printed format.
        </Text>
        <Subtitle>Breathhh's Intellectual Property Rights</Subtitle>
        <Text>
          The contents of the Service, including its "look and feel" (e.g., text, graphics, images,
          logos and button icons), photographs, editorial content, notices, software (including
          html-based computer programs) and other material are protected under copyright, trademark
          and other laws. The contents of the Service belong or are licensed to Breathhh or its
          software or content suppliers. Breathhh grants you the right to view and use the Service
          subject to this Agreement. Any distribution, reprint or electronic reproduction of any
          content from the Service, other than as expressly permitted in this Agreement or with our
          prior written consent, is prohibited. If you would like to request such permission, shoot
          us an email at {` `}
          <Link href="mailto:contact@breathhh.app">contact@breathhh.app</Link>.
        </Text>
        <Text>
          Your right to access and use the Service is personal to you and is not transferable by you
          to any other person or entity. You are only entitled to access and use our Service for
          lawful, internal, and non-commercial purposes. Subject to your compliance with this
          Agreement, Breathhh hereby grants to you, a personal, worldwide, non-assignable,
          non-sublicensable, non-transferrable, and non-exclusive license to use the software and
          content provided to you as part of the Service (for personal, non-commercial purposes) and
          download a single copy of our software for such use.
        </Text>
        <Text>
          You agree that Breathhh may use your feedback, suggestions, or ideas in any way, including
          in future modifications of the Service, other products or services, advertising or
          marketing materials (although any use of your personal information will be subject to our
          privacy policy).
        </Text>
        <Subtitle>Privacy and your Personal Information</Subtitle>
        <Text>
          The Service is subject to Breathhh's privacy and data protection practices (our "Privacy
          Policy"). Please read Breathhh's Privacy Policy found at
          https://breathhh.app/privacy_policy. Our Privacy Policy explains how Breathhh collects,
          uses and shares your information when you visit our Website, use the Breathhh browser
          extension or otherwise access the Service. By using the Service, you consent to Breathhh's
          collection, use, and sharing of your information as set forth in our Privacy Policy.
        </Text>
        <Subtitle>Technology Disclaimer</Subtitle>
        <Text>
          As you navigate and utilize the services provided by Breathhh, please be aware that
          interruptions and disruptions are a possibility, stemming from factors beyond our control
          such as third-party service failures or the inherent unpredictability of the Internet
          infrastructure. Additionally, Breathhh may periodically perform updates, maintenance, or
          repairs on the service, potentially leading to temporary interruptions. Despite our
          commitment to ensuring the service's availability, we cannot guarantee uninterrupted
          access at all times.
        </Text>
        <Text>
          Your experience with the Breathhh is contingent upon your provision of necessary resources
          such as a suitable wireless service plan, compatible software, Internet connectivity, and
          any other required equipment or services. It is important to note that compatibility and
          accessibility of the Breathhh are not guaranteed across all browsers or service plans.
          Furthermore, availability of our services or the ability to place orders for products is
          not assured in every geographic location.
        </Text>
        <Text>
          Unexpected technical or other challenges may arise, potentially leading to data loss, or
          interruptions in service, including the loss of personalization settings. Breathhh cannot
          be held responsible for any such occurrences, including issues related to the timeliness,
          accuracy, deletion, non-delivery, or failure to store user data, communications, or
          personalization settings.
        </Text>
        <Text>
          Breathhh reserves the unilateral right to modify, enhance, or cease any component of its
          services, including features and functionalities, at any moment without prior notification
          to its users, regardless of their subscription status (free or paid). There is no
          obligation on the part of Breathhh to reveal details concerning the operational aspects of
          its service technologies, such as algorithms, artificial intelligence, or machine learning
          components. Changes, including deactivations or alterations to these functionalities, may
          occur without notice. By using Breathhh services, users accept and consent to potential
          changes that may impact the services’ performance, features, or availability.
        </Text>
        <Subtitle>Notification Disclaimer</Subtitle>
        <Text>
          You understand and agree that any notifications provided to you through the Service may be
          delayed or prevented by a variety of factors. Breathhh does its best to provide
          notifications in a timely manner with accurate information. However, we neither guarantee
          the delivery nor the accuracy of the content of any notification. You also agree that
          Breathhh shall not be liable for any delays, failure to deliver, or misdirected delivery
          of any notification; for any errors in the content of a notification; or for any actions
          taken or not taken by you or any third-party in reliance on a notification.
        </Text>
        <Subtitle>Limitations on Breathhh's Liability</Subtitle>
        <Text>
          Breathhh shall in no event be responsible or liable to you or to any third party, whether
          in contract, warranty, tort (including negligence) or otherwise, for any indirect,
          special, incidental, consequential, exemplary, liquidated or punitive damages, including
          but not limited to loss of profit, revenue or business, arising in whole or in part from
          your access to the service, your use of the service or this agreement, or for any loss or
          damage caused by your reliance on information obtained on or through the service, even if
          Breathhh has been advised of the possibility of such damages.
        </Text>
        <Text>
          Because some states or jurisdictions do not allow the exclusion or the limitation of
          liability for consequential or incidental damages, in such states or jurisdictions, the
          liability of Breahhh shall be limited to the fullest extent permitted by law. Therefore,
          the foregoing limitations shall apply to the maximum extent permitted by law. Nothing in
          this agreement affects statutory rights that cannot be waived or limited by contract.
        </Text>
        <Subtitle>"AS IS" and "AS AVAILABLE" Disclaimer</Subtitle>
        <Text>
          The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects
          without warranty of any kind. To the maximum extent permitted under applicable law, the
          Company, on its own behalf and on behalf of its Affiliates and its and their respective
          licensors and service providers, expressly disclaims all warranties, whether express,
          implied, statutory or otherwise, with respect to the Service, including all implied
          warranties of merchantability, fitness for a particular purpose, title and
          non-infringement, and warranties that may arise out of course of dealing, course of
          performance, usage or trade practice. Without limitation to the foregoing, the Company
          provides no warranty or undertaking, and makes no representation of any kind that the
          Service will meet Your requirements, achieve any intended results, be compatible or work
          with any other software, applications, systems or services, operate without interruption,
          meet any performance or reliability standards or be error free or that any errors or
          defects can or will be corrected.
        </Text>
        <Text>
          Without limiting the foregoing, neither the Company nor any of the company's provider
          makes any representation or warranty of any kind, express or implied: as to the operation
          or availability of the Service, or the information, content, and materials or products
          included thereon; that the Service will be uninterrupted or error-free; as to the
          accuracy, reliability, or currency of any information or content provided through the
          Service; or that the Service, its servers, the content, or e-mails sent from or on behalf
          of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or
          other harmful components.
        </Text>
        <Text>
          Some jurisdictions do not allow the exclusion of certain types of warranties or
          limitations on applicable statutory rights of a consumer, so some or all of the above
          exclusions and limitations may not apply to You. But in such a case the exclusions and
          limitations set forth in this section shall be applied to the greatest extent enforceable
          under applicable law.
        </Text>
        <Subtitle>Termination</Subtitle>
        <Text>
          We may terminate or suspend Your access immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if You breach these Terms and
          Conditions. Upon termination, Your right to use the Service will cease immediately.
        </Text>
        <Subtitle>Disputes Resolution</Subtitle>
        <Text>
          If You have any concern or dispute about the Service, You agree to first try to resolve
          the dispute informally by contacting the Breathhh.
        </Text>
        <Subtitle>Contact Us</Subtitle>
        <Text>
          If you have any questions about these Terms and Conditions, You can contact us by email:
          {` `}
          <Link href="mailto:contact@breathhh.app">contact@breathhh.app</Link>
        </Text>
        <Pad />
      </Content>
    </LayoutBase>
  )
}
