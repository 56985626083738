import { makeAutoObservable, runInAction } from 'mobx'

import { fetcher } from 'src/utils/fetcher'
import { UserStore } from 'src/store/UserStore'

import { ITransaction } from './types'

export class TransactionsStore {
  loading = false

  transactions: ITransaction[] = []

  constructor() {
    makeAutoObservable(this)
  }

  async fetch() {
    this.loading = true

    const { data } = await fetcher.get<ITransaction[]>(`/users/transactions`, {
      headers: { AUTHORIZATION: UserStore.token },
    })

    runInAction(() => {
      this.transactions = data
      this.loading = false
    })
  }
}
