import styled from 'styled-components'
import { useState, useEffect, useRef } from 'react'
import { media } from 'src/media'
import * as icons from 'src/ui/icons'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 0;

  ${media.laptop`
    padding: 64px 0;
  `}

  ${media.tablet`
    padding: 48px 0;
  `}
`

const PictureWrap = styled.div`
  position: relative;
  max-width: 1120px;
  width: 100%;
  height: 680px;
  margin: 0 auto;
  overflow: hidden;
  border: 2px solid var(--color-black);
  box-sizing: border-box;
  border-radius: 34px;

  ${media.laptop`
    max-width: 672px;
    height: 860px;
  `}

  ${media.tablet`
    height: 500px;
  `}

  ${media.mobile`
    max-width: 320px;
  `}
`

const Picture = styled.div`
  background-image: url('/assets/shapes.png');
  background-size: 550px 1215px;
  background-position: center;
  background-repeat: repeat;
  width: 550px;
  height: 2430px;
  animation: moveSlideshow 16s linear infinite;
  margin: 0 auto;

  ${media.laptop`
    width: 440px;
    background-size: 440px 1215px;
  `}

  ${media.tablet`
    width: 400px;
  `}

  @media (max-width: 520px) {
    width: 300px;
  }

  @media (max-width: 420px) {
    width: 270px;
  }

  @media (max-width: 400px) {
    width: 240px;
  }

  @media (max-width: 330px) {
    width: 220px;
  }

  @keyframes moveSlideshow {
    0 {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1215px);
    }
  }
`

const Points = styled.img`
  position: absolute;
  width: 46px;
  height: 10px;
  top: 34px;
  left: 34px;
  z-index: 4;
`

const Cursor = styled.img`
  position: absolute;
  width: 16px;
  height: 25px;
  top: 356px;
  right: 470px;
  z-index: 4;

  ${media.laptop`
    top: 449px;
    right: 236px;
  `}

  ${media.tablet`
    top: 306px;
    right: 116px;
  `}
`

const Title = styled.h2`
  text-align: center;

  ${media.laptop`
    max-width: 672px;
  `}

  ${media.mobile`
    max-width: 320px;
  `}
`

const Text = styled.p`
  max-width: 700px;
  text-align: center;
  margin: 32px 0 58px;

  ${media.laptop`
    margin: 28px 0 40px;
  `}

  ${media.laptop`
    margin: 32px 0 48px;
  `}

  ${media.mobile`
    margin: 24px 0 48px;
  `}
`

const Head = styled.img`
  position: absolute;
  width: 300px;
  height: 300px;
  top: 100px;
  right: -20px;
  animation: showhead ease-in 1s forwards;

  ${media.laptop`
    width: 250px;
    height: 250px;
    top: 128px;
    right: -15px;
  `}

  ${media.tablet`
    width: 140px;
    height: 140px;
    top: 90px;
    right: -6px;
  `}

  @keyframes showhead {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`

const TextBox = styled.a`
  position: absolute;
  top: 68px;
  right: 242px;
  padding: 12px 16px;
  background: var(--color-black);
  border-radius: 14px;
  opacity: 0;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  animation: showtext ease-in 0.4s 1s forwards;

  ${media.laptop`
    top: 96px;
    right: 212px;
  `}

  ${media.tablet`
    padding: 8px 12px;
    top: 68px;
    right: 123px;
  `}

  @keyframes showtext {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
    & > * {
      color: var(--color-white);
    }
    & svg path {
      fill: var(--color-white);
    }
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 16px solid var(--color-black);
    border-left: 16px solid transparent;
    position: absolute;
    bottom: -15px;
    right: 30px;

    ${media.mobile`
      bottom: -9px;
      right: 24px;
  `}
  }
`

const TextLink = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-ground-700);

  ${media.mobile`
    & svg {
        width: 16px;
        height: 16px;
      }
  `}
`

const TextHead = styled.p`
  font-family: var(--fonts-second);
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: var(--color-white);

  ${media.tablet`
    font-size: 16px;
    line-height: 22px;
  `}
`

const TextHeadGrey = styled(TextHead)`
  color: inherit;
`

export const Demo: React.FC = () => {
  const watcherRef = useRef<HTMLDivElement>(null)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: `-50%`,
      threshold: 0,
    }

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => setAnimate(true), 1300)
        }
      })
    }

    const scrollObserver = new IntersectionObserver(callback, options)

    scrollObserver.observe(watcherRef.current)

    return () => {
      scrollObserver.disconnect()
    }
  }, [])

  return (
    <Root>
      <Title>no need to develop a&nbsp;habit</Title>
      <Text>Practices appear automatically at the right time based on your behaviour patterns</Text>
      <PictureWrap ref={watcherRef}>
        <Points width="46" height="10" src="/assets/demo-points.svg" alt="points" />
        <Cursor width="16" height="25" src="/assets/cursor.svg" alt="points" />
        <Picture />
        {animate && (
          <>
            <Head width="300" height="300" src="/assets/head.svg" alt="head" />
            <TextBox
              href="https://chrome.google.com/webstore/detail/breathhh/niipedbmjiopjpmjcpigiflabghcckeo"
              target="blank"
            >
              <TextHead>Time to Breathe</TextHead>
              <TextLink>
                <TextHeadGrey> Start</TextHeadGrey>
                <icons.ArrowUpRightGrey />
              </TextLink>
            </TextBox>
          </>
        )}
      </PictureWrap>
    </Root>
  )
}
