import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { UserStore } from 'src/store/UserStore'
import * as icons from 'src/ui/icons'
import { media } from 'src/media'
import styled from 'styled-components'

const Root = styled.div`
  display: flex;
  width: 265px;
  flex-direction: column;
  color: #232a34;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-top: 48px;

  &::-webkit-scrollbar {
    display: none;
  }

  & a {
    color: inherit;
    text-decoration: none;
  }

  ${media.laptop`
    position: absolute;
    width: 100vw;
    box-sizing: border-box;
    flex-direction: row;
    overflow-x: scroll;
    max-width: 100vw;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-ground-200);
    padding-left: calc((100vw - 840px) / 2 );
    right: 0;
    left: 0;
    `}

  ${media.tablet`
    margin-left: 0;
  `}
`

const Wrapper = styled.div`
  display: flex;
  height: 26px;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 28px;
  letter-spacing: normal;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    color: var(--color-ground-800);

    & svg path {
      fill: var(--color-ground-800);
    }
  }

  ${media.laptop`
    margin-bottom: 0;

    &:first-child{
      margin-left: 34px;
    }
  `}

  ${media.tablet`
    &:last-child{
    margin-right: 22px;
    }

    &:first-child{
      margin-left: 22px;
    }
  `}
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 0 14px;

  ${media.laptop`
    margin-left: 0;
  `}
`

// const PictureWrapper = styled.a`
//   position: relative;
//   height: 96px;
//   width: 265px;
//   margin-top: 20px;
//   background-color: var(--color-ground-100);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 16px 0 16px 20px;
//   box-sizing: border-box;
//   transition: 0.3s;

//   &:hover {
//     cursor: pointer;
//     background-color: var(--color-sunrise);
//   }

//   ${media.laptop`
//     display: none;
//   `}
// `

// const UltimaText = styled.p`
//   font-family: var(--fonts-second);
//   font-weight: 400;
//   font-size: 26px;
//   line-height: 32px;
//   white-space: break-spaces;
// `
// const UltimaPicture = styled.img`
//   position: absolute;
//   width: 80px;
//   height: 80px;
//   bottom: 0;
//   right: 0;
// `

interface IProps {
  handleShowPopup?: () => void
  showPopup?: boolean
  browserName?: string | boolean
}

export const Menu: React.FC<IProps> = observer(() => {
  const watcherRef = useRef<HTMLDivElement>(null)
  const path = document.location.pathname
  let scroll = 0

  switch (path) {
    case `/plans`:
      scroll = 150
      break
    case `/notifications`:
      scroll = 300
      break
    case `/integrations`:
      scroll = 450
      break
    case `/account`:
      scroll = 600
      break
    default:
      scroll = 0
  }

  useEffect(() => {
    watcherRef.current.scrollLeft = scroll
  }, [])

  return (
    <Root ref={watcherRef}>
      <NavLink to="/dashboard">
        <Wrapper onClick={() => UserStore.sendAction({ kind: `dashboard_click` })}>
          <IconWrapper>
            <icons.Stats />
          </IconWrapper>
          Dashboard
        </Wrapper>
      </NavLink>
      <NavLink to="/plans">
        <Wrapper onClick={() => UserStore.sendAction({ kind: `plan_and_billing_click` })}>
          <IconWrapper>
            <icons.Wallet />
          </IconWrapper>
          Plan&nbsp;&&nbsp;Billing
        </Wrapper>
      </NavLink>
      <NavLink to="/notifications">
        <Wrapper onClick={() => UserStore.sendAction({ kind: `notifications_click` })}>
          <IconWrapper>
            <icons.Bell />
          </IconWrapper>
          Notifications
        </Wrapper>
      </NavLink>
      <NavLink to="/integrations">
        <Wrapper onClick={() => UserStore.sendAction({ kind: `integrations_click` })}>
          <IconWrapper>
            <icons.Stars />
          </IconWrapper>
          Integrations
        </Wrapper>
      </NavLink>
      <NavLink to="/account">
        <Wrapper onClick={() => UserStore.sendAction({ kind: `account_click` })}>
          <IconWrapper>
            <icons.User />
          </IconWrapper>
          Account
        </Wrapper>
      </NavLink>
      {/* {UserStore.isNotActiveSubscription && (
        <PictureWrapper
          href="/plans"
          onClick={() => UserStore.sendAction({ kind: `get_ultima_banner_click` })}
        >
          <UltimaText>
            {UserStore.isTrialAbility ? `Try Ultima      for free` : `Get Ultima     from $5.8`}
          </UltimaText>
          <UltimaPicture src="/assets/sense.svg" alt="eye" />
        </PictureWrapper>
      )} */}
    </Root>
  )
})
