import { useEffect } from 'react'
import { PlansStore } from 'src/store/PlansStore'

type TOptions = {
  filterTrial?: boolean
}

export function usePlansStore(options: TOptions = {}) {
  const { filterTrial } = options

  useEffect(() => {
    ;(async () => {
      if (PlansStore.plans.length <= 0 && !PlansStore.loading) {
        await PlansStore.fetchPlans()
      }
    })()
  }, [])

  return { plans: filterTrial ? PlansStore.plansWithoutTrial : PlansStore.plans }
}
