import React from 'react'
import cx from 'classnames'
import * as icons from 'src/ui/icons'

import css from './Button.module.scss'

type TButton = {
  appearance?: `simple-invert` | `provide` | `ghost`
  tag?: string
  href?: string
  noBlank?: boolean
  iconLeft?: keyof typeof icons
  iconRight?: keyof typeof icons
} & React.ComponentProps<`button`>

export const Button: React.FC<TButton> = (props) => {
  const { children, tag, href, noBlank, iconLeft, iconRight, appearance, ...rest } = props

  return React.createElement(
    tag || href ? `a` : `button`,
    {
      ...rest,
      href,
      ...(href && !noBlank ? { target: `blank` } : {}),
      className: cx(css.root, appearance && css[`appearance-${appearance}`]),
    },
    <>
      {iconLeft && <span className={css.iconLeft}>{React.createElement(icons[iconLeft])}</span>}
      <span className={css.content}>{children}</span>
      {iconRight && <span className={css.iconRight}>{React.createElement(icons[iconRight])}</span>}
    </>
  )
}
