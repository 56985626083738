import styled from 'styled-components'
import { useState, useRef, useEffect } from 'react'
import { RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { UserStore } from 'src/store/UserStore'
// @ts-ignore
import LoadingBar from 'react-top-loading-bar'
import { Helmet } from 'react-helmet-async'

import { LayoutBase } from 'src/components/LayoutBase'
import { AppPopup } from 'src/components/AppPopup'
import { FormToggle, LoaderTop } from 'src/ui/atoms'

import { media } from 'src/media'

const Wrapper = styled.div`
  margin-left: 115px;
  margin-top: 34px;
  margin-bottom: 320px;

  ${media.laptop`
    margin: 146px auto 232px;
  `}

  ${media.tablet`
    margin: 132px auto 196px;
  `}

  ${media.mobile`
    margin-bottom: 160px;
  `}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 34px;
  font-family: var(--fonts);
  line-height: 38px;
  text-transform: none;
  letter-spacing: normal;
  margin: 4px 0 6px;

  ${media.tablet`
    font-size: 24px;
    line-height: 32px;
  `}
`

const NotificationWrapper = styled.div`
  display: flex;
  width: 550px;
  max-width: 550px;
  padding: 28px 0 34px;
  border-bottom: 1px solid var(--color-ground-200);
  justify-content: space-between;

  ${media.laptop`
    max-width: 672px;
    width: 672px;
  `}

  ${media.tablet`
    width: calc(100vw - 70px);
  `}

  ${media.mobile`
    max-width: calc(100vw - 40px);
  `}

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`

const InfoWrapper = styled.div`
  max-width: 455px;

  ${media.laptop`
    max-width: 556px;
  `}
`

const NoteTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconTitle = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 8px;
`

const NoteTitle = styled.h3`
  font-family: var(--fonts);
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
  letter-spacing: normal;
`

const NoteText = styled.p`
  font-family: var(--fonts);
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: none;
  letter-spacing: normal;
  color: var(--color-ground-700);
  margin-top: 8px;

  ${media.tablet`
    font-size: 14px;
    line-height: 22px;
  `}
`

const ToggleWrapper = styled.div`
  margin-right: -12px;
  &:hover {
    cursor: not-allowed;
  }

  ${media.laptop`
    margin-left: 26px;
  `}

  ${media.mobile`
    margin-left: 14px;
  `}
`

const ToggleWrapperActive = styled(ToggleWrapper)`
  &:hover {
    cursor: default;
  }
`

export const PageIntegrations: React.FC<RouteProps> = observer(() => {
  const [google, SetGoogle] = useState(false)
  const [slack, SetSlack] = useState(false)
  const [breathApp, SetBreathApp] = useState(
    localStorage.getItem(`breath_app`) ? localStorage.getItem(`breath_app`) : false
  )
  const [appPopup, setAppPopup] = useState(false)
  const ref = useRef(null)

  const devices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i

  const handleChangeGoogleCalendar = async () => {
    // UserStore.google(google)
    SetGoogle(!google)
    localStorage.setItem(`google_calendar`, `${!google}`)
  }

  const handleChangeBreathApp = async () => {
    // UserStore.google(google)
    SetBreathApp(!breathApp)
    localStorage.setItem(`breath_app`, `${!breathApp}`)
    if (!breathApp) {
      if (devices.test(navigator.userAgent)) {
        window.open(`https://apps.apple.com/us/app/breathhh/id6444698538?platform=iphone`, `_blank`)
      } else {
        setAppPopup(true)
      }
      UserStore.sendAction({ kind: `integrations_devices_click` })
    }
  }

  const handleChangeSlack = async () => {
    // UserStore.slack(slack)
    SetSlack(!slack)
    localStorage.setItem(`slack_emails`, `${!slack}`)
  }

  const convertBool = (string) => {
    return string === `true` && true
  }

  const clickClose = () => {
    setAppPopup(false)
  }

  useEffect(() => {
    if (UserStore.loadingFeedback) {
      ref.current.continuousStart()
    } else {
      ref.current.complete()
    }
  }, [UserStore.loadingFeedback])

  return (
    <>
      <LayoutBase enableNav showSticky={false}>
        <Helmet>
          <title>Integrations</title>
        </Helmet>
        <LoaderTop />
        <LoadingBar color="var(--app-text-color)" ref={ref} />
        <Wrapper>
          <Title>Integrations</Title>
          <NotificationWrapper>
            <InfoWrapper>
              <NoteTitleWrapper>
                <IconTitle width="26" height="26" src="/assets/apple-emblem.svg" alt="apple logo" />
                <NoteTitle>Breathhh for iPhone and Apple Watch</NoteTitle>
              </NoteTitleWrapper>
              <NoteText>Get practice, even when you aren’t at your PC</NoteText>
            </InfoWrapper>
            <ToggleWrapperActive>
              <FormToggle
                onChange={handleChangeBreathApp}
                checked={
                  !localStorage.getItem(`breath_app`)
                    ? // ? UserStore.user.google
                      false
                    : convertBool(localStorage.getItem(`breath_app`))
                }
                // loading
              />
            </ToggleWrapperActive>
          </NotificationWrapper>
          <NotificationWrapper>
            <InfoWrapper>
              <NoteTitleWrapper>
                <IconTitle
                  width="26"
                  height="26"
                  src="/assets/google-calendar-emblem.svg"
                  alt="google calendar logo"
                />
                <NoteTitle>Google Calendar (Beta)</NoteTitle>
              </NoteTitleWrapper>
              <NoteText>To increase the level of relevance of the display of practices</NoteText>
            </InfoWrapper>
            <ToggleWrapper
              onClick={() => UserStore.sendAction({ kind: `integrations_google_calendar_click` })}
            >
              <FormToggle
                onChange={handleChangeGoogleCalendar}
                checked={
                  !localStorage.getItem(`google_calendar`)
                    ? // ? UserStore.user.google
                      false
                    : convertBool(localStorage.getItem(`google_calendar`))
                }
                loading
              />
            </ToggleWrapper>
          </NotificationWrapper>
          <NotificationWrapper>
            <InfoWrapper>
              <NoteTitleWrapper>
                <IconTitle width="26" height="26" src="/assets/slack-emblem.svg" alt="slack logo" />
                <NoteTitle>Slack (Beta)</NoteTitle>
              </NoteTitleWrapper>
              <NoteText>For practice suggestions in the direct messages</NoteText>
            </InfoWrapper>
            <ToggleWrapper
              onClick={() => UserStore.sendAction({ kind: `integrations_slack_click` })}
            >
              <FormToggle
                onChange={handleChangeSlack}
                checked={
                  !localStorage.getItem(`slack_emails`)
                    ? // ? UserStore.user.slack
                      false
                    : convertBool(localStorage.getItem(`slack`))
                }
                loading
              />
            </ToggleWrapper>
          </NotificationWrapper>
        </Wrapper>
      </LayoutBase>
      {appPopup && <AppPopup clickClose={clickClose} />}
    </>
  )
})
