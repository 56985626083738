import styled from 'styled-components'
import { media } from 'src/media'
import * as icons from 'src/ui/icons'

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 18px;
  column-gap: 20px;
  font-size: 16px;
  line-height: 25px;

  ${media.mobile`
    grid-template-columns: 1fr;
    margin-top: -13px;
  `}
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const IconWrap = styled.div`
  margin-right: 8px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DATA = [
  { title: `Personalized  AI for Breaks`, Icon: icons.Stars },
  { title: `Unlimited Focus Categories`, Icon: icons.Eye },
  { title: `Unlimited Mood Picking`, Icon: icons.Face },
  { title: `Priority Support`, Icon: icons.Message },
  { title: `Unlimited Breathing Practices`, Icon: icons.Wind },
]

export const PlansBenefits = () => {
  return (
    <Block>
      {DATA.map((item, key) => {
        return (
          <Row key={key}>
            <IconWrap>
              <item.Icon />
            </IconWrap>
            <div>{item.title}</div>
          </Row>
        )
      })}
    </Block>
  )
}
