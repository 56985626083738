import { useState, useRef, useEffect } from 'react'
import dayjs from 'dayjs'

import { UserStore } from 'src/store/UserStore'

const TIMEOUT_MINUTES = process.env.NODE_ENV === `production` ? 25 : 5

const STORAGE_NAME = `__paddle_success`
const CHECK_TIMEOUT = 3_000

export const usePlansCheck = () => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>()
  const [isPaymentProcessing, isPaymentProcessingSet] = useState(false)

  const timerClear = () => {
    if (timerRef.current) {
      console.log(`>> убираем таймер`)
      clearTimeout(timerRef.current)
    }
  }

  const handleComplete = () => {
    localStorage.removeItem(STORAGE_NAME)
    isPaymentProcessingSet(false)
    timerClear()
  }

  const startCheck = async () => {
    const currentFlag = localStorage.getItem(STORAGE_NAME)

    if (currentFlag) {
      const { persisted_date, persisted_user_plan } = JSON.parse(currentFlag) as {
        persisted_date: string
        persisted_user_plan: string
      }

      console.log(`>> время оплаты`, persisted_date)
      console.log(`>> план id пользователя на момент оплаты`, persisted_user_plan)

      const mountTime = dayjs(persisted_date)

      const diff = dayjs().diff(mountTime, `minute`)
      console.log(`>> прошло минут с момента оплаты: `, diff)

      if (diff >= TIMEOUT_MINUTES) {
        handleComplete()
        console.log(`>> таймер проверки покупки истек`)
      } else {
        if (!isPaymentProcessing) {
          isPaymentProcessingSet(true)
        }

        await UserStore.fetchUser()
        const newUserPlanId = UserStore.selectUserActiveSubscription?.external_plan_id
        console.log(`>> план пользователя, после его рефетча: `, newUserPlanId)

        if (newUserPlanId === persisted_user_plan) {
          timerRef.current = setTimeout(startCheck, CHECK_TIMEOUT)
          console.log(`>> план не поменялся, проверим еще раз, через: `, CHECK_TIMEOUT)
        } else {
          handleComplete()
          console.log(`>> план пользователя поменялся`)
        }
      }
    } else if (isPaymentProcessing) {
      isPaymentProcessingSet(false)
    }
  }

  const handleUserPlanUpdate = () => {
    const stateToPersist = {
      persisted_date: dayjs().toString(),
      persisted_user_plan: UserStore.selectUserActiveSubscription?.external_plan_id,
    }

    console.log(`!!stateToPersist`, stateToPersist)

    localStorage.setItem(STORAGE_NAME, JSON.stringify(stateToPersist))
    isPaymentProcessingSet(true)
    startCheck()
  }

  useEffect(() => {
    startCheck()
    return timerClear
  }, [])

  return { isPaymentProcessing, handleUserPlanUpdate }
}
