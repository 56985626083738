import styled from 'styled-components'

import { Face, Tick, Eye, Wind, Message } from 'src/ui/icons'
import { media } from 'src/media'

import { ReactComponent as LogoPaddle } from './assets/paddle.svg'
import { ReactComponent as LogoPaypal } from './assets/paypal.svg'
import { ReactComponent as LogoVisa } from './assets/visa.svg'
import { ReactComponent as LogoMastercard } from './assets/mastercard.svg'
import topImgUrl from './assets/top-image.png'

const SectionRoot = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: 680px;
  background-color: var(--color-ground-1000);
  background-image: url(${topImgUrl});
  background-repeat: no-repeat;
  background-position: 50% 0%;
  background-size: contain;

  ${media.tablet`
    min-height: auto;
  `}
`

const SectionInner = styled.div`
  width: 100%;
  height: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  ${media.tablet`
    padding: 0;
  `}
`

const StyledTitle = styled.h3`
  font-size: 42px;
  text-transform: uppercase;
  color: var(--color-white);
  line-height: 1.2;

  ${media.tablet`
    font-size: 30px;
    padding: 0 20px;
  `}
`

const StyledCaption = styled.p`
  font-size: 20px;
  line-height: 1.4;
  color: var(--color-ground-500);

  ${media.tablet`
    margin-top: 5px;
    padding: 0 30px;
    font-size: 16px;
  `}
`

const SectionMiddle = styled.div`
  padding-top: 130px;
  text-align: center;

  ${media.tablet`
    padding-top: 110px;
  `}
`

const BenefitsList = styled.ul`
  margin: 32px 0 0 0;
  padding: 8px 0;
  list-style-type: none;
  background: linear-gradient(
    74.44deg,
    rgba(52, 52, 52, 0.35) -9.18%,
    rgba(22, 22, 22, 0.35) 106.71%
  );
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 4px;
  color: var(--color-ground-500);
  text-align: left;

  ${media.tablet`
    background: transparent;
    backdrop-filter: none;
  `}
`

const BenefitsListItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 60px 12px 20px;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
`

const BenefitIconWrap = styled.span`
  display: inline-block;
  padding-right: 10px;
  line-height: 1;

  svg {
    path {
      fill: var(--color-ground-500);
    }
  }
`

const TickIconWrap = styled.span`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);

  svg {
    path {
      fill: var(--color-ground-500);
    }
  }
`

const StyledFooter = styled.footer`
  width: 100%;
  margin: 0;
  padding: 30px 24px 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  ${media.tablet`
    padding: 32px 22px;
  `}
`

const BENEFITS = [
  { text: `Unlimited Mood Picking`, icon: <Face />, key: 1 },
  { text: `Unlimited Focus Categories`, icon: <Eye />, key: 2 },
  { text: `Unlimited Breathing Practices`, icon: <Wind />, key: 3 },
  { text: `Priority Support`, icon: <Message />, key: 4 },
]

const FOOTER_ICONS = [
  { key: `paddle`, icon: <LogoPaddle /> },
  {
    key: `paypal`,
    icon: <LogoPaypal />,
  },
  {
    key: `visa`,
    icon: <LogoVisa />,
  },
  {
    key: `mastercard`,
    icon: <LogoMastercard />,
  },
]

export const PlansInfoBlock = () => {
  return (
    <SectionRoot>
      <SectionInner>
        <SectionMiddle>
          <StyledTitle>Personalized AI for Breaks</StyledTitle>
          <StyledCaption>Learns your behaviour patterns and suggests the right time</StyledCaption>
          <BenefitsList>
            {BENEFITS.map(({ text, key, icon }) => {
              return (
                <BenefitsListItem key={key}>
                  <BenefitIconWrap>{icon}</BenefitIconWrap>
                  <span>{text}</span>
                  <TickIconWrap>
                    <Tick />
                  </TickIconWrap>
                </BenefitsListItem>
              )
            })}
          </BenefitsList>
        </SectionMiddle>
        <StyledFooter>
          {FOOTER_ICONS.map(({ key, icon }) => {
            return <div key={key}>{icon}</div>
          })}
        </StyledFooter>
      </SectionInner>
    </SectionRoot>
  )
}
