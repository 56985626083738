import { motion, useAnimationControls } from 'framer-motion'
import { useEffect, useState } from 'react'

import css from './Show.module.scss'

import { ReactComponent as ArrowToMorda } from './assets/arrowtomorda.svg'

export const Show: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isHovered, setHovered] = useState(false)
  const [isFirstRender, setFirstRender] = useState(false)
  const controls = useAnimationControls()

  useEffect(() => {
    controls.start({
      x: [-75, -35, -50],
      transition: {
        duration: 2,
        type: `spring`,
      },
    })
    setTimeout(() => setFirstRender(true), 5000)
  }, [])

  useEffect(() => {
    if (isHovered) {
      controls.stop()
    } else if (isFirstRender) {
      controls.start({ x: [-75, -25, -50] })
    }
  })

  return (
    <>
      <motion.div
        className={css.root}
        animate={controls}
        transition={{
          duration: 1,
          type: `spring`,
          bounce: 0.25,
          repeat: Infinity,
          repeatDelay: 3,
        }}
        initial={false}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {children}
      </motion.div>
      {!isHovered && (
        <motion.div
          key="banner"
          className={css.bannerWrap}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <div className={css.arrowWrapper}>
            <ArrowToMorda />
          </div>
          <div className={css.description}>Appears automatically on any page</div>
        </motion.div>
      )}
    </>
  )
}
