import { useEffect } from 'react'
import { UserStore } from 'src/store/UserStore'

export function useEngagements(from: string, to: string) {
  useEffect(() => {
    ;(async () => {
      await UserStore.fetchEngagements(from, to)
    })()
  }, [from, to])

  return { engagements: UserStore.engagements, engagementsIsLoaded: UserStore.engagementsIsLoaded }
}
