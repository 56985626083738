import styled from 'styled-components'
import { media } from 'src/media'

import * as icons from 'src/ui/icons'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--fonts-second);
  margin: 96px -400px;

  @media (max-width: 1940px) {
    margin: 96px 0;
  }

  ${media.laptop`
    margin: 68px auto;
  `}

  ${media.tablet`
    margin: 48px auto;
  `}

  ${media.mobile`
    max-width: 320px;
  `}
`

const ReviewBlock = styled.div`
  width: 100%;

  ${media.laptop`
    max-width: 672px;
  `}

  ${media.tablet`
    width: 100%;
  `}
`

const ReviewText = styled.p`
  font-family: var(--fonts-second);
  max-width: 550px;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 48px;
  white-space: pre-line;

  ${media.laptop`
    max-width: 672px;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 24px;
    text-align: center;
  `}

  ${media.tablet`
    font-size: 24px;
    line-height: 30px;
    white-space: normal;
    text-align: left;
  `}

  ${media.mobile`
    font-size: 22px;
    line-height: 28px;
  `}
`

const ReviewName = styled.p`
  font-family: var(--fonts-second);
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 6px;

  ${media.laptop`
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 4px;
    text-align: center;
  `}

  ${media.tablet`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: left;
  `}

  ${media.mobile`
    font-size: 18px;
    line-height: 24px;
  `}
`

const ReviewNameWithPick = styled(ReviewName)`
  margin-top: 24px;

  ${media.laptop`
    margin-top: 28px;
  `}

  ${media.tablet`
    margin-top: 24px;
  `}

  ${media.mobile`
    margin-top: 20px;
  `}
`

const ReviewInfo = styled.h6`
  color: var(--color-ground-700);
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 36px;

  ${media.laptop`
    text-align: center;
    font-size: 24px;
    line-height: 36px;
  `}

  ${media.tablet`
    text-align: left;
    font-size: 16px;
    line-height: 20px;
  `}
`

const BlockWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 50% 50%;
  max-width: 1120px;
  width: 100%;

  &:not(:first-child) {
    margin-top: 96px;
  }

  ${media.laptop`
    gap: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -34px;
    margin-left: -34px;

    & > * {
      margin-top: 34px;
      margin-left: 34px;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }
  `}

  ${media.tablet`
    margin-top: -24px;
    margin-left: -24px;

    & > * {
      margin-top: 24px;
      margin-left: 24px;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  `}
`

const ReviewTextLeft = styled(ReviewText)`
  ${media.mobile`
    margin-top: 24px;
  `}
`

const Br = styled.br`
  ${media.tablet`
    display: none;
  `}
`

const BlockWrapperReverse = styled(BlockWrapper)`
  ${media.laptop`
    flex-direction: column-reverse;
  `}
`

const PictureWrapper = styled.div`
  ${media.laptop`
    width: 100%;
    max-width: 672px;
  `}
`

const Picture = styled.img`
  right: 0;
  max-width: 950px;
  max-height: 600px;
  object-fit: cover;
  margin-left: -400px;
  width: 950px;
  height: 600px;

  @media (max-width: 1940px) {
    width: calc(100vw / 2);
    height: 100%;
    margin-left: calc(-100vw / 2 + 560px);
  }

  @media (max-width: 1325px) {
    width: calc(50vw - 20px);
    height: 100%;
    margin-left: -100px;
  }

  ${media.laptop`
    width: 100%;
    max-width: 672px;
    max-height: 500px;
    margin: 0;
  `}

  ${media.tablet`
    width: 100%;
    max-height: 400px;
  `}
`

const PictureRight = styled(Picture)`
  margin-left: 0;
  margin-right: -400px;
  left: 0;

  ${media.laptop`
    width: 100%;
    margin: 0;
  `}
`

const Icon = styled.a`
  &:hover {
    cursor: pointer;
    & path {
      fill: var(--color-ground-700);
    }
  }
`

const IconWrap = styled.div`
  ${media.laptop`
    display: flex;
    justify-content: center;
  `}

  ${media.tablet`
    justify-content: flex-start;
  `}
`

export const Reviews: React.FC = () => {
  return (
    <Root>
      <BlockWrapper>
        <PictureWrapper>
          <Picture src="/assets/kacey-photo.webp" alt="Kacey photo" />
        </PictureWrapper>
        <ReviewBlock>
          <ReviewText>
            “After the pandemic began, I spent a long time looking for a solution that would reduce
            the anxiety. I'm glad I finally found it.”
          </ReviewText>
          <IconWrap>
            <icons.Mc />
          </IconWrap>
          <ReviewNameWithPick>Kacey Gillar</ReviewNameWithPick>
          <ReviewInfo>Accounting Assistant at McDonalds</ReviewInfo>
        </ReviewBlock>
      </BlockWrapper>
      <BlockWrapperReverse>
        <ReviewBlock>
          <ReviewTextLeft>
            “The issue of burnout is studied by many scientists every day. It is certainly
            encouraging that we are beginning to think seriously about solving this problem.”
          </ReviewTextLeft>
          <ReviewName>Alexey Markov</ReviewName>
          <ReviewInfo>
            Member of the National Consortium <Br />
            for Psychiatric Genetics
          </ReviewInfo>
        </ReviewBlock>
        <PictureWrapper>
          <PictureRight src="/assets/alexey-photo.webp" alt="Alexey" />
        </PictureWrapper>
      </BlockWrapperReverse>
      <BlockWrapper>
        <PictureWrapper>
          <Picture src="/assets/chrisitana-photo.webp" alt="Chrisitana" />
        </PictureWrapper>
        <ReviewBlock>
          <ReviewText>
            “I am glad that I joined such a unique project. At the interview stage, I did not
            believe in the technology. However, now I am ready to share it with everyone!”
          </ReviewText>
          <IconWrap>
            <Icon href="https://twitter.com/loonakris" target="blank">
              <icons.TwitterGrey />
            </Icon>
          </IconWrap>
          <ReviewNameWithPick>Christiana Luna</ReviewNameWithPick>
          <ReviewInfo>Communications Manager at Breathhh</ReviewInfo>
        </ReviewBlock>
      </BlockWrapper>
    </Root>
  )
}
