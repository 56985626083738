import axios, { type AxiosError } from 'axios'
import { UserStore } from 'src/store/UserStore'
import { apiUrl } from './config'
import { rollBar } from './rollBar'

const prettyAxiosError = (error: AxiosError) => ({
  status: error.response?.status,
  baseURL: error.response?.config.baseURL,
  url: error.response?.config.url,
  headers: error.response?.config.headers,
  method: error.response?.config.method,
  data: error.response?.config.data,
})

export const fetcher = axios.create({
  baseURL: apiUrl,
})

/** Нужен, чтобы ошибка в роллбар успела отправиться */
const delayCallback = (callback) => {
  setTimeout(() => {
    callback()
  }, 1_000)
}

fetcher.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      UserStore.logout()
    } else if (error.response.status === 500) {
      rollBar.critical(prettyAxiosError(error))
      delayCallback(() => {
        window.location.href = `/500`
      })
    } else if (error.response.status === 502) {
      rollBar.critical(prettyAxiosError(error))
      delayCallback(() => {
        window.location.href = `/502`
      })
    } else if (error.response.status === 503) {
      rollBar.critical(prettyAxiosError(error))
      delayCallback(() => {
        window.location.href = `/503`
      })
    }

    return error
  }
)
