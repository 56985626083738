import styled from 'styled-components'

import { media } from 'src/media'
import { OnboardingState, TOnboardingState } from 'src/store/UserStore'

import { ONBOADING_DATA } from '../../PageOnBoading.contants'
import { PlansInfoBlock } from '../PlansInfoBlock'
import { PaymentLoader } from '../PaymentLoader'
import step1Url from '../../assets/step1.webp'

export interface RightContentSlotProps {
  onboardingState: TOnboardingState
  isOverlay: boolean
}

const ImageWrap = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 40px;

  ${media.tablet`
    padding-top: 0;
  `}
`

const ContentImg = styled.img<{ isLastStep: boolean }>`
  max-width: 520px;
  width: 100%;
  background: ${({ isLastStep }) =>
    isLastStep ? `none` : `url('${step1Url}') 0 0 / cover no-repeat;`};
  height: auto;
`

const ContentVideo = styled.video`
  max-width: 520px;
  width: 100%;
`

export const RightContentSlot = ({ onboardingState, isOverlay }: RightContentSlotProps) => {
  const { content } = ONBOADING_DATA[onboardingState]

  if (onboardingState === OnboardingState.INITIAL) {
    return <ContentImg isLastStep={false} src={content} />
  }

  if (onboardingState === OnboardingState.INSTALLED && !isOverlay) {
    return <ContentVideo src={content} autoPlay muted loop playsInline />
  }

  if (onboardingState === OnboardingState.INSTALLED && isOverlay) {
    return <ContentVideo src={content} muted playsInline />
  }

  if (onboardingState === OnboardingState.PRACTICES) {
    return <PlansInfoBlock />
  }

  if (onboardingState === OnboardingState.PAYMENT_PENDING) {
    return <PaymentLoader />
  }

  if (onboardingState === OnboardingState.PAYMENT_COMPLETED) {
    return (
      <ImageWrap>
        <ContentImg isLastStep src={content} />
      </ImageWrap>
    )
  }

  return null
}
