import { OnboardingState, TOnboardingState } from 'src/store/UserStore'

import step1Url from './assets/step1.webp'
import step2Url from './assets/step2.webm'
import step4Url from './assets/vector-stroke.svg'

export const ONBOADING_DATA: {
  [key: string]: {
    title: string
    subtitle?: string
    activeTab: number
    nextStep: TOnboardingState
    action?: string
    content?: string
    buttonText?: string
  }
} = {
  [OnboardingState.INITIAL]: {
    title: `Install Browser Extension`,
    subtitle: `Passed security checkup by Google`,
    buttonText: `Continue`,
    activeTab: 1,
    content: step1Url,
    nextStep: OnboardingState.INSTALLED,
    action: `install`,
  },
  [OnboardingState.INSTALLED]: {
    title: `Mental practices and\u00A0physical exercises`,
    subtitle: `Scientifically proven effectiveness`,
    buttonText: `Try it out`,
    activeTab: 2,
    content: step2Url,
    nextStep: OnboardingState.PRACTICES,
  },
  [OnboardingState.PRACTICES]: {
    title: `Unlock the full potential`,
    activeTab: 3,
    nextStep: OnboardingState.PAYMENT_PENDING,
    action: `practices`,
  },
  [OnboardingState.PAYMENT_PENDING]: {
    title: `Wait a few moments`,
    subtitle: `Account activation and AI engine warming`,
    activeTab: 3,
    nextStep: OnboardingState.PAYMENT_COMPLETED,
  },
  [OnboardingState.PAYMENT_COMPLETED]: {
    title: `Open new tab and\u00A0continue browsing`,
    subtitle: `All set! Expect the practice offerings`,
    activeTab: 4,
    content: step4Url,
    nextStep: null,
    // action: `complete`,
  },
}
