import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { RouteProps } from 'react-router-dom'
import { useState, useRef } from 'react'
import { LayoutBase } from 'src/components/LayoutBase'
import { Welcome } from 'src/components/Welcome/Welcome'
import { Reviews } from 'src/components/Reviews'
import { Benefits } from 'src/components/Benefits'
import { HeroButton } from 'src/components/HeroButton'
import { Overtime } from 'src/components/Overtime'
import { Demo } from 'src/components/Demo'
import { media } from 'src/media'

const Content = styled.div`
  margin: 0;

  ${media.mobile`
    max-width: 320px;
  `}
`

export const PageIndex: React.FC<RouteProps> = () => {
  const [showPopup, setShowPopup] = useState(false)
  const watcherRef = useRef<HTMLIFrameElement>(null)

  const handleShowPopup = () => {
    setShowPopup(!showPopup)
  }

  const userAgents = navigator.userAgent
  let browserName: string | boolean
  let isIOS: boolean

  if (userAgents.match(/Macintosh|iPhone/i) && window.innerWidth <= 1024) {
    isIOS = true
  } else {
    isIOS = false
  }

  if (userAgents.match(/YaBrowser/i)) {
    browserName = `Yandex Browser`
  } else if (userAgents.match(/opr\//i)) {
    browserName = `Opera`
  } else if (userAgents.match(/edg/i)) {
    browserName = `Edge`
  } else if (userAgents.match(/brave/i)) {
    browserName = `Brave`
  } else if (userAgents.match(/colibri/i)) {
    browserName = `Colibri`
  } else if (userAgents.match(/dragon/i)) {
    browserName = `Dragon`
  } else if (userAgents.match(/epic/i)) {
    browserName = `Epic`
  } else if (userAgents.match(/iron/i)) {
    browserName = `Iron`
  } else if (userAgents.match(/chrome/i)) {
    // @ts-ignore
    if (navigator.brave || false) {
      browserName = `Brave`
    } else {
      browserName = `Chrome`
    }
  } else if (userAgents.match(/chromium|crios/i)) {
    browserName = `Browser`
  } else {
    browserName = false
  }

  return (
    <LayoutBase
      showPopup={showPopup}
      handleShowPopup={handleShowPopup}
      browserName={browserName}
      watcherRef={watcherRef}
      isIOS={isIOS}
    >
      <Helmet>
        <title>Breathhh - Workplace Wellbeing Companion</title>
        <link rel="preload" as="image" href="/assets/1step.webp" />
        <link rel="preload" as="image" href="/assets/2step.webp" />
        <link rel="preload" as="image" href="/assets/3step.webp" />
      </Helmet>
      <Content>
        <Welcome
          showPopup={showPopup}
          handleShowPopup={handleShowPopup}
          browserName={browserName}
          isIOS={isIOS}
        />
        <Overtime />
        <Demo />
        <Reviews />
        <Benefits />
        <HeroButton isIOS={isIOS} />
      </Content>
    </LayoutBase>
  )
}
