import styled from 'styled-components'
import { RouteProps } from 'react-router-dom'
import { LayoutCenter } from 'src/components/LayoutCenter'
import { Helmet } from 'react-helmet-async'
import { media } from 'src/media'

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Content = styled.div`
  max-width: 740px;
  margin-top: 90px;
  margin-bottom: 360px;

  ${media.laptop`
    margin-top: 47px;
  `}

  ${media.tablet`
    margin-bottom: 196px;
  `}

  ${media.mobile`
    margin-top: 23px;
    margin-bottom: 132px;
  `}
`

const Title = styled.h1`
  font-family: var(--fonts-second);
  font-size: 62px;
  line-height: 64px;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 18px;

  ${media.laptop`
    font-size: 48px;
    line-height: 50px;
    padding-bottom: 20px;
  `}

  ${media.tablet`
    font-size: 36px;
    line-height: 40px;
    padding-bottom: 0;
  `}

  ${media.mobile`
    font-size: 28px;
    line-height: 36px;
  `}
`

const Text = styled.p`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  margin-top: 24px;
  font-feature-settings: 'liga' off;

  ${media.laptop`
    font-size: 22px;
    line-height: 32px;
    margin-top: 32px;
  `}

  ${media.tablet`
    font-size: 20px;
    line-height: 30px;
  `}
`
const Line = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--color-ground-300);

  ${media.laptop`
    margin-top: 31px;
    margin-bottom: 0;
  `}

  ${media.tablet`
    margin-top: 32px;
  `}
`

const TextBlack = styled.span`
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  border-radius: 6px;
  background-color: var(--color-black);
  color: var(--color-white);

  ${media.laptop`
    font-size: 22px;
    line-height: 32px;
    margin-top: 32px;
  `}

  ${media.tablet`
    font-size: 20px;
    line-height: 30px;
  `}
`

const Star = styled.img`
  width: 265px;
  height: 265px;
  margin-top: 90px;
  animation: rotation 10s infinite linear;

  ${media.laptop`
    display: none;
  `}

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Email = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  background-color: var(--color-ground-100);
  padding: 28px 32px;
  color: inherit;
  margin-top: 64px;
  font-feature-settings: 'liga' off;
  transition: 0.3s;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    background-color: var(--color-ground-300);
  }

  ${media.tablet`
    text-align: center;
    padding: 28px 33px;
  `}

  ${media.mobile`
    font-size: 20px;
    padding: 20px 19px;
  `}
`

const Arrow = styled.img`
  width: 32px;
  height: 32px;

  ${media.tablet`
    display: none;
  `}
`

export const PagePartnership: React.FC<RouteProps> = () => {
  return (
    <LayoutCenter>
      <Helmet>
        <title>Partnership</title>
        <meta name="title" content="Partnership" />
        <meta
          name="description"
          content="Opportunities for startups, businesses, schools, academies, universities, research institutes and non-profit organizations"
        />
        <meta
          name="keywords"
          content="Partnership, Integration, Integrations, AI, Contact, Breathhh, Mental"
        />
        <meta
          name="twitter:title"
          content="MentalTech - Collaborative efforts to achieve great results"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Collaborative efforts to achieve great results" />
        <meta
          property="og:description"
          content="Opportunities for startups, businesses, schools, academies, universities, research institutes and non-profit organizations"
        />
        <meta
          property="og:site_name"
          content="Mental shield to reduce stress and stay mindfulness"
        />
        <meta property="og:image" content="https://breathhh.app/assets/opengraphpartenership.png" />
        <meta property="og:url" content="https://breathhh.app/partnership" />
      </Helmet>
      <Root>
        <Content>
          <Title>Collaborative efforts to achieve great results</Title>
          <Text>
            The mission of our service is to help users&thinsp;
            <TextBlack>&nbsp;take care of themselves&nbsp;</TextBlack>
            &thinsp;regularly and achieve a mental and physical balance.
          </Text>
          <Text>
            The core technology is&thinsp;
            <TextBlack>&nbsp;artificial intelligence&nbsp;</TextBlack>, which can determine the
            &thinsp;user's context, whether it be routine, flow state, burnout or anxiety.
          </Text>
          <Text>
            Opportunities for startups, businesses, schools, academies, universities, research
            institutes and non-profit organisations, as well as research and advertising special
            projects.
          </Text>
          <Line />
          <Text>
            We&thinsp;<TextBlack>&nbsp;do not share or sell user data&nbsp;</TextBlack>
            &thinsp;or support organisations that promote violence, bad habits or fraudulent
            schemes.
          </Text>
          <Email href="mailto:contact@breathhh.app?subject=Partnership">
            contact@breathhh.app
            <Arrow width="32" height="32" src="/assets/arrow-right.svg" alt="arrow" />
          </Email>
        </Content>
        <Star width="265" height="265" src="/assets/star.svg" alt="star" />
      </Root>
    </LayoutCenter>
  )
}
