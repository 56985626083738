import styled, { keyframes, css } from 'styled-components'
import dayjs from 'dayjs'

import { Menu } from 'src/ui/icons'
import { media } from 'src/media'
import type { TPlan } from 'src/store/PlansStore'
import { UserStore } from 'src/store/UserStore'
import { PlansRadioGroup } from 'src/pages/PageOnBoarding/components/PlansRadioGroup'
import { useState } from 'react'

const animate = () => {
  const animation = keyframes`
    0% { transform: translateY(0) }
  25% { transform: translateY(5px) }
  50% { transform: translateY(-5px) }
  75% { transform: translateY(5px) }
  100% { transform: translateY(0) }
  `
  return css`
    ${animation} 0.3s
  `
}

const ActiveCard = styled.div<{ active: boolean }>`
  position: relative;
  z-index: 0;
  padding: 24px;
  border-radius: 3px;
  background-color: var(--color-ground-100);
  animation: ${(props) => (props.active ? animate : `none`)};
  ${media.mobile`
    padding-right: 45px;
  `}
`

const ActiveCardTitle = styled.div`
  font-family: var(--fonts-second);
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: var(--color-black);

  ${media.mobile`
    font-size: 24px;
  `}
`

const ActiveCardSubtitle = styled.div`
  font-size: 16px;
  line-height: 25px;
  color: var(--color-ground-700);
  margin-top: 4px;
`

const IconWrap = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }
`

const Dropdown = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 50px;
  right: 10px;
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

  ${IconWrap}:hover + &, &:hover {
    display: flex;
  }
`
const DropdowndLink = styled.a`
  color: var(--color-black);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background-color: var(--color-ground-100);
  }
`

type TProps = {
  selectedPlanId: string
  currentPlanName?: string
  plans: TPlan[]
  onPlanClick: (id: string) => void
  handleCancel: () => void
  trialToDate?: string
  nextBillDate?: string
}

// TODO: доделать отмену
export const PlansList = (props: TProps) => {
  const { plans, currentPlanName, handleCancel, trialToDate, nextBillDate } = props
  const [isClick, setIsClick] = useState(false)

  const handleOnChange = () => {
    UserStore.sendAction({ kind: `change_plan_click` })
  }

  const onClickCancel = () => {
    handleCancel()
    setIsClick(true)
  }

  return nextBillDate ? (
    <ActiveCard active={UserStore.user.active_subscription.state === `completed` && isClick}>
      <ActiveCardTitle>{currentPlanName} is active</ActiveCardTitle>
      <ActiveCardSubtitle>
        {UserStore.user.active_subscription.state === `completed`
          ? `Expires `
          : UserStore.user.active_subscription.state === `trial`
          ? `Trial version until `
          : `Renewal `}
        {dayjs(nextBillDate).format(`MMMM D, YYYY`)}
      </ActiveCardSubtitle>
      <IconWrap>
        <Menu />
      </IconWrap>
      <Dropdown>
        <DropdowndLink href="mailto:contact@breathhh.app?subject=Payments question">
          Customer support
        </DropdowndLink>
        {UserStore.user.active_subscription.state !== `completed` && (
          <DropdowndLink onClick={onClickCancel}>Cancel subscription</DropdowndLink>
        )}
      </Dropdown>
    </ActiveCard>
  ) : trialToDate ? (
    <ActiveCard active={isClick}>
      <ActiveCardTitle>
        {UserStore.user.analytic_group === `Testers` || UserStore.user.analytic_group === `Business`
          ? `Ultima activated`
          : `Ultima Trial`}
      </ActiveCardTitle>
      <ActiveCardSubtitle>
        {UserStore.user.analytic_group === `Business`
          ? `Business `
          : UserStore.user.analytic_group === `Testers`
          ? `Free Access`
          : `Active until ${dayjs(trialToDate).format(`MMMM D, YYYY`)}`}
      </ActiveCardSubtitle>
      <IconWrap>
        <Menu />
      </IconWrap>
      <Dropdown>
        <DropdowndLink href="mailto:contact@breathhh.app?subject=Payments question">
          Customer support
        </DropdowndLink>
      </Dropdown>
    </ActiveCard>
  ) : (
    <PlansRadioGroup plans={plans} onChange={handleOnChange} />
  )
}
