import { useEffect } from 'react'
import { TransactionsStore } from 'src/store/TransactionsStore'

export function useTransactionsStore() {
  useEffect(() => {
    if (TransactionsStore.transactions.length === 0 && !TransactionsStore.loading) {
      TransactionsStore.fetch()
    }
  }, [])

  return { transactions: TransactionsStore.transactions }
}
