import dayjs from 'dayjs'
import { useState } from 'react'
import { saveAs } from 'file-saver'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet-async'
import { RouteProps } from 'react-router-dom'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import PuffLoader from 'react-spinners/PuffLoader'

import { media } from 'src/media'
import { Menu } from 'src/ui/icons'
import { LoaderTop } from 'src/ui/atoms'
import { LineChart } from 'src/components/LineChart'
import { LayoutBase } from 'src/components/LayoutBase'
import { RadarChart } from 'src/components/RadarChart'
import { useMoodRates, useEngagements, UserStore } from 'src/store/UserStore'

const Wrapper = styled.div`
  margin-left: 115px;
  margin-top: 34px;
  margin-bottom: 300px;

  ${media.laptop`
    margin: 146px auto 232px;
  `}

  ${media.tablet`
    position: relative;
    width: 80vw;
    min-height: 978px;
    margin: 132px auto 196px;
    overflow: hidden;
  `}

  ${media.mobile`
    margin-bottom: 160px;
  `}
`
const ScrollWrapper = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }

  ${media.tablet`
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: auto;
    overflow-y: hidden;
  `}
`

const Title = styled.h2`
  font-weight: 500;
  font-size: 34px;
  font-family: var(--fonts);
  line-height: 38px;
  text-transform: none;
  letter-spacing: normal;
  margin: 4px 0 0;
  padding-bottom: 18px;

  ${media.tablet`
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 8px;
  `}
`

const WrapGraph = styled.div`
  width: 740px;
  height: 335px;
  margin-bottom: 16px;
  background-color: var(--color-ground-100);
  position: relative;

  ${media.laptop`
    width: 672px;
  `}

  ${media.tablet`
    height: 320px;
  `}
`

const WrapGraphEng = styled(WrapGraph)`
  height: 400px;
`

const WrapGraphEngTest = styled(WrapGraph)`
  height: 360px;
`

const WrapGraphHeader = styled(WrapGraph)`
  height: 135px;
  margin-bottom: 0;

  ${media.tablet`
    height: 200px;
  `}
`

const WrapGraphHeaderEng = styled(WrapGraph)`
  height: 170px;
  margin-bottom: 0;

  ${media.tablet`
    height: 200px;
  `}
`

const HeaderGraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2px 0 28px;
  border-bottom: 1px solid var(--color-ground-300);

  ${media.tablet`
    flex-direction: column;
    align-items: flex-start;
  `}
`

const HeaderTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  ${media.tablet`
    font-size: 20px;
    line-height: 28px;
    margin-top: 24px;
  `}
`

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 28px;

  ${media.tablet`
    padding-left: 0
  `}
`

const MenuItem = styled.div<{ color: string; border: string; padding: string }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  margin-right: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  border-bottom: ${(props) => props.border};

  &:last-child {
    margin-right: 0;
  }

  & svg path {
    fill: ${(props) => props.color};
  }

  &:hover {
    cursor: pointer;
    color: var(--color-black);
    & svg path {
      fill: var(--color-black);
    }
  }
`

const Icon = styled.svg`
  margin-right: 8px;
`

const SelectMonth = styled.div`
  display: flex;
  margin: 24.5px 0 0 28px;
  align-items: center;
`

const IconSelectWrap = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-ground-200);
  margin-right: 6px;
  border: none;
  outline: none;
  z-index: 5;
  padding: 0;

  &:hover {
    cursor: pointer;
    background-color: var(--color-ground-300);
  }

  &:disabled {
    cursor: not-allowed;

    &:hover {
      background-color: var(--color-ground-200);
    }
  }
`

const IconSelectMonth = styled.svg<{ color: string }>`
  & path {
    fill: ${(props) => props.color};
  }
`

const MonthText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 2px;
`

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 670px;
  height: 290px;
  padding: 0 35px;
  z-index: 0;

  ${media.laptop`
    width: 602px;
  `}
`

const ChartWrapperRadar = styled(ChartWrapper)`
  display: block;
  width: 450px;
  height: 390px;
  margin: -50px auto 0;
  padding: 0;

  ${media.tablet`
    margin-top: -35px;
  `}
`

const LabelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 740px;
  padding: 35px 32px 29px;
  position: absolute;
  bottom: 0;

  ${media.laptop`
    width: 672px;
  `}
`

const Label = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-ground-700);
`

const WebTimeWrapper = styled.div`
  display: flex;
  padding: 35px 32px 29px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-ground-700);
`

const WebTimeText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-black);
`

const IconWrap = styled.div`
  position: absolute;
  top: 28px;
  right: 18px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }

  ${media.tablet`
    position: inherit;
    margin-top: 10px;
  `}
`

const Dropdown = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 55px;
  right: 10px;
  color: var(--color-black);
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16);

  ${IconWrap}:hover + &, &:hover {
    display: flex;
  }

  ${media.tablet`
    position: relative;
      top: 35px;
      left: -110px;
  `}
`

const DropdowndLink = styled.a`
  color: var(--color-black);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 10px;
  &:hover {
    cursor: pointer;
    background-color: var(--color-ground-100);
  }
`

const EmptyChart = styled.div`
  text-align: center;
  padding-top: 123px;
  color: var(--color-ground-700);
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`

const SpinnerWrapper = styled.div`
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`

const SpinnerWrapperEng = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 395px;
`

export const PageDashboard: React.FC<RouteProps> = observer(() => {
  const [isChartMood, setIsChartMood] = useState(true)
  const [isInsightMood, setIsInsightMood] = useState(false)
  const [currentDateMood, setCurrentDateMood] = useState(dayjs().format())
  const [currentDateEngagement, setCurrentDateEngagement] = useState(dayjs().format())
  const [isChartEngagement, setIsChartEngagement] = useState(true)
  const [isInsightEngagement, setIsInsightEngagement] = useState(false)
  const lastMonth = dayjs().format(`MMMM YYYY`)

  const title = (mood) => {
    const resMood =
      mood === 1
        ? `Terrible`
        : mood === 2
        ? `Unamused`
        : mood === 3
        ? `Neutral`
        : mood === 4
        ? `Good`
        : mood === 5 && `Great`

    return resMood
  }
  const from = (currentDate) => {
    return `${dayjs(currentDate).format(`YYYY-MM-01`)}`
  }
  const days = (currentDate) => {
    return dayjs(currentDate).daysInMonth()
  }
  const to = (currentDate) => {
    return `${dayjs(currentDate).format(`YYYY-MM-${days(currentDate)}`)}`
  }
  const month = (currentDate) => {
    return dayjs(currentDate).format(`MMM`).toLowerCase()
  }
  const labels = [`1 `, `5 `, `10 `, `15 `, `20 `, `25 `, `${days(currentDateMood)} `].map(
    (day) => {
      return day + month(currentDateMood)
    }
  )

  dayjs.extend(customParseFormat)

  const { moodRates, moodIsLoaded } = useMoodRates(
    from(currentDateMood).toString(),
    to(currentDateMood).toString()
  )
  const { engagements, engagementsIsLoaded } = useEngagements(
    from(currentDateEngagement).toString(),
    to(currentDateEngagement)
  )

  const takeMoodData = async () => {
    UserStore.sendAction({
      kind: `dashboard_mood_csv_export_click`,
    })
    const moodReport = await UserStore.downloadMoodReport(
      from(currentDateMood).toString(),
      to(currentDateMood).toString()
    )
    const blob = new File([moodReport], `reportMood.csv`, {
      type: `text/csv`,
    })
    saveAs(blob)
  }

  const takeEngagementData = async () => {
    UserStore.sendAction({
      kind: `dashboard_engagement_csv_export_click`,
    })
    const engagementsReport = await UserStore.downloadEngagementsReport(
      from(currentDateEngagement).toString(),
      to(currentDateEngagement).toString()
    )

    const blob = new File([engagementsReport], `reportEngagements.csv`, {
      type: `text/csv`,
    })
    saveAs(blob)
  }

  const handleChartMood = () => {
    setIsChartMood(true)
    setIsInsightMood(false)
    UserStore.sendAction({
      kind: `dashboard_mood_chart_click`,
    })
  }

  const handleInsightMood = () => {
    setIsChartMood(false)
    setIsInsightMood(true)
    UserStore.sendAction({
      kind: `dashboard_mood_insights_click`,
    })
  }

  const handleChartEngagement = () => {
    setIsChartEngagement(true)
    setIsInsightEngagement(false)
    UserStore.sendAction({
      kind: `dashboard_engagement_chart_click`,
    })
  }

  const handleInsightsEngagement = () => {
    setIsChartEngagement(false)
    setIsInsightEngagement(true)
    UserStore.sendAction({
      kind: `dashboard_engagement_insights_click`,
    })
  }

  const prevMonthMood = () => {
    const prevMonthNum = Number(dayjs(currentDateMood).format(`MM`)) - 1
    const year = Number(dayjs(currentDateMood).format(`YYYY`))
    setCurrentDateMood(dayjs(`${year}-${prevMonthNum}`).format())
  }

  const prevMonthEngagement = () => {
    const prevMonthNum = Number(dayjs(currentDateEngagement).format(`MM`)) - 1
    const year = Number(dayjs(currentDateEngagement).format(`YYYY`))
    setCurrentDateEngagement(dayjs(`${year}-${prevMonthNum}`).format())
  }

  const nextMonthMood = () => {
    const prevMonthNum = Number(dayjs(currentDateMood).format(`MM`)) + 1
    const year = Number(dayjs(currentDateMood).format(`YYYY`))
    setCurrentDateMood(dayjs(`${year}-${prevMonthNum}`).format())
  }

  const nextMonthEngagement = () => {
    const prevMonthNum = Number(dayjs(currentDateEngagement).format(`MM`)) + 1
    const year = Number(dayjs(currentDateEngagement).format(`YYYY`))
    setCurrentDateEngagement(dayjs(`${year}-${prevMonthNum}`).format())
  }

  return (
    <LayoutBase enableNav showSticky={false}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <LoaderTop />
      <Wrapper>
        <Title>Dashboard</Title>
        <WrapGraphHeader>
          <HeaderGraph>
            <HeaderTitle>Mood</HeaderTitle>
            <HeaderMenu>
              <MenuItem
                color={isChartMood ? `var(--color-black)` : `var(--color-ground-800)`}
                border={isChartMood ? `1px solid var(--color-black)` : `none`}
                padding={isChartMood ? `29px 0 28px` : `29px 0`}
                onClick={handleChartMood}
              >
                <Icon
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 2.16667C0.5 1.24619 1.24619 0.5 2.16667 0.5H13.8333C14.7538 0.5 15.5 1.24619 15.5 2.16667V13.8333C15.5 14.7538 14.7538 15.5 13.8333 15.5H2.16667C1.24619 15.5 0.5 14.7538 0.5 13.8333V2.16667ZM13.8333 2.16667H2.16667V13.8333H13.8333V2.16667ZM9.04167 11.75V4.25H10.7083V11.75H9.04167ZM5.29167 11.75V7.16667H6.95833V11.75H5.29167Z"
                    fill="black"
                  />
                </Icon>
                Chart
              </MenuItem>
              <MenuItem
                color={isInsightMood ? `var(--color-black)` : `var(--color-ground-800)`}
                border={isInsightMood ? `1px solid var(--color-black)` : `none`}
                padding={isInsightMood ? `29px 0 28px` : `29px 0`}
                onClick={handleInsightMood}
              >
                <Icon
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.83317 0.666748V3.44453H8.1665V0.666748H9.83317ZM15.4817 3.69678L13.5175 5.66097L12.339 4.48246L14.3031 2.51827L15.4817 3.69678ZM4.48284 5.66139L2.51865 3.6972L3.69717 2.51869L5.66135 4.48287L4.48284 5.66139ZM8.99984 6.54468C7.64384 6.54468 6.54458 7.64393 6.54458 8.99994C6.54458 10.3559 7.64384 11.4552 8.99984 11.4552C10.3558 11.4552 11.4551 10.3559 11.4551 8.99994C11.4551 7.64393 10.3558 6.54468 8.99984 6.54468ZM4.87791 8.99994C4.87791 6.72346 6.72336 4.87801 8.99984 4.87801C11.2763 4.87801 13.1218 6.72346 13.1218 8.99994C13.1218 11.2764 11.2763 13.1219 8.99984 13.1219C6.72336 13.1219 4.87791 11.2764 4.87791 8.99994ZM3.44428 9.83342H0.666504V8.16675H3.44428V9.83342ZM17.3332 9.83342H14.5554V8.16675H17.3332V9.83342ZM14.3027 15.4813L12.3385 13.5171L13.517 12.3386L15.4812 14.3028L14.3027 15.4813ZM5.66072 13.5177L3.69654 15.4819L2.51802 14.3034L4.48221 12.3392L5.66072 13.5177ZM9.83317 14.5556V17.3334H8.1665V14.5556H9.83317Z"
                    fill="#3E3E3E"
                  />
                </Icon>
                Insights
              </MenuItem>
              <IconWrap>
                <Menu />
              </IconWrap>
              <Dropdown>
                <DropdowndLink onClick={takeMoodData}>Export as .CSV</DropdowndLink>
              </Dropdown>
            </HeaderMenu>
          </HeaderGraph>
          {isChartMood ? (
            <SelectMonth>
              <IconSelectWrap onClick={prevMonthMood}>
                <IconSelectMonth
                  color="var(--color-black)"
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.70373 0.782227L4.87505 1.71928L2.24982 5.00081L4.87505 8.28234L3.70373 9.2194L0.328857 5.00081L3.70373 0.782227Z"
                    fill="black"
                  />
                </IconSelectMonth>
              </IconSelectWrap>
              {dayjs(currentDateMood).format(`MMMM YYYY`) === lastMonth ? (
                <IconSelectWrap disabled>
                  <IconSelectMonth
                    color={
                      dayjs(currentDateMood).format(`MMMM YYYY`) === lastMonth
                        ? `var(--color-ground-700)`
                        : `var(--color-black)`
                    }
                    width="5"
                    height="10"
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.29606 9.21875L0.124756 8.28171L2.74993 5.00024L0.124756 1.71876L1.29606 0.781722L4.67087 5.00024L1.29606 9.21875Z"
                      fill="black"
                    />
                  </IconSelectMonth>
                </IconSelectWrap>
              ) : (
                <IconSelectWrap onClick={nextMonthMood}>
                  <IconSelectMonth
                    color={
                      dayjs(currentDateMood).format(`MMMM YYYY`) === lastMonth
                        ? `var(--color-ground-700)`
                        : `var(--color-black)`
                    }
                    width="5"
                    height="10"
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.29606 9.21875L0.124756 8.28171L2.74993 5.00024L0.124756 1.71876L1.29606 0.781722L4.67087 5.00024L1.29606 9.21875Z"
                      fill="black"
                    />
                  </IconSelectMonth>
                </IconSelectWrap>
              )}
              <MonthText>{dayjs(currentDateMood).format(`MMMM YYYY`)}</MonthText>
            </SelectMonth>
          ) : (
            <WebTimeWrapper>
              Your average mood for {dayjs(currentDateMood).format(`MMMM`)}:&nbsp;
              {moodRates && <WebTimeText>{title(moodRates.average_mood)}</WebTimeText>}
            </WebTimeWrapper>
          )}
        </WrapGraphHeader>
        <ScrollWrapper>
          <WrapGraph>
            {isChartMood && moodIsLoaded ? (
              <>
                {moodRates &&
                  (UserStore.user.has_moods ? (
                    <ChartWrapper>
                      <LineChart days={days(currentDateMood)} dataTest={moodRates.moods} />
                    </ChartWrapper>
                  ) : (
                    <EmptyChart>
                      So far, it's empty. <br /> Pick your first mood in the browser extension.
                    </EmptyChart>
                  ))}
                {UserStore.user.has_moods && (
                  <LabelsWrapper>
                    {labels.map((day) => (
                      <Label key={day}>{day}</Label>
                    ))}
                  </LabelsWrapper>
                )}
              </>
            ) : (
              isChartMood && (
                <SpinnerWrapper>
                  <PuffLoader size={150} />
                </SpinnerWrapper>
              )
            )}
          </WrapGraph>
        </ScrollWrapper>
        <WrapGraphHeaderEng>
          <HeaderGraph>
            <HeaderTitle>Engagement</HeaderTitle>
            <HeaderMenu>
              <MenuItem
                color={isChartEngagement ? `var(--color-black)` : `var(--color-ground-800)`}
                border={isChartEngagement ? `1px solid var(--color-black)` : `none`}
                padding={isChartEngagement ? `29px 0 28px` : `29px 0`}
                onClick={handleChartEngagement}
              >
                <Icon
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.5 2.16667C0.5 1.24619 1.24619 0.5 2.16667 0.5H13.8333C14.7538 0.5 15.5 1.24619 15.5 2.16667V13.8333C15.5 14.7538 14.7538 15.5 13.8333 15.5H2.16667C1.24619 15.5 0.5 14.7538 0.5 13.8333V2.16667ZM13.8333 2.16667H2.16667V13.8333H13.8333V2.16667ZM9.04167 11.75V4.25H10.7083V11.75H9.04167ZM5.29167 11.75V7.16667H6.95833V11.75H5.29167Z"
                    fill="black"
                  />
                </Icon>
                Chart
              </MenuItem>
              <MenuItem
                color={isInsightEngagement ? `var(--color-black)` : `var(--color-ground-800)`}
                border={isInsightEngagement ? `1px solid var(--color-black)` : `none`}
                padding={isInsightEngagement ? `29px 0 28px` : `29px 0`}
                onClick={handleInsightsEngagement}
              >
                <Icon
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.83317 0.666748V3.44453H8.1665V0.666748H9.83317ZM15.4817 3.69678L13.5175 5.66097L12.339 4.48246L14.3031 2.51827L15.4817 3.69678ZM4.48284 5.66139L2.51865 3.6972L3.69717 2.51869L5.66135 4.48287L4.48284 5.66139ZM8.99984 6.54468C7.64384 6.54468 6.54458 7.64393 6.54458 8.99994C6.54458 10.3559 7.64384 11.4552 8.99984 11.4552C10.3558 11.4552 11.4551 10.3559 11.4551 8.99994C11.4551 7.64393 10.3558 6.54468 8.99984 6.54468ZM4.87791 8.99994C4.87791 6.72346 6.72336 4.87801 8.99984 4.87801C11.2763 4.87801 13.1218 6.72346 13.1218 8.99994C13.1218 11.2764 11.2763 13.1219 8.99984 13.1219C6.72336 13.1219 4.87791 11.2764 4.87791 8.99994ZM3.44428 9.83342H0.666504V8.16675H3.44428V9.83342ZM17.3332 9.83342H14.5554V8.16675H17.3332V9.83342ZM14.3027 15.4813L12.3385 13.5171L13.517 12.3386L15.4812 14.3028L14.3027 15.4813ZM5.66072 13.5177L3.69654 15.4819L2.51802 14.3034L4.48221 12.3392L5.66072 13.5177ZM9.83317 14.5556V17.3334H8.1665V14.5556H9.83317Z"
                    fill="#3E3E3E"
                  />
                </Icon>
                Insights
              </MenuItem>
              <IconWrap>
                <Menu />
              </IconWrap>
              <Dropdown>
                <DropdowndLink onClick={takeEngagementData}>Export as .CSV</DropdowndLink>
              </Dropdown>
            </HeaderMenu>
          </HeaderGraph>
          {isChartEngagement ? (
            <SelectMonth>
              <IconSelectWrap onClick={prevMonthEngagement}>
                <IconSelectMonth
                  color="var(--color-black)"
                  width="5"
                  height="10"
                  viewBox="0 0 5 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.70373 0.782227L4.87505 1.71928L2.24982 5.00081L4.87505 8.28234L3.70373 9.2194L0.328857 5.00081L3.70373 0.782227Z"
                    fill="black"
                  />
                </IconSelectMonth>
              </IconSelectWrap>
              {dayjs(currentDateEngagement).format(`MMMM YYYY`) === lastMonth ? (
                <IconSelectWrap disabled>
                  <IconSelectMonth
                    color={
                      dayjs(currentDateEngagement).format(`MMMM YYYY`) === lastMonth
                        ? `var(--color-ground-700)`
                        : `var(--color-black)`
                    }
                    width="5"
                    height="10"
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.29606 9.21875L0.124756 8.28171L2.74993 5.00024L0.124756 1.71876L1.29606 0.781722L4.67087 5.00024L1.29606 9.21875Z"
                      fill="black"
                    />
                  </IconSelectMonth>
                </IconSelectWrap>
              ) : (
                <IconSelectWrap onClick={nextMonthEngagement}>
                  <IconSelectMonth
                    color={
                      dayjs(currentDateEngagement).format(`MMMM YYYY`) === lastMonth
                        ? `var(--color-ground-700)`
                        : `var(--color-black)`
                    }
                    width="5"
                    height="10"
                    viewBox="0 0 5 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.29606 9.21875L0.124756 8.28171L2.74993 5.00024L0.124756 1.71876L1.29606 0.781722L4.67087 5.00024L1.29606 9.21875Z"
                      fill="black"
                    />
                  </IconSelectMonth>
                </IconSelectWrap>
              )}
              <MonthText>{dayjs(currentDateEngagement).format(`MMMM YYYY`)}</MonthText>
            </SelectMonth>
          ) : (
            <WebTimeWrapper>
              Average Daily Web Time:&nbsp;
              {Math.floor((engagements.average_browser_time % (3600 * 24)) / 3600) > 0 &&
                engagements && (
                  <WebTimeText>
                    {Math.floor((engagements.average_browser_time % (3600 * 24)) / 3600)}
                    &nbsp;h&nbsp;
                  </WebTimeText>
                )}
              {engagements && (
                <WebTimeText>
                  {Math.floor((engagements.average_browser_time % 3600) / 60)}&nbsp;min
                </WebTimeText>
              )}
            </WebTimeWrapper>
          )}
        </WrapGraphHeaderEng>
        {isChartEngagement ? (
          <ScrollWrapper>
            <WrapGraphEng>
              {engagementsIsLoaded ? (
                <ChartWrapperRadar>
                  {/* @ts-ignore */}
                  <RadarChart
                    days={days(currentDateEngagement)}
                    // @ts-ignore
                    dataTest={engagements.engagements}
                  />
                </ChartWrapperRadar>
              ) : (
                isChartEngagement && (
                  <ChartWrapperRadar>
                    <SpinnerWrapperEng>
                      <PuffLoader size={150} />
                    </SpinnerWrapperEng>
                  </ChartWrapperRadar>
                )
              )}
            </WrapGraphEng>
          </ScrollWrapper>
        ) : (
          <WrapGraphEngTest />
        )}
      </Wrapper>
    </LayoutBase>
  )
})
