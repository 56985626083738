import { Helmet } from 'react-helmet-async'

export const PageInterview: React.FC = () => {
  if (Math.floor(Math.random() * 100) % 2 === 0) {
    window.location.replace(`https://calendly.com/shirshov/interview-with-breathhh`)
  } else {
    window.location.replace(`https://calendly.com/shapalwork/interview-with-breathhh-1`)
  }
  return (
    <Helmet>
      <title>Interview Randomizer</title>
    </Helmet>
  )
}
