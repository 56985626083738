import styled from 'styled-components'

import { TPlan } from 'src/store/PlansStore'
import { UserPlanRadio } from 'src/components/UserPlanRadio'

export type PlanRadioGroup = TPlan & { checked?: boolean; onChange?: () => void }

export interface PlansRadioGroupProps {
  plans?: PlanRadioGroup[]
  onChange: () => void
}

const ListContainer = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const PlanRadioWrap = styled.li`
  margin-top: 4px;

  &:first-child {
    margin-top: 0;
  }
`

export const PlansRadioGroup = ({ plans, onChange }: PlansRadioGroupProps) => {
  return (
    <ListContainer>
      {Boolean(plans) &&
        plans.map((data) => {
          const handleOnchange = () => {
            data.onChange()
            onChange()
          }

          return (
            <PlanRadioWrap key={data.external_id}>
              <UserPlanRadio
                groupName="plans"
                planName={data.name}
                title={data.title}
                annualPrice={data.yearly_price}
                badgeInfo={data.badge_info}
                isFreeTrialAlllowed={data.trial_days > 0}
                onChange={handleOnchange}
                checked={data.checked}
              />
            </PlanRadioWrap>
          )
        })}
    </ListContainer>
  )
}
