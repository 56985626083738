import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { UserStore, OnboardingState, type TOnboardingState } from 'src/store/UserStore'
import { useExtension, E_EXT_STATE } from 'src/utils/useExtension'
import { sendMessageToExt } from 'src/utils/config'
import { Breath } from 'src/components/Breath'
import { Header } from 'src/components/Header'
import { Show } from 'src/components/Show'
import { Morda } from 'src/components/Morda'
import { media } from 'src/media'
import { SnackbarElement } from 'src/components/Snackbar'
import { PageOnboardingHead } from './components/Head'
import { ONBOADING_DATA } from './PageOnBoading.contants'
import { LeftContentSlot } from './components/LeftContentSlot'
import { RightContentSlot } from './components/RightContentSlot'

const NOTICE_TIMEOUT_DELAY_TO_SHOW = 1000
const NOTICE_TIMEOUT_DELAY_TO_HIDE = 20000

const Container = styled.div`
  display: flex;
  min-height: 100vh;

  ${media.laptopX`
    margin-top: calc(90px + 96px);
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.tablet`
    margin-top: calc(90px + 46px);
    min-height: auto;
  `}
`

const PartLeft = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;

  ${media.laptopX`
    padding: 20px;
  `}
`

const TextBlock = styled.div`
  margin: auto;
  max-width: 500px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  ${media.laptopX`
    max-width: 100%;
    min-height: auto;
  `}
`

const PartRight = styled.div<{ white: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ white }) => (white ? `var(--color-white)` : `var(--color-ground-100)`)};
  box-sizing: border-box;

  ${media.laptopX`
    height: 100%;
    min-height: 200px;
  `}
`
const SlotWrap = styled.div`
  display: flex;
  margin: -2px;

  & > * {
    margin: 2px;
  }
`

const Slot = styled.div<{ active: boolean }>`
  width: 71px;
  height: 6px;
  background-color: ${({ active }) =>
    active ? `var(--color-ground-900)` : `var(--color-ground-300)`};
`

const Overlay = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  background: #000000;
  opacity: 0.85;
`

const SnackbarWrap = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 1120px;
  padding: 0 16px 48px 16px;
  transform: translateX(-50%);
  z-index: 1;

  ${media.tablet`
    padding: 0 16px 26px 16px;
  `}
`

export const PageOnBoarding: React.FC<RouteProps> = observer(() => {
  const [extensionState] = useExtension()
  const [currentStep, setCurrentStep] = useState<TOnboardingState | null>(null)
  const [isOverlay, setIsOverlay] = useState(false)
  const [isStartPractice, setIsStartPractice] = useState(false)
  const [snackbarData, setSnackbarData] = useState<null | { text: string; emoji: string }>(null)
  const [isNoticeVisible, setIsNoticeVisible] = useState(false)

  let type = currentStep || UserStore.currentOnboarding
  const isExtNotInstalled = extensionState === E_EXT_STATE.NOT_INSTALLED
  const skipPaywall = UserStore.user.subscription_state !== `subscription_not_active`
  const { isOnActiveSubscription } = UserStore

  // NOTE: handle case when extension already installed
  // but our backend dont know about that at current moment
  if (!isExtNotInstalled && UserStore.currentOnboarding === `onboarding_initial` && !currentStep) {
    type = OnboardingState.INSTALLED
  } else if (isExtNotInstalled) {
    type = OnboardingState.INITIAL
  }

  const currentStepSet = async (step: TOnboardingState) => {
    const nextStep =
      skipPaywall && step === OnboardingState.PRACTICES ? OnboardingState.PAYMENT_COMPLETED : step

    setCurrentStep(nextStep)
    UserStore.setOnboaringState(nextStep)

    const { action } = ONBOADING_DATA[nextStep]

    if (skipPaywall && step === OnboardingState.PRACTICES) {
      await UserStore.completeOnboarding(`practices`)
    }

    if (action) {
      UserStore.completeOnboarding(action)
    }
  }

  useEffect(() => {
    if (isOnActiveSubscription && type !== OnboardingState.PAYMENT_COMPLETED) {
      currentStepSet(OnboardingState.PAYMENT_COMPLETED)
    }
  }, [isOnActiveSubscription, type])

  useEffect(() => {
    if (type === OnboardingState.PAYMENT_COMPLETED && !snackbarData) {
      const complete = async () => {
        sendMessageToExt(`webOnboardingCompleted`)
        const data = await UserStore.completeOnboarding(`complete`)
        setSnackbarData(data)
      }

      complete()
    }
  }, [type, snackbarData])

  useEffect(() => {
    let timeoutToShow
    let timeoutToHide

    if (snackbarData) {
      timeoutToShow = setTimeout(() => setIsNoticeVisible(true), NOTICE_TIMEOUT_DELAY_TO_SHOW)
      timeoutToHide = setTimeout(async () => {
        setIsNoticeVisible(false)
      }, NOTICE_TIMEOUT_DELAY_TO_SHOW + NOTICE_TIMEOUT_DELAY_TO_HIDE)
    }

    return () => {
      if (timeoutToShow) {
        clearTimeout(timeoutToShow)
      }

      if (timeoutToHide) {
        clearTimeout(timeoutToHide)
      }
    }
  }, [snackbarData])

  if (extensionState === E_EXT_STATE.UNKNOWN) {
    // Детектим, установлен ли экстеншен
    return null
  }

  const { activeTab } = ONBOADING_DATA[type]

  // only uniq
  const stepTabs = Object.values(ONBOADING_DATA).reduce<number[]>((acc, item) => {
    if (!acc.some((el) => el === item.activeTab)) {
      acc.push(item.activeTab)
    }

    return acc
  }, [])

  const overlayCallback = () => {
    currentStepSet(OnboardingState.PRACTICES)
  }

  const startPractice = () => {
    setIsStartPractice(true)
  }

  const runNext = (step: TOnboardingState) => {
    // capture setting step and show overlay
    if (step === OnboardingState.PRACTICES && !isStartPractice && window.innerWidth > 1024) {
      setIsOverlay(true)
    } else {
      currentStepSet(step)
    }
  }

  const setErrorToSnackbar = () => {
    setSnackbarData({
      text: `Something went wrong`,
      emoji: `⛔️`,
    })
  }

  return (
    <>
      <PageOnboardingHead />
      <Header positionAbsolute transparent />
      {isStartPractice && ONBOADING_DATA[type].activeTab === 2 ? (
        <Breath nextStep={overlayCallback} time={20_000} />
      ) : (
        <Container>
          <SnackbarWrap>
            <AnimatePresence>
              {isNoticeVisible && Boolean(snackbarData) && (
                <motion.div
                  key="notice"
                  initial={{ opacity: 0, translateY: 30 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  exit={{ opacity: 0, translateY: 30 }}
                  transition={{
                    duration: `0.3`,
                  }}
                >
                  <SnackbarElement text={snackbarData.text} emoji={snackbarData.emoji} />
                </motion.div>
              )}
            </AnimatePresence>
          </SnackbarWrap>
          <PartLeft>
            <TextBlock>
              <SlotWrap>
                {stepTabs.map((tab) => {
                  return <Slot key={tab} active={activeTab === tab} />
                })}
              </SlotWrap>
              <LeftContentSlot
                startAnimate={!isOverlay || isStartPractice}
                activeTab={activeTab}
                onboardingState={type}
                isExtNotInstalled={isExtNotInstalled}
                setNextStep={runNext}
                onError={setErrorToSnackbar}
              />
            </TextBlock>
          </PartLeft>
          <PartRight white={[3, 4].includes(activeTab)}>
            <RightContentSlot onboardingState={type} isOverlay={isOverlay} />
          </PartRight>
          {isOverlay && !isStartPractice && (
            <Overlay>
              <Show>
                <Morda startPractice={startPractice} />
              </Show>
            </Overlay>
          )}
        </Container>
      )}
    </>
  )
})
