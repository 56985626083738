import { Helmet } from 'react-helmet-async'

export const PageOnboardingHead = () => {
  return (
    <Helmet>
      <title>Breathhh</title>
      <meta name="title" content="Product Demo" />
      <meta name="description" content="Introduction tour explaining how to use the product" />
      <meta
        name="keywords"
        content="Demo, Onboarding, Tour, Setup, Mental, Wellness, Extension, Browser, AI, artificial, Intelligence"
      />
      <meta name="twitter:title" content="A mental shield that protects against stress" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Product Demo" />
      <meta
        property="og:description"
        content="Introduction tour explaining how to use the product"
      />
      <meta property="og:site_name" content="Mental shield to reduce stress and stay mindfulness" />
      <meta property="og:url" content="https://breathhh.app/onboarding" />
      <script src="https://cdn.paddle.com/paddle/paddle.js" />
    </Helmet>
  )
}
