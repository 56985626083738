import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledSubTitle = styled.p`
  margin: 12px 0 0;
  font-size: 18px;
  line-height: 26px;
  color: var(--color-black);
`

export const ContentSubtitle = ({ children }: { children: ReactNode }) => {
  return <StyledSubTitle>{children}</StyledSubTitle>
}
