import { useEffect } from 'react'
import { UserStore } from 'src/store/UserStore'

export function useMoodRates(from: string, to: string) {
  useEffect(() => {
    ;(async () => {
      await UserStore.fetchMoodRates(from, to)
    })()
  }, [from, to])
  return { moodRates: UserStore.moodRates, moodIsLoaded: UserStore.moodIsLoaded }
}
