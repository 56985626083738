/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, RouteProps } from 'react-router-dom'

import { UserStore } from 'src/store/UserStore'

export const withLoginIfNotLoggedIn = (Component: any) => (props: RouteProps) => {
  return UserStore.user ? <Component {...props} /> : <Navigate to="/login" />
}

export const withOnboardingRedirectIfLoggedIn = (Component: any) => (props: RouteProps) => {
  return UserStore.user ? <Navigate to="/onboarding?from=website" /> : <Component {...props} />
}

export const withAccountRedirectIfLoggedIn = (Component: any) => (props: RouteProps) => {
  return UserStore.user ? <Navigate to="/dashboard" /> : <Component {...props} />
}

export const withRedirectToOnBoardingIfOnBoardingNotComplete =
  (Component: any) => (props: RouteProps) =>
    UserStore.onboardingIsCompleted ? (
      <Component {...props} />
    ) : (
      <Navigate to="/onboarding?from=website" />
    )
export const withRedirectToDashboardIfOnBoardingComplete =
  (Component: any) => (props: RouteProps) =>
    UserStore.onboardingIsCompleted ? <Navigate to="/dashboard" /> : <Component {...props} />
