import styled from 'styled-components'
import { RouteProps, Link } from 'react-router-dom'

import { media } from 'src/media'
import { LayoutBaseWithoutFooter } from 'src/components/LayoutBaseWithoutFooter'
import { apiUrlFacebook, apiUrlGoogle, apiUrlApple } from 'src/utils/config'
import { Button, Spacer } from 'src/ui/atoms'
import { Referrer } from 'src/utils/localStore'

const Root = styled.div`
  height: calc(100vh - 89px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.mobile`
  height: calc(100vh - 73px);
  `}
`

const Video = styled.video`
  width: 320px;

  ${media.tablet`
    max-width: 296px;
    width: 100%;
  `}
`

const Subtitle = styled.div`
  display: inline-block;
  max-width: 320px;
  color: var(--color-ground-700);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  font-weight: 500;
`

const WrapButtons = styled.div`
  display: grid;
  gap: 12px;
  width: 320px;
  font-family: inherit;
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;

  ${media.tablet`
    max-width: 320px;
    width: 100%;
  `}
`

const LinkTo = styled(Subtitle)`
  color: var(--color-ground-800);
  &:hover {
    color: var(--color-black);
  }
`

const encodeData = (data) =>
  Object.keys(data)
    .map((key) => [key, data[key]].map(encodeURIComponent).join(`=`))
    .join(`&`)

export const PageLogin: React.FC<RouteProps> = () => {
  const ref = Referrer.getRef()

  let postFix = ``

  if (ref) {
    // FIXME: нет проверки на '?' в урле, учитывать это
    postFix = `&`
    postFix += encodeData(ref)
  }

  return (
    <LayoutBaseWithoutFooter hideLogin hideInstall>
      <Root>
        <Video muted autoPlay loop playsInline>
          <source src="assets/signin_ext.webm" type='video/webm; codecs="vp8, vorbis"' />
          <source src="assets/signin_ext.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          Sorry, your browser doesn't support embedded videos.
        </Video>
        <WrapButtons>
          <Button iconLeft="Google" href={apiUrlGoogle + postFix} noBlank appearance="provide">
            Continue&nbsp;with&nbsp;Google
          </Button>
          <Button iconLeft="Facebook" href={apiUrlFacebook + postFix} noBlank appearance="provide">
            Continue&nbsp;with&nbsp;Facebook
          </Button>
          <Button iconLeft="Apple" href={apiUrlApple + postFix} noBlank appearance="provide">
            Continue&nbsp;with&nbsp;Apple ID
          </Button>
        </WrapButtons>
        <Spacer s="24" />
        <Subtitle>
          You acknowledge that you read, and agree to {`\n`}
          <Link to="/privacy_policy">
            <LinkTo>privacy policy</LinkTo>
          </Link>
          {` `}
          and{` `}
          <Link to="/terms_of_use">
            <LinkTo>terms of use</LinkTo>
          </Link>
        </Subtitle>
      </Root>
    </LayoutBaseWithoutFooter>
  )
}
