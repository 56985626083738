import css from './SocialDot.module.scss'

export const SocialDot: React.FC<React.PropsWithChildren<React.ComponentProps<`a`>>> = ({
  children,
  href,
}) => (
  <a href={href} target="_blank" className={css.root} rel="noreferrer">
    {children}
  </a>
)
