import { API_URL, APP_DOMAIN } from './envs'

export const apiUrl = API_URL
export const appDomain = APP_DOMAIN
export const yahhtUrl = `https://yahht.com/`
export const facebook = `https://www.facebook.com/breathhh.page`
export const twitter = `https://twitter.com/BreathhhApp`
export const medium = `https://medium.com/breathhh`
export const breathUltima = `https://${appDomain}/plans`
export const apiUrlFacebook = `${apiUrl}/auth/facebook?reg_from=web`
export const apiUrlGoogle = `${apiUrl}/auth/google_oauth2?reg_from=web`
export const apiUrlApple = `${apiUrl}/auth/apple?reg_from=web`
export const chromeExtId = `niipedbmjiopjpmjcpigiflabghcckeo`
export const chromeExtUrl = `https://chrome.google.com/webstore/detail/breathhh/${chromeExtId}`
export const breathhhExtension = `https://${appDomain}/extension`

export const sendMessageToExt = (type: string) => {
  try {
    chrome.runtime?.sendMessage(chromeExtId, { type })
  } catch (error) {
    console.log(`🍅>> sendMessageToExt:`, error)
  }
}
