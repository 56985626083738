import styled from 'styled-components'
import { media } from 'src/media'
import { RouteProps } from 'react-router-dom'
import { LayoutBaseWithoutFooter } from 'src/components/LayoutBaseWithoutFooter'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 89px);

  ${media.mobile`
    height: calc(100vh - 73px);
  `}
`

const Picture = styled.img`
  width: 208px;
  height: 208px;
`

const Title = styled.h1`
  max-width: 360px;
  font-family: var(--fonts-second);
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  font-weight: 400;
  margin-top: 32px;

  ${media.mobile`
    max-width: 320px;
    font-size: 34px;
    line-height: 38px;
  `}
`

const Text = styled.p`
  position: absolute;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-weight: 500;
  bottom: 29px;
  color: var(--color-ground-500);
`

export const Page500: React.FC<RouteProps> = () => {
  return (
    <LayoutBaseWithoutFooter>
      <Root>
        <Picture width="208" height="208" src="/assets/face-500.svg" alt="eye" />
        <Title>Unexpected error</Title>
        <Text>Error 500</Text>
      </Root>
    </LayoutBaseWithoutFooter>
  )
}
