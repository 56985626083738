import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Header } from 'src/components/Header'
import { Container } from './Container'

const Root = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
`
const Nav = styled.div`
  display: flex;
  gap: 10px;
  color: #232a34;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;

  & a {
    color: inherit;
    text-decoration: none;
  }
`

type TProps = {
  hideLogin?: boolean
  enableNav?: boolean
  hideInstall?: boolean
}

export const LayoutBaseWithoutFooter: React.FC<React.PropsWithChildren<TProps>> = ({
  children,
  hideLogin,
  enableNav,
  hideInstall,
}) => {
  window.scrollTo(0, 0)
  return (
    <Root>
      <div>
        <Header hideLogin={hideLogin} enableNav={enableNav} hideInstall={hideInstall} />
        <Container>
          {enableNav && (
            <Nav>
              <NavLink
                style={({ isActive }) => ({ opacity: isActive ? `1` : `0.5` })}
                to="/dashboard"
              >
                Dashboard
              </NavLink>
              <NavLink style={({ isActive }) => ({ opacity: isActive ? `1` : `0.5` })} to="/plans">
                Subscirption
              </NavLink>
              <NavLink style={({ isActive }) => ({ opacity: isActive ? `1` : `0.5` })} to="/diary">
                Diary
              </NavLink>
            </Nav>
          )}
          {children}
        </Container>
        {/* <ForReview /> */}
      </div>
    </Root>
  )
}
