import { useState } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { media } from 'src/media'

import { UserStore } from 'src/store/UserStore'

import { Button } from 'src/ui/atoms'

import { ReactComponent as IconApple } from './assets/apple.svg'

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 580px;
  max-height: 740px;
  height: calc(100vh - 89px);

  ${media.laptop`
    min-height: 100px;
    max-height: 1300px;
    height: 100%;
    justify-content: center;
    flex-direction: column-reverse;
    padding: 35px 0 110px;
  `}

  ${media.tablet`
    padding: 47px 0 56px;
  `}

  ${media.mobile`
    padding: 15px 0 92px;
  `}
`

const Info = styled.div`
  display: flex;
  flex-direction: column;

  ${media.laptop`
    margin-top: 96px;
    align-items: center;
  `}

  ${media.tablet`
    margin-top: 64px;
  `}

  ${media.mobile`
    margin-top: 48px;
  `}
`

const StoreText = styled.span`
  margin-left: 8px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const VideoWrap = styled.div`
  width: 455px;
  height: 455px;
  position: relative;

  &:hover {
    cursor: pointer;
    svg path {
      fill: var(--color-sunrise);
    }
  }

  ${media.laptop`
    width: 100%;
    height: 100%;
  `}
`

const Title = styled.h1`
  max-width: 550px;

  ${media.laptop`
    max-width: 672px;
    text-align: center;
  `}
`

const Text = styled.p`
  max-width: 550px;
  margin: 24px 0 40px;

  ${media.laptop`
    max-width: 672px;
    text-align: center;
  `}
`

const ButtonContainer = styled.div`
  max-width: 307px;
`

const PlayButton = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 134px;
  height: 134px;
  margin-left: -67px;
  margin-top: -67px;
  z-index: 5;

  &:hover {
    cursor: pointer;
    path {
      fill: var(--color-sunrise);
    }
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;
`

interface IProps {
  handleShowPopup?: () => void
  showPopup?: boolean
  browserName?: string | boolean
  isIOS?: boolean
}

export const Welcome: React.FC<IProps> = observer((props) => {
  const { handleShowPopup, showPopup, browserName, isIOS } = props
  const [play, setPlay] = useState(false)

  const handleClick = () => {
    handleShowPopup()
    setPlay(true)
  }

  return (
    <Root>
      <Info>
        <Title id="101">Workplace wellbeing companion</Title>
        <Text id="102">
          Get mindful practices at the right time just by&nbsp;browsing the&nbsp;Internet
        </Text>
        <ButtonContainer>
          {window.innerWidth < 768 && !UserStore.user ? (
            isIOS ? (
              <Button id="200" noBlank href="https://apps.apple.com/us/app/breathhh/id6444698538">
                <IconWrapper>
                  <IconApple />
                  <StoreText>Get it on the App Store</StoreText>
                </IconWrapper>
              </Button>
            ) : (
              <Link to="/login">
                <Button type="button">Get Started — It's Free</Button>
              </Link>
            )
          ) : isIOS ? (
            <Button id="200" noBlank href="https://apps.apple.com/us/app/breathhh/id6444698538">
              <IconWrapper>
                <IconApple />
                <StoreText>Get it on the App Store</StoreText>
              </IconWrapper>
            </Button>
          ) : (
            <Button
              id="103"
              noBlank
              href="https://chrome.google.com/webstore/detail/breathhh/niipedbmjiopjpmjcpigiflabghcckeo"
            >
              {!browserName ? `Get from Web Store` : `Add to ${browserName} — it’s free`}
            </Button>
          )}
        </ButtonContainer>
      </Info>
      <VideoWrap onClick={handleClick}>
        {(!play || !showPopup) && (
          <PlayButton onClick={handleClick}>
            <svg
              width="134"
              height="134"
              viewBox="0 0 134 134"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.0001 122.833C97.836 122.833 122.833 97.8359 122.833 67C122.833 36.1641 97.836 11.1667 67.0001 11.1667C36.1642 11.1667 11.1667 36.1641 11.1667 67C11.1667 97.8359 36.1642 122.833 67.0001 122.833ZM55.0819 46.9252C54.3376 46.4786 53.3907 47.0148 53.3907 47.8827V86.1181C53.3907 86.9861 54.3376 87.5222 55.0819 87.0757L86.9447 67.958C87.6676 67.5243 87.6676 66.4766 86.9447 66.0429L55.0819 46.9252Z"
                fill="white"
              />
            </svg>
          </PlayButton>
        )}
        <Video src="assets/welcome.mp4" muted autoPlay loop playsInline>
          <source src="assets/welcome.mp4" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
          <source src="assets/welcome.ogg" type="video/ogg" />
          <source src="assets/welcome.webm" type="video/webm" />
          Sorry, your browser doesn't support embedded videos.
        </Video>
      </VideoWrap>
    </Root>
  )
})
